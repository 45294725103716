import React, {forwardRef, useImperativeHandle, useState, useEffect} from 'react';
import {useDropzone} from 'react-dropzone';
import {Icon} from "semantic-ui-react";

const sendInput = {
    borderRadius: '9px',
    border: 'none',
    backgroundColor: '#f3f3f3'
};

const orderDropzone = {
    height: '100%',
    display: 'flex',
    padding: '10px 20px',
    cursor: 'pointer',
    outline: 'none',
    borderRadius: '9px',
    border: 'none',
    backgroundColor: '#f3f3f3',
    justifyContent: 'space-between'
};

const Dropzone = forwardRef((props, ref) => {
    const [files, setFiles] = useState([]);
    const {getRootProps, getInputProps} = useDropzone({
        accept: 'image/*',
        onDrop: acceptedFiles => {
            const arrFiles = [...files, acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            }))]

            setFiles(arrFiles);
            props.onDrop(acceptedFiles);
        }
    });

    useImperativeHandle(ref, () => ({
        setInitialFiles() {
            setFiles([]);
        }
    }));

    useEffect(() => () => {
        files.forEach(file => URL.revokeObjectURL(file.preview));
    }, [files]);


    return (
        <section style={{width: '100%', display: 'flex', justifyContent: 'center', outline: 'none'}}>
            <div {...getRootProps({className: 'dropzone'})} style={orderDropzone}>
                <input style={sendInput} disabled={props.disabled} {...getInputProps()} />
                Wybierz zdjęcie
                <Icon name="add circle" color="black"/>
            </div>
        </section>
    )
});

export default Dropzone;