import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import axios from "../../axios-sw";
import classes from './GroupMailer.module.scss'
import {authRedirect} from "../../shared/utility";
import Spinner from "../../components/UI/Spinner/Spinner";
import {Button, Input, TextField} from "@material-ui/core";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import {toast, ToastContainer} from "react-toastify";
import {Picky} from "react-picky";
import FormControl from "@material-ui/core/FormControl";

const GroupMailer = () => {
    //CONST
    const locationOptions = [
        {value: "Dolnośląskie", label: "Dolnośląskie"},
        {value: "Kujawsko-pomorskie", label: "Kujawsko-pomorskie"},
        {value: "Lubelskie", label: "Lubelskie"},
        {value: "Lubuskie", label: "Lubuskie"},
        {value: "Łódzkie", label: "Łódzkie"},
        {value: "Małopolskie", label: "Małopolskie"},
        {value: "Mazowieckie", label: "Mazowieckie"},
        {value: "Opolskie", label: "Opolskie"},
        {value: "Podkarpackie", label: "Podkarpackie"},
        {value: "Podlaskie", label: "Podlaskie"},
        {value: "Pomorskie", label: "Pomorskie"},
        {value: "Śląskie", label: "Śląskie"},
        {value: "Świętokrzyskie", label: "Świętokrzyskie"},
        {value: "Warmińsko-mazurskie", label: "Warmińsko-mazurskie"},
        {value: "Wielkopolskie", label: "Wielkopolskie"},
        {value: "Zachodniopomorskie", label: "Zachodniopomorskie"},
        {value: "Poza Polską", label: "Poza Polską"}
    ];

    const conditionOptions = [
        //{value: "all_users", label: "Wszyscy Użytkownicy"},
        {value: "inactive_accounts", label: "Konta nieaktywne"},
        //{value: "active_accounts", label: "Konta aktywne"},
        {value: "not_first_login_accounts", label: "Bez pierwszego logowania"},
        {value: "last_day_registred", label: "Zarejestrowani w ciągu ostatnich 24 godzin"},
        {value: "last_week_registred", label: "Zarejestrowani w ciągu ostatnich 7 dni"},
        {value: "last_month_registred", label: "Zarejestrowani w ciągu ostatnich 30 dni"},
        {value: "last_day_active", label: "Aktywni w ciągu ostatnich 24 godzin"},
        {value: "last_week_active", label: "Aktywni w ciągu ostatnich 7 dni"},
        {value: "last_month_active", label: "Aktywni w ciągu ostatnich 30 dni"},
        {value: "chosen_voivodeship", label: "Wybrane Województwo"},
        {value: "chosen_users", label: "Wybrani użytkownicy"}
    ]

    const settingName = 'welcome_message';

    //STATE
    const [groupMailer, setGroupMailer] = useState(null);
    const [topic, setTopic] = useState(null);
    const [users, setUsers] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [condition, setCondition] = useState(null);
    const [voivodeship, setVoivodeship] = useState(null);
    const [loading, setLoading] = useState(true);

    //SELECTORS
    const token = useSelector(({auth}) => auth.token);

    //FUNCTIONS
    useEffect(() => {
        fetchUsers();
    }, []);

    const fetchUsers = () => {
        axios.get(`admin/index-mail-recipients.json`, {headers: {'Authorization': `Bearer ${token}`}})
            .then(res => {
                const result = res.data.map((obj) => {
                    return {value: obj.id, label: obj.nickname}
                });
                setUsers(result);
                setLoading(false);
            })
            .catch(err => {
                console.error(err)
            })
    }

    const handleGroupMailerSend = () => {
        if((groupMailer && groupMailer.trim().length > 0) && (topic && topic.trim().length > 0)) {
            let selectedUsersArray = []
            if (condition && condition.value === 'chosen_users') selectedUsersArray = selectedUsers.map(user => user.value);
            const value = (condition && condition.value === 'chosen_voivodeship') ?
                (voivodeship ? voivodeship.value : null) : (condition && condition.value === 'chosen_users') ?
                    (selectedUsersArray.length > 0 ? selectedUsersArray : null  ) : true

            if(value) {
                setLoading(true);
                const data = {
                    condition: condition.value,
                    topic: topic,
                    content: groupMailer,
                    value: value
                }

                axios.post(`admin/users/send-group-noreply-email.json`, data, {headers: {'Authorization': `Bearer ${token}`}})
                    .then(res => {
                        toast.success('Wysłano!');
                        setGroupMailer(null);
                        setTopic(null);
                        setSelectedUsers([]);
                        setCondition(null);
                        setVoivodeship(null)
                        setLoading(false);
                    })
                    .catch(err => {
                        toast.success('Nie udało się wysłać!');
                        console.error(err)
                    })

            } else {
                if (condition.value === 'chosen_voivodeship') toast.error('Wybierz Województwo!');
                else if(condition.value === 'chosen_users') toast.error('Wybierz Użytkowników!');
            };

        } else toast.error('Wiadomość i temat nie mogą być puste!');
    }

    return (
        <>
            {loading ? <Spinner/> :
                <div>
                    {authRedirect(token)}
                    <ToastContainer/>
                    <Picky
                        className={`${classes.Select}`}
                        options ={conditionOptions}
                        labelKey="label"
                        valueKey="value"
                        multiple={false}
                        includeFilter
                        includeSelectAll
                        value={condition}
                        keepOpen={false}
                        onChange={(e) => setCondition(e)}
                    />
                    {condition && condition.value === 'chosen_voivodeship' &&
                    <Picky
                        className={classes.Select}
                        options ={locationOptions}
                        labelKey="label"
                        valueKey="value"
                        multiple={false}
                        includeFilter
                        includeSelectAll
                        value={voivodeship}
                        keepOpen={false}
                        onChange={(e) => setVoivodeship(e)}
                    />
                    }
                    {condition && condition.value === 'chosen_users' &&
                    <Picky
                        className={classes.Select}
                        options ={users}
                        labelKey="label"
                        valueKey="value"
                        multiple={true}
                        includeFilter
                        includeSelectAll
                        value={selectedUsers}
                        keepOpen={false}
                        onChange={(e) => setSelectedUsers(e)}
                    />
                    }
                    <FormControl className={classes.FormControl}>
                        <p className={classes.Label}>Temat</p>
                        <Input
                            id="title"
                            value={topic}
                            onChange={e => setTopic(e.target.value)}
                        />
                    </FormControl>
                    <TextareaAutosize
                        rows={10}
                        rowsMax={10}
                        value={groupMailer}
                        style={{fontSize: 14, width: '100%'}}
                        onChange={e => setGroupMailer(e.target.value)}
                    />
                    <Button
                        onClick={handleGroupMailerSend}
                        variant="outlined"
                        color="primary"
                        type="submit"
                    >
                        Wyślij
                    </Button>
                </div>
            }
        </>
    )
}

export default GroupMailer;