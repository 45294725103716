import React from "react";
import { Link } from "react-router-dom";

import Divider from "@material-ui/core/Divider/index";
import List from "@material-ui/core/List/index";

import ListItem from "@material-ui/core/ListItem/index";
import ListItemIcon from "@material-ui/core/ListItemIcon/index";
import ListItemText from "@material-ui/core/ListItemText/index";
import {
    Room,
    Fingerprint,
    PlayCircleFilled,
    FormatListNumbered,
    Email,
    Accessibility,
    Warning,
    People,
    Chat,
    Forum,
    Category,
    PostAdd,
    Subject,
    Theaters,
    GroupWork,
    Slideshow,
    LocalOffer,
} from "@material-ui/icons";

import Typography from "@material-ui/core/Typography";

const iconSize = 15;

const DrawerItem = (props) => {
    return (
        <Link to={props.link} onClick={props.onClick} style={{ textDecoration: "none" }}>
            <ListItem button>
                <ListItemIcon style={{ marginRight: -20 }}>{props.icon}</ListItemIcon>
                <ListItemText
                    disableTypography
                    primary={
                        <Typography
                            type="body1"
                            style={{
                                color: "rgba(0, 0, 0, 0.54)",
                                fontSize: 12,
                                marginBottom: -2,
                            }}
                        >
                            {props.label}
                        </Typography>
                    }
                />
            </ListItem>
        </Link>
    );
};

const AdminListItems = (props) => (
    <div>
        <DrawerItem label="Użytkownicy" link="/users" onClick={props.onClickLinkHandler} icon={<People style={{ fontSize: iconSize, opacity: "0.8" }} />} />
        <Divider></Divider>
        <DrawerItem
            label="Grupy Wsparcia"
            link="/support-groups"
            onClick={props.onClickLinkHandler}
            icon={<Chat style={{ fontSize: iconSize, opacity: "0.8" }} />}
        />
        <DrawerItem
            label="Grupy Tematyczne"
            link="/thematic-groups"
            onClick={props.onClickLinkHandler}
            icon={<Forum style={{ fontSize: iconSize, opacity: "0.8" }} />}
        />
        <DrawerItem
            label="Lokalne Grupy Wsparcia"
            link="/local-groups"
            onClick={props.onClickLinkHandler}
            icon={<Room style={{ fontSize: iconSize, opacity: "0.8" }} />}
        />
        <DrawerItem
            label="Główne Kategorie"
            link="/main-categories"
            onClick={props.onClickLinkHandler}
            icon={<Category style={{ fontSize: iconSize, opacity: "0.8" }} />}
        />
        <DrawerItem label="Oferty" link="/offers" onClick={props.onClickLinkHandler} icon={<LocalOffer style={{ fontSize: iconSize, opacity: "0.8" }} />} />
        <Divider></Divider>
        <DrawerItem label="Wpisy" link="/posts" onClick={props.onClickLinkHandler} icon={<PostAdd style={{ fontSize: iconSize, opacity: "0.8" }} />} />
        <Divider></Divider>
        <DrawerItem label="Artykuły" link="/articles" onClick={props.onClickLinkHandler} icon={<Subject style={{ fontSize: iconSize, opacity: "0.8" }} />} />
        <DrawerItem label="Media" link="/media" onClick={props.onClickLinkHandler} icon={<Theaters style={{ fontSize: iconSize, opacity: "0.8" }} />} />
        <DrawerItem label="Partnerzy" link="/partners" onClick={props.onClickLinkHandler} icon={<GroupWork style={{ fontSize: iconSize, opacity: "0.8" }} />} />
        <DrawerItem label="Reklamy" link="/banners" onClick={props.onClickLinkHandler} icon={<Slideshow style={{ fontSize: iconSize, opacity: "0.8" }} />} />
        <DrawerItem label="Zgłoszenia" link="/reports" onClick={props.onClickLinkHandler} icon={<Warning style={{ fontSize: iconSize, opacity: "0.8" }} />} />
        <Divider></Divider>
        <DrawerItem
            label="Wiadomość Powitalna"
            link="/welcome-message"
            onClick={props.onClickLinkHandler}
            icon={<Accessibility style={{ fontSize: iconSize, opacity: "0.8" }} />}
        />
        <DrawerItem
            label="Wiadomości Grupowe"
            link="/group-mailer"
            onClick={props.onClickLinkHandler}
            icon={<Email style={{ fontSize: iconSize, opacity: "0.8" }} />}
        />
        <Divider></Divider>
        <DrawerItem
            label="Statystyki"
            link="/statistics"
            onClick={props.onClickLinkHandler}
            icon={<FormatListNumbered style={{ fontSize: iconSize, opacity: "0.8" }} />}
        />
        <Divider></Divider>
        <DrawerItem
            label="Profile Zaufane"
            link="/trusted-profiles"
            onClick={props.onClickLinkHandler}
            icon={<Fingerprint style={{ fontSize: iconSize, opacity: "0.8" }} />}
        />
        <DrawerItem
            label="Webinaria Online"
            link="/online-webinars"
            onClick={props.onClickLinkHandler}
            icon={<PlayCircleFilled style={{ fontSize: iconSize, opacity: "0.8" }} />}
        />
        <Divider></Divider>
        <DrawerItem
            label="Historie HelpUkraine"
            link="/ukraine-stories"
            onClick={props.onClickLinkHandler}
            icon={<Chat style={{ fontSize: iconSize, opacity: "0.8" }} />}
        />
        <DrawerItem
            label="Wpisy HelpUkraine"
            link="/ukraine-articles"
            onClick={props.onClickLinkHandler}
            icon={<PostAdd style={{ fontSize: iconSize, opacity: "0.8" }} />}
        />
        <DrawerItem
            label="Eksperci HelpUkraine"
            link="/ukraine-volunteers"
            onClick={props.onClickLinkHandler}
            icon={<Accessibility style={{ fontSize: iconSize, opacity: "0.8" }} />}
        />
    </div>
);

const UkraineListItems = (props) => (
    <>
        <DrawerItem
            label="Historie HelpUkraine"
            link="/ukraine-stories"
            onClick={props.onClickLinkHandler}
            icon={<Chat style={{ fontSize: iconSize, opacity: "0.8" }} />}
        />
        <DrawerItem
            label="Wpisy HelpUkraine"
            link="/ukraine-articles"
            onClick={props.onClickLinkHandler}
            icon={<PostAdd style={{ fontSize: iconSize, opacity: "0.8" }} />}
        />
        <DrawerItem
            label="Eksperci HelpUkraine"
            link="/ukraine-volunteers"
            onClick={props.onClickLinkHandler}
            icon={<Accessibility style={{ fontSize: iconSize, opacity: "0.8" }} />}
        />
    </>
);

const drawerLinks = (props) => (
    <>
        <Divider />
        {props.role === "admin" && props.nickname !== "roksolana" ? (
            <List>
                <AdminListItems onClickLinkHandler={props.onClickLinkHandler} />
            </List>
        ) : null}

        {props.nickname === "roksolana" ? (
            <List>
                <UkraineListItems onClickLinkHandler={props.onClickLinkHandler} />
            </List>
        ) : null}
    </>
);

export default drawerLinks;
