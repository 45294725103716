import React, {Component} from 'react';
import {connect} from "react-redux";
import {authRedirect, catchResponse, thenResponse} from "../../shared/utility";
import axios from "../../axios-sw";

class GWPanel extends Component {

    state = {
        redirection: null,
    };

    componentDidMount() {

    }

    render() {
        return (
            <>
                {authRedirect(this.props.token)}
                <h1>Panel administracyjny grupawsparcia.pl</h1>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        user_id: state.auth.user_id,
        ip_address: state.auth.ip_address,
        token: state.auth.token,
        role: state.auth.role
    }
};

export default connect(mapStateToProps)(GWPanel);