import React, {Component} from 'react';

import Auxi from '../Auxi/Auxi';

import Footer from '../../components/Navigation/Footer/Footer';
import Navbar from '../../components/Navigation/Navbar/Navbar';

class Layout extends Component {

    render() {
        return (
            <Auxi style={{display: 'flex', flexDirection: 'column'}}>
                <Navbar />

                <main style={{padding: '90px 25px 90px 25px', minHeight: '90vh'}}>
                    {this.props.children}
                </main>
                <Footer />
            </Auxi>
        );
    }

}

export default Layout;