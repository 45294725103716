import { Checkbox, Input } from "@material-ui/core";
import moment from "moment";
import React, { useState } from "react";
import { Picky } from "react-picky";
import "react-picky/dist/picky.css";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";

export const editComponent = (props, autoFocus) => {
    return (
        <Input
            style={{ fontSize: 14 }}
            value={props.value}
            onChange={(e) => props.onChange(e.target.value)}
            autoFocus={autoFocus}
            onKeyPress={(event) => {
                if (event.key === "Enter") {
                    event.target.blur();
                }
            }}
        />
    );
};

//Customowy checkbox, można zaznaczyć tylko wtedy gdy podany field_contidional jest false (GW panel, users)
export const customCheckobxComponent = (props, field_conditional) => {
    return (
        <Checkbox
            value={props.value}
            checked={props.value}
            onChange={(e) => props.onChange(e.target.checked)}
            disabled={props.rowData[field_conditional] ? true : false}
        />
    );
};

export const editTextarea = (props, autoFocus) => {
    return (
        <TextareaAutosize
            rows={10}
            rowsMax={10}
            value={props.value}
            style={{ fontSize: 14, width: "100%" }}
            onChange={(e) => props.onChange(e.target.value)}
            autoFocus={autoFocus}
            onKeyPress={(event) => {
                if (event.key === "Enter") {
                    event.target.blur();
                }
            }}
        />
    );
};

export const multiEditSeasonComponent = (props, autoFocus) => {
    let selectedValues = [];
    if (typeof props.value === "string") {
        props.value
            .trim()
            .split(" ")
            .forEach((season) => {
                let index = props.columnDef.tableData.seasons.findIndex((item) => item.label === season);
                if (index !== -1) selectedValues = [...selectedValues, props.columnDef.tableData.seasons[index]];
            });
    } else {
        selectedValues = props.value ? props.value : [];
    }

    return (
        <Picky
            //className={classes.columnsSelect}
            options={props.columnDef.tableData.seasons}
            labelKey="label"
            valueKey="value"
            multiple={true}
            includeFilter
            includeSelectAll
            value={selectedValues}
            onChange={(e) => {
                props.onChange(e);
            }}
        />
    );
};

export const selectEditInput = (props) => {
    let selectedValues = props.value ? props.value : [];

    return (
        <Picky
            options={props.columnDef.tableData.options}
            labelKey="label"
            valueKey="value"
            multiple={false}
            includeFilter
            includeSelectAll
            value={selectedValues}
            onChange={(e) => {
                props.onChange(e);
            }}
        />
    );
};

export const selectSourceLanguage = (props, options) => {
    let selectedValues = props.value ? props.value : [];

    return (
        <Picky
            options={options}
            labelKey="label"
            valueKey="value"
            multiple={false}
            value={selectedValues}
            onChange={(e) => {
                props.onChange(e);
            }}
        />
    );
};

export const checkDateRange = (range, rowDataDate) => {
    if (range.isEmpty) {
        return !rowDataDate || rowDataDate === "" || rowDataDate.trim() === "";
    } else if (range.isFull) {
        return rowDataDate && rowDataDate !== "" && rowDataDate.trim() !== "";
    } else {
        const start = moment(range.start).format("YYYY-MM-DD");
        const end = moment(range.end).format("YYYY-MM-DD");
        return rowDataDate >= start && rowDataDate <= end;
    }
};

export const sortByDate = (a, b, order, key) => {
    if (order === "desc") {
        if (!a[key]) return -1;
        else return a[key] < b[key] ? -1 : 1;
    } else {
        if (!b[key]) return -1;
        else return a[key] < b[key] ? -1 : 1;
    }
};

/*Dla zwykłych kolumn*/
export const cellStyle = {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    maxWidth: 90,
    paddingRight: 15,
    paddingLeft: 10,
};
export const headerStyle = {
    whiteSpace: "nowrap",
    overflow: "hidden",
    maxWidth: 90,
    paddingRight: 15,
    paddingLeft: 10,
};

/*Dla kolumn z checkboxami*/

export const checkboxCellStyle = {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    maxWidth: 90,
    padding: 0,
};
export const checkboxHeaderStyle = {
    whiteSpace: "nowrap",
    overflow: "hidden",
    maxWidth: 90,
    paddingRight: 0,
    paddingLeft: 0,
};
