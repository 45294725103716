import React, { Component } from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Paper from "@material-ui/core/Paper";
import { browserVersion } from "react-device-detect";

// Shared, store
import { checkMyBrowser, checkMyBrowserVersion } from "./shared/utility";
import { supportedBrowsers } from "./shared/defines";
import * as actions from "./store/actions/index";

// Views
import Layout from "./hoc/Layout/Layout";
import Login from "./views/Login/Login";
import Logout from "./views/Logout/Logout";
import GWPanel from "./views/GWPanel/GWPanel";
import Users from "./views/Users/Users";
import SupportGroups from "./views/SupportGroups/SupportGroups";
import ThematicGroups from "./views/ThematicGroups/ThematicGroups";
import MainCategories from "./views/MainCategories/MainCategories";
import Posts from "./views/Posts/Posts";
import Articles from "./views/Articles/Articles";
import Article from "./views/Articles/Article/Article";
import Offers from "./views/Offers/Offers";
import Offer from "./views/Offers/Offer/Offer";
import Media from "./views/Media/Media";
import MediaForm from "./views/Media/MediaForm/MediaForm";
import Partners from "./views/Partners/Partners";
import Partner from "./views/Partners/Partner/Partner";
import Banners from "./views/Banners/Banners";
import Banner from "./views/Banners/Banner/Banner";
import ThematicGroup from "./views/ThematicGroups/ThematicGroup/ThematicGroup";
import Reports from "./views/Reports/Reports";
import WelcomeMessage from "./views/WelcomeMessage/WelcomeMessage";
import GroupMailer from "./views/GroupMailer/GroupMailer";
import Statistics from "./views/Statistics/Statistics";
import LocalGroups from "./views/LocalGroups/LocalGroups";
import TrustedProfiles from "./views/TrustedProfiles/TrustedProfiles";
import OnlineWebinars from "./views/OnlineWebinars/OnlineWebinars";
import OnlineWebinar from "./views/OnlineWebinars/OnlineWebinar/OnlineWebinar";
import UkraineStories from "./views/UkraineStories/UkraineStories";
import UkraineArticles from "./views/UkraineArticles/UkraineArticles";
import UkraineArticle from "./views/UkraineArticles/UkraineArticle/UkraineArticle";
import UkraineVolunteers from "./views/UkraineVolunteers/UkraineVolunteers";
import UkraineVolunteer from "./views/UkraineVolunteers/UkraineVolunteer/UkraineVolunteer";

// Styles
import "react-toastify/dist/ReactToastify.min.css";
import classes from "./App.module.scss";

class App extends Component {
    state = {
        redirection: null,
        browser: checkMyBrowser(),
    };

    componentDidMount() {
        this.props.onTryAutoSignup();
    }

    render() {
        return (
            <div className={classes.App}>
                {this.state.redirection}
                {!supportedBrowsers.find((browser) => browser === this.state.browser) ? (
                    <div className={"overlay overlay-browser-info"}>
                        <Paper elevation={3} className={"browser-info-error"}>
                            <p className={"text-info"}>
                                Your browser: <b>{checkMyBrowser()}</b> is not supported. Try install Chrome or Firefox.
                            </p>
                        </Paper>
                    </div>
                ) : (
                    !checkMyBrowserVersion() && (
                        <div className={"overlay overlay-browser-info"}>
                            <Paper elevation={3} className={"browser-info-error"}>
                                <p className={"text-info"}>
                                    Your browser version: <b>0.{browserVersion}</b> is not supported. Try update your browser: <b>{checkMyBrowser()}</b>
                                </p>
                            </Paper>
                        </div>
                    )
                )}
                <Layout>
                    <Switch>
                        <Route path="/ukraine-volunteers" component={UkraineVolunteers} />
                        <Route path="/ukraine-volunteer/:id?" component={UkraineVolunteer} />
                        <Route path="/ukraine-articles" component={UkraineArticles} />
                        <Route path="/ukraine-article/:id?" component={UkraineArticle} />
                        <Route path="/ukraine-stories" component={UkraineStories} />
                        <Route path="/users" component={Users} />
                        <Route path="/support-groups" component={SupportGroups} />
                        <Route path="/local-groups" component={LocalGroups} />
                        <Route path="/thematic-groups" component={ThematicGroups} />
                        <Route path="/thematic-group/:id?" component={ThematicGroup} />
                        <Route path="/main-categories" component={MainCategories} />
                        <Route path="/articles" component={Articles} />
                        <Route path="/article/:id?" component={Article} />
                        <Route path="/offers" component={Offers} />
                        <Route path="/offer/:id?" component={Offer} />
                        <Route path="/media" component={Media} />
                        <Route path="/media-form/:id?" component={MediaForm} />
                        <Route path="/partners" component={Partners} />
                        <Route path="/partner/:id?" component={Partner} />
                        <Route path="/banners" component={Banners} />
                        <Route path="/banner/:id?" component={Banner} />
                        <Route path="/reports" component={Reports} />
                        <Route path="/posts" component={Posts} />
                        <Route path="/welcome-message" component={WelcomeMessage} />
                        <Route path="/group-mailer" component={GroupMailer} />
                        <Route path="/statistics" component={Statistics} />
                        <Route path="/trusted-profiles" component={TrustedProfiles} />
                        <Route path="/online-webinars" component={OnlineWebinars} />
                        <Route path="/online-webinar/:id?" component={OnlineWebinar} />
                        <Route path="/login" component={Login} />
                        <Route path="/logout" component={Logout} />
                        <Route path="/" component={GWPanel} />
                        <Redirect to="/" />
                    </Switch>
                </Layout>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.auth.token !== null,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onTryAutoSignup: () => dispatch(actions.authCheckState()),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
