import React, {useEffect, useState} from "react";
import {withRouter} from "react-router";
import {GoogleMap, Marker, useLoadScript} from '@react-google-maps/api';
import usePlacesAutocomplete, {getGeocode, getLatLng,} from "use-places-autocomplete";
import {Combobox, ComboboxInput, ComboboxList, ComboboxOption, ComboboxPopover,} from "@reach/combobox";
import "./OfferMap.css";

const Map = ({setLatLng, getLocation, lat, lng}) => {
    const [libraries, setLibraries] = useState(["places"]);
    const [offer, setOffer] = useState([]);
    const [firstLoad, setFirstLoad] = useState(true);

    const mapContainerStyle = {
        width: '100%',
        height: '100%'
    }

    const [zoom, setZoom] = useState(10);
    const [center, setCenter] = useState({
        lat: 52.401780,
        lng: 16.939140
    })

    const {isLoaded, loadError} = useLoadScript({
        googleMapsApiKey: 'AIzaSyBnYQZnzcjaQjLVyVqwy22UYu4sPSOXd9w',
        libraries
    })

    const handleSetPinOffer = async e => {
        setLatLng( e.latLng.lat(), e.latLng.lng());
        setOffer([{lat: e.latLng.lat(), lng: e.latLng.lng()}])
    }

    const mapRef = React.useRef();
    const onMapLoad = React.useCallback((map) => {
        mapRef.current = map;
    }, []);

    const panTo = React.useCallback(({ lat, lng }) => {
        mapRef.current.panTo({ lat, lng });
        mapRef.current.setZoom(14);
        setLatLng(lat, lng);
        setOffer([{lat: lat, lng: lng}])
    }, []);

    useEffect(() => {
        if(lat && lng && firstLoad) {
            setFirstLoad(false);
            setOffer([{lat: lat, lng: lng}])
        }
    },[lat, lng])

    if (loadError) return 'Loading Error';
    if (!isLoaded) return 'Loading';

    return (
        <div className="offer-map">
            <Search panTo={panTo}/>
            <GoogleMap
                mapContainerStyle={mapContainerStyle}
                zoom={zoom}
                center={center}
                onClick={handleSetPinOffer}
                onLoad={onMapLoad}
            >
                {offer.map((offer, i) => {
                    return (
                        <Marker
                            key={i}
                            position={{
                                lat: parseFloat(offer.lat),
                                lng: parseFloat(offer.lng)
                            }}
                            icon={{
                                url: "/img/icons/icon-map-pin.svg"
                            }}
                            onClick={() => {
                                setLatLng(null, null)
                                setOffer([])
                            }}
                        />
                    )
                })}
            </GoogleMap>
        </div>
    )

}

const Search = ({panTo}) => {
    const {
        ready,
        value,
        suggestions: {status, data},
        setValue,
        clearSuggestions,
    } = usePlacesAutocomplete({
        requestOptions: {
            location: {lat: () => 52.401780, lng: () => 16.939140},
            radius: 100 * 1000,
        },
    });

    const handleInput = (e) => {
        setValue(e.target.value);
    };

    const handleSelect = async (address) => {
        setValue(address, false);
        clearSuggestions();

        try {
            const results = await getGeocode({address});
            const {lat, lng} = await getLatLng(results[0]);
            panTo({lat, lng});
        } catch (error) {
            console.log("😱 Error: ", error);
        }
    };

    return (
        <div className="search">
            <Combobox onSelect={handleSelect}>
                <ComboboxInput
                    value={value}
                    onChange={handleInput}
                    disabled={!ready}
                    placeholder={'Search'}
                />
                <ComboboxPopover className="locations-list">
                    <ComboboxList>
                        {status === "OK" &&
                        data.map(({id, description}) => (
                            <ComboboxOption key={id} value={description}/>
                        ))}
                    </ComboboxList>
                </ComboboxPopover>
            </Combobox>
        </div>
    );
}


class OfferMap extends React.Component {

    render() {
        const {setLatLng, getLocation, lat, lng} = this.props;

        return (
            <div style={{height: '100%'}}>
                <Map
                    lat={lat}
                    lng={lng}
                    getLocation={getLocation}
                    setLatLng={setLatLng}
                />
            </div>

        )
    }
}

export default OfferMap;
