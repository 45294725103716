import React, {Component} from "react";
import {FormControl, InputLabel, MenuItem, Button, Input, FormHelperText} from '@material-ui/core';
import Select from '@material-ui/core/Select';
import axios from '../../../../axios-sw';
import {toast} from 'react-toastify';

class VoivodeshipTab extends Component { 
  state = {
    voivodeship: "",
    criteria: "",
    maxUsers: 0,
    limits: 0
  }

  criteries = [
    {value: "id ASC", name: "Brak"},
    {value: "last_login ASC", name: "Wg daty logowań - nieaktywni"},
    {value: "last_login DESC", name: "Wg daty logowań - aktywni"}
  ]
  
  voivodeships = [
    {value: "Dolnośląskie", text: "Dolnośląskie"},
    {value: "Kujawsko-pomorskie", text: "Kujawsko-pomorskie"},
    {value: "Lubelskie", text: "Lubelskie"},
    {value: "Lubuskie", text: "Lubuskie"},
    {value: "Łódzkie", text: "Łódzkie"},
    {value: "Małopolskie", text: "Małopolskie"},
    {value: "Mazowieckie", text: "Mazowieckie"},
    {value: "Opolskie", text: "Opolskie"},
    {value: "Podkarpackie", text: "Podkarpackie"},
    {value: "Podlaskie", text: "Podlaskie"},
    {value: "Pomorskie", text: "Pomorskie"},
    {value: "Śląskie", text: "Śląskie"},
    {value: "Świętokrzyskie", text: "Świętokrzyskie"},
    {value: "Warmińsko-mazurskie", text: "Warmińsko-mazurskie"},
    {value: "Wielkopolskie", text: "Wielkopolskie"},
    {value: "Zachodniopomorskie", text: "Zachodniopomorskie"},
    {value: "Poza Polską", text: "Poza Polską"}
  ]

  send = () => {
    this.props.setLoading(true);
    axios.post(`admin/add-users-to-support-group`, {
      params: {
          supportGroupId: this.props.supportGroupId,
          voivodeship: this.state.voivodeship,
          orderBy: this.state.criteria,
          limits: this.state.limits 
      }
  }, {headers: {'Authorization': `Bearer ${this.props.token}`}})
      .then(async res => {
        this.props.setLoading(false);
         toast.success(res.data.message);
         this.props.onClose();
  });
  }

  countUserByVoivodeship = async (voivodeship) => {
    this.props.setLoading(true);
    await axios.get(`admin/count-by-voivodeship/${voivodeship}`)
      .then(resp => {
         this.setState({maxUsers: resp.data.count})
         this.props.setLoading(false);
        });
  }

  render() {
    return (
      <div style={{padding: "25px"}}>
        <div>
        <FormControl style={{minWidth: 120}}>
          <InputLabel id="demo-simple-select-label">Województwo</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={this.state.voivodeship}
            onChange={e => {
              this.countUserByVoivodeship(e.target.value);
              this.setState({voivodeship: e.target.value});
            }}
          >
            {
              this.voivodeships.map((v, index) => (
                <MenuItem key={`voivodeship${index}`} value={v.value}>{v.text}</MenuItem>
              ))
            }
          </Select>
        </FormControl>
        <FormControl style={{minWidth: 120, marginLeft: "20px"}}>
          <InputLabel id="demo-simple-select-label">Sortuj</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={this.state.criteria}
            onChange={e => {
              this.setState({criteria: e.target.value});
            }}
          >
            {
              this.criteries.map((v, index) => (
                <MenuItem key={`crit${index}`} value={v.value}>{v.name}</MenuItem>
              ))
            }
          </Select>
        </FormControl>
        </div>
        <FormControl style={{width: 120}}>
          <InputLabel id="demo-simple-select-label">Ilość</InputLabel>
          <Input
          onChange={e => this.setState({limits: e.target.value})}
           type="number"/>
           <FormHelperText>Max: {this.state.maxUsers}</FormHelperText>
        </FormControl>
        <div style={{marginTop: "25px"}}>
          <Button
            onClick={() => this.props.onClose()}
            disabled={this.state.loading}
            variant="contained"
            style={{backgroundColor: 'gray', color:"#fff"}}
          >
            Anuluj
          </Button>
          <Button
            onClick={() => this.send()}
            disabled={this.state.loading}
            variant="contained"
            style={{backgroundColor: 'black', color:"#fff", marginLeft: "25px"}}
          >
            Zatwierdź
          </Button>
        </div>
      </div>
    );
  }
}

export default VoivodeshipTab;