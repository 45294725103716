import * as actionTypes from './actionTypes';
import axios from '../../axios-sw';
import {toast} from 'react-toastify';

import publicIp from 'public-ip';

export const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('expirationTime');
    localStorage.removeItem('user_id');
    return {
        type: actionTypes.AUTH_LOGOUT
    }
};

export const authStart = () => {
    return {
        type: actionTypes.AUTH_START
    }
};

export const authSuccess = (token, user_id, role, nickname, avatar, position, main_lang_id, ip_address, redirection) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        token: token,
        user_id: user_id,
        role: role,
        nickname: nickname,
        avatar: avatar,
        position: position,
        main_lang_id: main_lang_id,
        ip_address: ip_address,
        authRedirectPath: redirection
    }
};

export const authFail = (error) => {
    return {
        type: actionTypes.AUTH_FAIL,
        error: error
    }
};

export const auth = (nickname, password) => {
    return dispatch => {
        dispatch(authStart());
        (async () => {
            const ip_address = await publicIp.v4();
            const authData = {
                user: {
                    nickname: nickname,
                    password: password
                },
                id_address: ip_address
            };
            let url = '/login';
            axios.post(url, authData)
                .then(res => {
                    const user = res.data;
                    if (user.role === 'admin') {
                        localStorage.setItem('token', res.headers.authorization.split(' ')[1]);
                        localStorage.setItem('expirationTime', user.exp_time);
                        localStorage.setItem('user_id', user.id);
                        let redirection = '/'
                        console.log(user)
                        dispatch(authSuccess(res.headers.authorization.split(' ')[1],
                            user.id,
                            user.role,
                            user.nickname,
                            user.avatar,
                            user.position,
                            user.main_lang_id,
                            ip_address,
                            redirection
                        ));
                    } else {
                        toast.error('Wrong nickname or password! Try again');
                    }
                })
                .catch(err => {
                    console.log(err);
                    dispatch(authFail(err));
                })
        })()
    }
};

export const setAuthRedirectPath = (path) => {
    return {
        type: actionTypes.SET_AUTH_REDIRECT_PATH,
        path: path
    }
};

export const authCheckState = () => {
    return dispatch => {
        const token = localStorage.getItem('token');
        if (!token) {
            dispatch(logout());
        } else {
            const expirationTime = new Date(localStorage.getItem('expirationTime'));
            if (expirationTime <= new Date().getTime() / 1000) {
                dispatch(logout());
            } else {
                const user_id = localStorage.getItem('user_id');
                axios.get('admin/users/' + user_id + '.json')
                    .then(res => {
                        const user = res.data;
                        (async () => {
                            const ip_address = await publicIp.v4();
                            dispatch(authSuccess(
                                token,
                                user_id,
                                user.role,
                                user.nickname,
                                user.avatar,
                                user.position,
                                user.main_lang_id,
                                ip_address
                            ));
                        })();
                    })
                    .catch(err => {
                        console.log(err)
                    })
            }
        }
    }
};

export const accountUpdate = (nickname, avatar) => {
    return {
        type: actionTypes.AUTH_ACCOUNT_UPDATE,
        nickname: nickname,
        avatar: avatar
    }
};