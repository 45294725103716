import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import { Avatar, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minHeight: 'fit-content'
    },
    avatar: {
        width: 60,
        height: 60
    },
    name: {
        marginTop: theme.spacing(1)
    }
}));


const AccountPreview = props => {

    const { className, ...rest } = props;

    const classes = useStyles();

    return (
        <div
            {...rest}
            className={classes.root}
        >
            <Avatar
                alt="Person"
                className={classes.avatar}
                component={RouterLink}
                src={process.env.REACT_APP_BACKEND_URL + props.avatar_url}
                to={"/user/" + props.user_id}
            />
            <Typography
                className={classes.name}
                variant="subtitle2"
            >
                {props.nickname}
            </Typography>
        </div>
    )
};

export default AccountPreview;