import React, {Component} from "react";
import Box from '@material-ui/core/Box';
import { CircularProgress, Paper, Tabs, Tab} from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import VoivodeshipTab from "./Tabs/VoivodeshipTab";

class SupportGroupUsers extends Component { 
  state = {
    loading: false,
    value: 0
  }

  render() {
    return (
      <Modal
        open={this.props.isOpen}
        onClose={() => this.props.onClose()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box style={style}>
          {this.state.loading &&
          <div style={{
            justifyContent: "center", alignItems: "center", display: "flex",
            position: "absolute", width: "100%", height: "100%", backgroundColor: "grey", opacity: 0.5}}>
            <CircularProgress />
          </div>
          }
            <Paper square>
              <Tabs
                value={this.state.value}
                indicatorColor="primary"
                textColor="primary"
                onChange={(event, newValue) => this.setState({value: newValue})}
                aria-label="disabled tabs example"
              >
                <Tab label="Wg województwa" />
              </Tabs>
            
            </Paper>
            {this.state.value === 0 &&
              <VoivodeshipTab
              setLoading={e => this.setState({loading: e})}
              onClose={() => this.props.onClose()}
                supportGroupId={this.props.supportGroupId}
              />
            }
        </Box>
      </Modal>
    );
  }
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: "#fff",
  minWidth: 400,
  boxShadow: 24,
  p: 4,
};

export default SupportGroupUsers;