import React, {useEffect, useRef, useState} from 'react';
import {useFormik} from "formik";
import * as Yup from 'yup';
import classes from "./Offer.module.scss";
import axios from "../../../axios-sw";
import {authRedirect, catchResponse, thenResponse} from "../../../shared/utility";
import Spinner from "../../../components/UI/Spinner/Spinner";
import {ToastContainer} from "react-toastify";
import {useSelector} from "react-redux";

import {Button, Paper, FormControl, Input, Select, MenuItem} from "@material-ui/core";

import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {ArrowBack, Delete} from "@material-ui/icons";

import Geocode from "react-geocode";
import OfferMap from "../../../components/System/OfferMap/OfferMap";
import Dropzone from "../Dropzone/Dropzone";

const options = [
    {key: 'pln', text: 'PLN', value: 'PLN'},
    {key: 'byn', text: 'BYN', value: 'BYN'},
    {key: 'usd', text: 'USD', value: 'USD'},
    {key: 'eur', text: 'EUR', value: 'EUR'},
]

const Offer = props => {

    const [id, setId] = useState(props.match.params.id);
    const [loading, setLoading] = useState(true);
    const [offer, setOffer] = useState(null);
    const [offerCategories, setOfferCategories] = useState([]);
    const [imgs, setImgs] = useState([]);
    const [isAddingOffer, setIsAddingOffer] = useState(false);
    const dropzone = useRef();

    const token = useSelector(({auth}) => auth.token);
    const userId = useSelector(({auth}) => auth.user_id);
    const ipAddress = useSelector(({auth}) => auth.ip_address);
    const langId = useSelector(({auth}) => auth.main_lang_id);

    useEffect(() => {
        indexOfferCategories();
        if (id) indexOffer(id);
        else setLoading(false);
    }, [id])

    const indexOffer = id => {
        axios.get(`admin/offers/${id}.json`, {headers: {'Authorization': `Bearer ${token}`}})
            .then(async res => {
                const offer = res.data;
                const imgs = offer.img.map((url, i) => {
                    return [{
                        isAdded: true,
                        preview: `${process.env.REACT_APP_BACKEND_URL}/uploads/offer_photo/${url}`,
                        name: `${process.env.REACT_APP_BACKEND_URL}/uploads/offer_photo/${url}`,
                        url: url
                    }]
                })
                if(imgs[0]) imgs[0].cover = true;

                await formik.setValues({
                    title: offer.title,
                    offer_category_id: offer.offer_category_id,
                    price: offer.price,
                    location: offer.location,
                    lat: offer.lat,
                    lng: offer.lng,
                    description: offer.description,
                    currency: offer.currency,
                    imgs: offer.imgs,
                    accepted: res.data.accepted
                })

                await setOffer(res);
                await setImgs(imgs);
                setLoading(false);
                setIsAddingOffer(false);
            })
            .catch(err => {
                catchResponse(err);
            })
    }

    const indexOfferCategories = () => {
        axios.get("/offer_categories.json", {headers: {'Authorization': `Bearer ${token}`}})
            .then(res => {
                const offerCategoriesOptions = res.data.map(category => {
                    return {key: category.id, value: category.id, text: category.name}
                })
                setOfferCategories(offerCategoriesOptions);
            });
    }

    const setLatLng = (lat, lng) => {
        formik.setFieldValue('lat', lat);
        formik.setFieldValue('lng', lng);
        formik.setTouched({...formik.touched,['lat']: true, ['lng']: true });
    }

    const getLocation = async (lat, lng) => {
        Geocode.setApiKey("AIzaSyBnYQZnzcjaQjLVyVqwy22UYu4sPSOXd9w");
        Geocode.setRegion("pl");
        let name = '';
        await Geocode.fromLatLng(lat, lng).then(
            response => {
                name = response.results[1].address_components.filter(item => item.types.includes("locality"));
                if(!name[0]) name = response.results[1].address_components.filter(item => item.types.includes("administrative_area_level_4"));
                if(!name[0]) name = response.results[1].address_components.filter(item => item.types.includes("administrative_area_level_3"));
                if(!name[0]) name = response.results[1].address_components.filter(item => item.types.includes("administrative_area_level_2"));
                if(!name[0]) name = response.results[1].address_components.filter(item => item.types.includes("administrative_area_level_1"));
                if(!name[0]) name = response.results[1].address_components.filter(item => item.types.includes("country"));
                name = name[0].long_name;
            },
            error => {
                console.error(error);
            }
        );

        return name;
    }

    const addOffer = async values => {
        let formDataPreview;

        if (imgs === null) {
            formDataPreview = new FormData();
            formDataPreview.append('images[]', null);
        } else if (imgs.length > 0) {
            formDataPreview = new FormData();
            for (let i = 0; i < imgs.length; i++) {
                let image = imgs[i]
                let cover = i === 0;
                formDataPreview.append('images[]', image[0]);
                formDataPreview.append('covers[]', cover);
            }
        }

        const location = await getLocation(values.lat, values.lng);
        const data = {
            offer: {
                title: values.title,
                description: values.description,
                price: values.price,
                currency: values.currency,
                location: location,
                lat: values.lat,
                lng: values.lng,
                offer_category_id: values.offer_category_id,
                user_id: userId,
                original_lang_id: langId,
                accepted: true
            },
            images: {},
            user_id: userId,
            ip_address: ipAddress
        }

        axios.post('/offers', data, {headers: {'Authorization': `Bearer ${token}`}})
            .then(offer => {
                if(imgs.length > 0) {
                    axios.post('/offers/upload-image/' + offer.data.offer_id, formDataPreview, {headers: {'Authorization': `Bearer ${token}`}})
                        .then(() => {
                            setId(offer.data.offer_id);
                            props.history.push(`/offer/${offer.data.offer_id}`);
                            setIsAddingOffer(false);
                        });
                } else {
                    setId(offer.data.offer_id);
                    props.history.push(`/offer/${offer.data.offer_id}`);
                    setIsAddingOffer(false);
                }
            });

    }

    const updateOffer = async (id, values) => {
        let formDataPreview;
        const location = await getLocation(values.lat, values.lng);
        const imgToAdd = imgs.filter(img => !img[0].isAdded);
        const existImgToUpdate = imgs.map(img => {
                if (img[0].isAdded) return img[0].url
            }
        );

        const coverImage = imgs.map(img => {
            if(img.cover) return img[0].url
        })


        if (imgToAdd && imgToAdd.length > 0) {
            formDataPreview = new FormData();
            for (let i = 0; i < imgToAdd.length; i++) {
                let image = imgToAdd[i];
                let cover = !!image.cover;
                formDataPreview.append('images[]', image[0]);
                formDataPreview.append('covers[]', cover);
            }
        }

        const data = {
            offer: {
                title: values.title,
                phone_number: values.phone_number,
                description: values.description,
                price: values.price,
                currency: values.currency,
                location: location,
                lat: values.lat,
                lng: values.lng,
                offer_category_id: values.offer_category_id,
                user_id: userId,
                original_lang_id: langId,
                accepted: false,
            },
            imgs: existImgToUpdate,
            cover_image: coverImage,
            user_id: userId,
            ip_address: ipAddress
        }

        axios.put(`admin/update-content/${id}`, data, {headers: {'Authorization': `Bearer ${token}`}})
            .then(res => {
                if(imgToAdd && imgToAdd.length >0) {
                    axios.post('/offers/upload-image/' + id, formDataPreview, {headers: {'Authorization': `Bearer ${token}`}})
                        .then(() => {
                            formik.setSubmitting(false)
                        });
                    formik.setSubmitting(false);
                    setIsAddingOffer(false);
                    thenResponse(res);
                } else {
                    formik.setSubmitting(false);
                    setIsAddingOffer(false);
                }

            })
            .catch(err => {
                formik.setSubmitting(false);
                catchResponse(err)
            });
    }

    const validationSchema = Yup.object({
        title: Yup.string()
            .required('Tytuł jest wymagany'),
        offer_category_id: Yup.string()
            .required('Kategoria jest wymagana'),
        description: Yup.string()
            .required('Opis jest wymagany')
            .max(9000, 'Opis nie może być dłuższy niż 9s000 znaków'),
        price: Yup.number(),
        lat: Yup.number()
            .required('Lokalizacja jest wymagana'),
        lng: Yup.number()
            .required('Lokalizacja jest wymagana'),
    })

    const formik = useFormik({
        initialValues: {
            title: '',
            phone_number: null,
            offer_category_id: '',
            price: 0,
            lat: null,
            lng: null,
            description: '',
            currency: 'PLN',
            imgs: null
        },
        onSubmit: (values, actions) => {
            setIsAddingOffer(true);
            if (id) updateOffer(id, values);
            else addOffer(values);
        },
        validationSchema
    });

    const switchPlacesImage = async (imgs,i) => {
        const images = [...imgs];
        images.forEach((img,index) => {
            if(i === index) {
                images[0].cover = false;
                images[i].cover = true;
                [images[0], images[i]] = [images[i], images[0]]
            };
        })
        setImgs(images)
    }

    return (
        <>{loading ? <Spinner/> :
            <div className={classes.Article}>
                {authRedirect(token)}
                {isAddingOffer &&<div className={classes.Overlay}>
                    <Spinner/>
                    {id
                        ? <p>Edycja Oferty</p>
                        : <p>Dodawanie Oferty</p>
                    }
                </div>}
                <ToastContainer/>
                <Paper className={classes.Paper}>
                    <Button
                        className={classes.BackButton}
                        variant="contained"
                        onClick={() => props.history.push('/offers')}>
                        <ArrowBack/>
                    </Button>
                    {(id && offer) ? <h1>Offer: {offer.title}</h1> : <h1>Add offer</h1>}

                    <form className={classes.ArticleForm} onSubmit={formik.handleSubmit}>

                        {/* Zdjęcia */}
                        <div className={classes.PhotosDropzone}>
                            {imgs.map((file, i) => {
                                return (
                                    <div className = {i === 0 ? `${classes.OffersPhotoThumb} ${classes.Cover}` : classes.OffersPhotoThumb} key={i}>
                                        <Delete
                                            key={i}
                                            onClick={() => {
                                                const filterImgs = imgs.filter(img => img[0].preview !== file[0].preview)
                                                setImgs(filterImgs);
                                            }}
                                            className={classes.RemoveButton}
                                        />
                                        <div className={i === 0 ? `${classes.ThumbInner} ${classes.Cover}` : classes.ThumbInner}>
                                            <img
                                                onClick={() => {
                                                    if(i !== 0) {
                                                        switchPlacesImage(imgs, i);
                                                    };
                                                }}
                                                alt={file[0].name}
                                                src={file[0].preview}
                                            />
                                        </div>
                                    </div>
                                )
                            })}
                            <Dropzone files={[]}
                                      disabled={imgs.length >=4}
                                      ref={dropzone}
                                      onDrop={files => {
                                          const arrFiles = [...imgs, files]
                                          setImgs(arrFiles);
                                      }}
                                      onRemove={files => {
                                          setImgs(files);
                                      }}
                            />
                        </div>

                        {/* Title */}
                        <FormControl className={classes.FormControl}>
                            <p className={classes.Label}>Title</p>
                            <Input
                                id="title"
                                error={formik.touched.title && formik.errors.title}
                                className={classes.Input}
                                {...formik.getFieldProps('title')}
                            />
                            <p className={classes.InputFeedback}>{formik.touched.title && formik.errors.title ? formik.errors.title : null}</p>
                        </FormControl>

                        {/* Category */}
                        <FormControl className={classes.FormControl}>
                            <p className={classes.Label}>Category</p>
                            <Select
                                fluid
                                className="input"
                                error={formik.touched.offer_category_id && formik.errors.offer_category_id}
                                id="category"
                                value={formik.values.offer_category_id}
                                onChange={(e) => {
                                    formik.setFieldValue('offer_category_id', e.target.value)
                                }}
                                options={offerCategories}
                                labelPosition='right'
                            >
                                {offerCategories === [] ? null : offerCategories.map(category => (
                                    <MenuItem value={category.value}>{category.text}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        {/* Price */}
                        <FormControl className={classes.FormControl}>
                            <p className={classes.Label}>Price</p>

                            <div style={{display: 'inline-block'}}>
                                <Input
                                    id="price"
                                    error={formik.touched.price && formik.errors.price}
                                    className={classes.Input}
                                    {...formik.getFieldProps('price')}
                                    style={{width: '80%'}}
                                />
                                <Select
                                    defaultValue='PLN'
                                    onChange={(e, {value}) => formik.setFieldValue('currency', value)}
                                    style={{width: '20%'}}
                                >
                                    {options.map(option => (
                                        <MenuItem value={option.value}>{option.text}</MenuItem>
                                    ))}
                                </Select>
                            </div>

                            <p className={classes.InputFeedback}>{formik.touched.price && formik.errors.price ? formik.errors.price : null}</p>
                        </FormControl>

                        {/* Description */}
                        <FormControl className={classes.FormControl}>
                            <p className={classes.Label}>Description</p>
                            <CKEditor
                                id="description"
                                editor={ClassicEditor}
                                data={`${formik.values.description}`}
                                config={{
                                    toolbar: ['heading', '|', 'bold', 'italic', 'blockQuote', 'link', 'numberedList', 'bulletedList', 'imageUpload', 'insertTable',
                                        'tableColumn', 'tableRow', 'mergeTableCells', '|', 'undo', 'redo']
                                }}
                                onChange={(e, editor) => {
                                    const data = editor.getData();
                                    formik.setFieldValue('description', data)
                                }}
                            />
                            <p className={classes.InputFeedback}>{formik.errors.description}</p>
                        </FormControl>

                        <div className={ (formik.touched.lat && formik.touched.lng) && (formik.errors.lat || formik.errors.lng) ? "map-wrapper error" : "map-wrapper"}
                            style={{width: '100%', height: '450px', marginBottom: '20px'}}
                        >
                            <OfferMap
                                lat={id && offer ? formik.values.lat : null}
                                lng={id && offer ? formik.values.lng : null}
                                getLocation={getLocation}
                                setLatLng={setLatLng}
                            />
                        </div>

                        <Button
                            onClick={formik.handleSubmit}
                            variant="contained"
                            style={{backgroundColor: '#006400', color: "#fff"}}
                        >
                            {(id && offer) ? 'Save' : 'Add offer'}
                        </Button>
                    </form>
                </Paper>
            </div>
        }
        </>
    )
}

export default Offer;