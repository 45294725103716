import React, {useEffect, useRef, useState} from 'react';
import {useFormik} from "formik";
import * as Yup from 'yup';
import classes from "./Banner.module.scss";
import axios from "../../../axios-sw";
import {authRedirect, catchResponse, thenResponse} from "../../../shared/utility";
import Spinner from "../../../components/UI/Spinner/Spinner";
import {ToastContainer} from "react-toastify";
import {useSelector} from "react-redux";
import {Button, Paper} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import Checkbox from "@material-ui/core/Checkbox";
import Input from "@material-ui/core/Input";
import {ArrowBack, Delete} from "@material-ui/icons";
import Dropzone from "../../../components/System/OrderDropzone/OrderDropzone";
import DatePicker from "react-datepicker";


const Banner = props => {

    const [id, setId] = useState(props.match.params.id);
    const [loading, setLoading] = useState(true);
    const [banner, setBanner] = useState(null);
    const [img, setImg] = useState([]);

    const token = useSelector(({auth}) => auth.token);
    const userId = useSelector(({auth}) => auth.user_id);
    const ipAddress = useSelector(({auth}) => auth.ip_address);
    const dropzone = useRef();

    useEffect(() => {
        if (id) indexBanner(id);
        else setLoading(false);
    }, [id])

    const indexBanner = id => {
        axios.get(`/admin/banners/${id}.json`, {headers: {'Authorization': `Bearer ${token}`}})
            .then(async res => {
                await setBanner(res.data);
                await formik.setValues({
                    title: res.data.title,
                    img: res.data.img_url,
                    url: res.data.url,
                    created_at: res.data.created_at,
                    position: res.data.position
                })
                setLoading(false);
            })
            .catch(err => {
                catchResponse(err);
            })
    }

    const addBanner = data => {
        axios.post('/admin/banners', data, {headers: {'Authorization': `Bearer ${token}`}})
            .then(async res => {
                await updateImgFiles(img, res.data.banner_id, () => formik.setSubmitting(false));
                formik.setSubmitting(false);
                thenResponse(res);
                setId(res.data.banner_id);
                props.history.push(`/banner/${res.data.banner_id}`);
            })
            .catch(err => {
                formik.setSubmitting(false);
                catchResponse(err)
            });
    }

    const updateBanner = (id, data) => {
        axios.put(`/admin/banners/${id}`, data, {headers: {'Authorization': `Bearer ${token}`}})
            .then(res => {
                updateImgFiles(img, id, () => formik.setSubmitting(false));
                formik.setSubmitting(false);
                thenResponse(res);
            })
            .catch(err => {
                formik.setSubmitting(false);
                catchResponse(err)
            });
    }

    const validationSchema = Yup.object({
        title: Yup.string()
            .required('Tytuł jest wymagany'),
        url: Yup.string()
            .required('Url jest wymagany'),
        created_at: Yup.date()
            .required('Data jest wymagana'),
        position: Yup.string()
            .required('Pozycja jest wymagana'),
    })

    const formik = useFormik({
        initialValues: {
            title: '',
            img: '',
            url: '',
            created_at: Date.now(),
            position: ''
        },
        onSubmit: (values, actions) => {
            const data = {
                banner: {
                    title: values.title,
                    img_url: values.img,
                    url: values.url,
                    created_at: values.created_at,
                    position: values.position
                },
                user_id: userId,
                ip_address: ipAddress
            }
            if (id) updateBanner(id, data);
            else addBanner(data);
        },
        validationSchema
    });

    const updateImgFiles = (img_urls, bannerId, callback) => {
        if (img_urls === null) {
            const formDataPreview = new FormData();
            formDataPreview.append('img_urls[]', null);
            formDataPreview.append('user_id', userId);
            formDataPreview.append('ip_address', ipAddress);

            axios.post(`/admin/banners/img-urls/${bannerId}`, formDataPreview, {headers: {'Authorization': `Bearer ${token}`}})
                .catch(err => {
                    callback();
                    catchResponse(err)
                });

        } else if (img_urls.length > 0) {
            const formDataPreview = new FormData();
            for (let i = 0; i < img_urls.length; i++) {
                formDataPreview.append('img_urls[]', img_urls[i]);
            }
            formDataPreview.append('user_id', userId);
            formDataPreview.append('ip_address', ipAddress);
            axios.post(`/admin/banners/img-urls/${bannerId}`, formDataPreview, {headers: {'Authorization': `Bearer ${token}`}})
                .catch(err => {
                    callback();
                    catchResponse(err)
                });
        }
    };

    return (
        <>{loading ? <Spinner/> :
            <div className={classes.Banner}>
                {authRedirect(token)}
                <ToastContainer/>
                <Paper className={classes.Paper}>
                    <Button
                        className={classes.BackButton}
                        variant="contained"
                        onClick={() => props.history.push('/banners')}>
                        <ArrowBack/>
                    </Button>
                    {(id && banner) ? <h1>Banner: {banner.title}</h1> : <h1>Dodaj Reklamę</h1>}
                    <form className={classes.BannerForm} onSubmit={formik.handleSubmit}>
                        <FormControl className={classes.FormControl}>
                            <p className={classes.Label}>Nazwa</p>
                            <Input
                                id="title"
                                error={formik.errors.title ? true : false}
                                className={classes.Input}
                                {...formik.getFieldProps('title')}
                            />
                            <p className={classes.InputFeedback}>{formik.errors.title}</p>
                        </FormControl>

                        <FormControl className={classes.FormControl}>
                            <div className={classes.DropzoneContainer}>
                                <p className={classes.Label}>Zjęcie</p>
                                <div className={classes.Dropzone}>
                                    <Button className={classes.DropzoneDelete} onClick={(e) => {
                                        e.preventDefault();
                                        dropzone.current.setInitialFiles();
                                        const bannerData = banner;
                                        bannerData.img_url = [];
                                        setBanner(bannerData);
                                        setImg(null)
                                        formik.setFieldValue('img_url', null);
                                    }}>
                                        <Delete/>
                                    </Button>
                                    <Dropzone
                                        files={banner && typeof banner.img_url !== 'undefined' ? banner.img_url : []}
                                        disabled={false}
                                        ref={dropzone}
                                        onDrop={files => {
                                            formik.setFieldValue('img', files);
                                            setImg(files)
                                        }}/>
                                </div>
                                <p className={classes.InputFeedback}>{formik.errors.img}</p>
                            </div>
                        </FormControl>
                        <FormControl className={classes.FormControl}>
                            <p className={classes.Label}>Data Utworzenia</p>
                            <DatePicker
                                selected={new Date(formik.values.created_at)}
                                onChange={date => formik.setFieldValue('created_at', date)}
                                dateFormat="yyyy-MM-dd"
                            />
                            <p className={classes.InputFeedback}>{formik.errors.created_at}</p>
                        </FormControl>
                        <FormControl className={classes.FormControl}>
                            <p className={classes.Label}>Link</p>
                            <Input
                                id="url"
                                error={formik.errors.title ? true : false}
                                className={classes.Input}
                                {...formik.getFieldProps('url')}
                            />
                            <p className={classes.InputFeedback}>{formik.errors.url}</p>
                        </FormControl>

                        <FormControl className={classes.FormControl}>
                            <p className={classes.Label}>Pozycja</p>
                            <Input
                                id="position"
                                error={formik.errors.position ? true : false}
                                className={classes.Input}
                                {...formik.getFieldProps('position')}
                            />
                            <p className={classes.InputFeedback}>{formik.errors.position}</p>
                        </FormControl>
                        <Button
                            onClick={formik.handleSubmit}
                            variant="contained"
                            style={{backgroundColor: '#006400', color: "#fff"}}
                        >
                            {(id && banner) ? 'Zapisz zmiany' : 'Dodaj Reklamę'}
                        </Button>
                    </form>
                </Paper>
            </div>
        }
        </>
    )
}

export default Banner;