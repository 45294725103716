import React, { Component } from "react";
import { connect } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import {
    authRedirect,
    catchResponse,
    createSingleColumnFilterVal,
    createSortPath,
    formatDate,
    getAndConvertULRParams,
    getFilterPath,
    getSorthPath,
    thenResponse,
} from "../../shared/utility";
import axios from "../../axios-sw";
import { ArrowDownward, ArrowUpward, Check, Close, Email, GetApp, HighlightOff, Group, Remove } from "@material-ui/icons";
import Columns from "../../shared/tables";
import Spinner from "../../components/UI/Spinner/Spinner";
import { HotKeys } from "../../components/System/HotKeys/HotKeys";
import classes from "../../Common.module.scss";
import { Chip, TablePagination } from "@material-ui/core";
import MaterialTable, {
    MTableBody,
    MTableBodyRow,
    MTableEditRow,
    MTableFilterRow,
    MTableToolbar,
} from "material-table";
import { tableIcons, titleTemplate } from "../../shared/tableVariables";
import { defaultPageSize } from "../../shared/defines";
import moment from "moment";
import ProgressCircular from "../../components/UI/ProgressCircular/ProgressCircular";
import { json2excel } from "js2excel";
import SearchInput from "../../components/UI/SearchInput/SearchInput";
import TransitionsModal from "../../components/System/MailModal/MailModal";
import "react-toastify/dist/ReactToastify.min.css";
import UserSupportGroup from "./UserSupportGroup/UserSupportGroup";

const sortingPhraseHelper = [
    { key: "id", path: "users.id" },
    { key: "nickname", path: "users.nickname" },
    { key: "location", path: "users.location" },
    { key: "email", path: "users.email" },
    { key: "created_at", path: "users.created_at" },
    { key: "last_login", path: "users.last_login" },
    { key: "email_confirmed", path: "users.email_confirmed" },
    { key: "isActive", path: "users.isActive" },
    { key: "banned", path: "users.banned" },
    { key: "admin_permissions", path: "users.nickname" }, //TODO
    { key: "downtown", path: "users.downtown" },
    { key: "silesian", path: "users.silesian" },
    { key: "main_lang_id", path: "users.main_lang_id" },
];

class Users extends Component {
    state = {
        actions: [
            {
                position: "row",
                action: (rowData) => ({
                    icon: Email,
                    tooltip: "Wyślij Wiadomość",
                    onClick: (e, rowData) => {
                        this.setState({
                            openModal: true,
                            currentUser: rowData,
                        });
                    },
                }),
            },
            {
                position: "row",
                action: (rowData) => ({
                    icon: Group,
                    tooltip: "Dodaj do grupy wsparcia",
                    position: "row",
                    onClick: (e, rowData) => {
                        this.setState({ addToSupportGroupModalIsOpen: true, userId: rowData.id });
                    },
                }),
            },
            () => ({
                icon: Check,
                tooltip: "Aktywuj wybrane konta",
                onClick: (e, rowData) => {
                    this.massActiveUsers(rowData, true);
                },
            }),
            () => ({
                icon: Remove,
                tooltip: "Dezaktywuj wybrane konta",
                onClick: (e, rowData) => {
                    this.massActiveUsers(rowData, false);
                },
            })
        ],
        columns: Columns.usersColumns,
        columnsHidden: Columns.usersColumns,
        currentStateColumns: null,
        data: [],
        filteredData: [],
        filterPhrase: "",
        sortBy: null,
        user: null,
        sortingColumnsList: [],
        singleColumnFilterList: [],
        firstLoad: true,
        tableRef: React.createRef(),
        loaded: false,
        openModal: false,
        currentUser: null,
        addToSupportGroupModalIsOpen: false,
        userId: 0,
    };

    componentDidMount() {
        const { page, limit, search, sortingColumnsList, singleColumnFilterList } = getAndConvertULRParams(
            this.props.location.search,
            sortingPhraseHelper
        );
        this.indexUsers(page - 1, limit, search, sortingColumnsList, singleColumnFilterList);

        document.querySelectorAll(".MuiTableSortLabel-root").forEach((icon, index) => {
            icon.addEventListener("click", () => {
                let sortingColumnsList = this.state.sortingColumnsList;
                const table = this.state.tableRef.current;
                let indexIsExist = this.state.sortingColumnsList.findIndex(
                    (column) => column.sortBy.key === table.state.columns[index - 1].field
                );
                if (indexIsExist !== -1) {
                    if (sortingColumnsList[indexIsExist].order === "DESC") sortingColumnsList.splice(indexIsExist, 1);
                    else if (sortingColumnsList[indexIsExist].order === "ASC")
                        sortingColumnsList[indexIsExist].order = "DESC";
                    else if (!sortingColumnsList[indexIsExist].order) sortingColumnsList[indexIsExist].order = "ASC";
                    this.setState({ sortingColumnsList: sortingColumnsList });
                } else {
                    const sqlColumn = sortingPhraseHelper.filter(
                        (phrase) => phrase.key === table.state.columns[index - 1].field
                    );
                    sortingColumnsList = [...sortingColumnsList, { sortBy: sqlColumn[0], order: "ASC" }];
                    this.setState({ sortingColumnsList: sortingColumnsList });
                }

                this.goPage(
                    0,
                    this.state.limit,
                    this.state.filterPhrase,
                    sortingColumnsList,
                    this.state.singleColumnFilterList
                );
            });
        });
        const table = this.state.tableRef.current;
        singleColumnFilterList.forEach((filter) => {
            const index = this.state.tableRef.current.state.columns.findIndex(
                (column) => column.field === filter.column.key
            );
            if (filter.type === "boolean") {
                if (filter.value.includes("true")) this.state.tableRef.current.onFilterChange(index, "checked");
                else if (filter.value.includes("false")) this.state.tableRef.current.onFilterChange(index, undefined);
                else this.state.tableRef.current.onFilterChange(index, null);
            } else if (filter.type === "text") {
                const value = filter.value
                    .replace("LIKE", "")
                    .replace("LOWER", "")
                    .replace("('%", "")
                    .replace("%')", "")
                    .trim();
                this.state.tableRef.current.state.columns[index].tableData.initialValue = value;
            } else if (filter.type === "number") {
                const value = filter.value.replace("=", "").trim();
                this.state.tableRef.current.state.columns[index].tableData.initialValue = value;
            } else if (filter.type === "select") {
                /*WYJĄTKI*/
                if (
                    filter.column.key === "proforma_document" &&
                    filter.value === `="" OR ${filter.column.path} IS NULL`
                )
                    table.onFilterChange(index, "unadded");
                if (
                    filter.column.key === "proforma_document" &&
                    filter.value === `<> '' AND ${filter.column.path} IS NOT NULL AND ${filter.column.path}`
                )
                    table.onFilterChange(index, "added");
            } else if (filter.type === "multiSelect") {
                if (filter.column.key === "accepted") {
                    let value = [];
                    if (
                        filter.column.key === "accepted" &&
                        filter.value.includes(`LIKE "%%" OR ${filter.column.path} IS NULL`)
                    )
                        value = [...value, "all"];
                    if (
                        filter.column.key === "accepted" &&
                        filter.value.includes(`="" OR ${filter.column.path} IS NULL`)
                    )
                        value = [...value, "empty"];
                    if (
                        filter.column.key === "accepted" &&
                        filter.value.includes(`<> '' AND ${filter.column.path} IS NOT NULL AND ${filter.column.path}`)
                    )
                        value = [...value, "accepted"];
                    if (filter.column.key === "accepted" && filter.value.includes(`LIKE "%hold%"`))
                        value = [...value, "hold"];
                    if (filter.column.key === "accepted" && filter.value.includes(`LIKE "%cancel%"`))
                        value = [...value, "cancel"];
                    this.state.tableRef.current.state.columns[index].tableData.initialValue = value;
                } else {
                    let value = filter.value
                        .split("LIKE")
                        .join("")
                        .split("'%")
                        .join("")
                        .split("%'")
                        .join("")
                        .split(`OR ${filter.column.path}`)
                        .join(",")
                        .trim();
                    let valueArray = value.split(",").map((item) => item.trim());
                    table.onFilterChange(index, valueArray);
                }
            }
        });
        if (singleColumnFilterList.filter((filter) => filter.type === "boolean").length === 0) {
            for (let i = 0; i < table.state.columns.length; i++) {
                if (table.state.columns[i].type !== "select" && table.state.columns[i].type !== "multiSelect") {
                    table.onFilterChange(i, null);
                    table.state.columns[i].tableData.filterValue = null;
                }
            }
        }
    }

    handleSerach = (val) => {
        let convertVal = val.split("'").join("");

        const table = this.state.tableRef.current;
        const singleColumnFilterList = [];
        table.state.columns.forEach((column) => {
            column.tableData.initialValue = "";
        });
        this.goPage(0, this.state.limit, convertVal, this.state.sortingColumnsList, singleColumnFilterList);
        this.setState({ filterPhrase: convertVal, singleColumnFilterList: singleColumnFilterList });
    };

    goPage = (page = 0, limit = 20, search = null, sortingColumnsList = [], singleColumnFilterList = []) => {
        this.setState({ loaded: false, filteredData: [], data: [] });
        const searchVal = search && search.trim().length > 0 ? `&search=${search}` : "";

        const sortByVal = createSortPath(sortingColumnsList);
        const singleColumnFilterVal = createSingleColumnFilterVal(singleColumnFilterList);

        this.props.history.push(
            `/users?page=${page + 1}&limit=${limit}${searchVal}${sortByVal}${singleColumnFilterVal}`
        );
        this.indexUsers(
            page,
            limit,
            search,
            sortingColumnsList,
            singleColumnFilterList,
            this.state.currentStateColumns
        );
    };

    indexUsers(page = 1, limit = 20, phrase = null, sortingColumnsList = [], singleColumnFilterList = []) {
        const sortPath = getSorthPath(sortingColumnsList);
        const filterPath = getFilterPath(singleColumnFilterList);
        const startTime = Date.now();

        axios
            .get(
                `admin/users/${page}/${limit}.json`,
                {
                    params: {
                        phrase: phrase,
                        sortPath: sortPath,
                        filterPath: filterPath,
                    },
                },
                { headers: { Authorization: `Bearer ${this.props.token}` } }
            )
            .then((res) => {
                this.state.tableRef.current.state.orderBy = -1;
                this.state.tableRef.current.state.orderDirection = "";
                this.state.tableRef.current.dataManager.orderBy = -1;
                this.state.tableRef.current.dataManager.orderDirection = "";

                const responseTime = (Date.now() - startTime) / 1000;
                console.log("Response time: " + responseTime + " s");

                this.setState({
                    data: res.data.users,
                    filteredData: res.data.users,
                    totalRow: res.data.total_row,
                    page: parseInt(page),
                    limit: parseInt(limit),
                    filterPhrase: phrase,
                    sortingColumnsList: sortingColumnsList,
                    singleColumnFilterList: singleColumnFilterList,
                    loaded: true,
                });
            })
            .catch((err) => {
                catchResponse(err);
            });
    }

    massActiveUsers = (rowData, positive) => {
        const userIds = [];
        rowData.map((row) => {
            userIds.push(row.id);
        });

        const data = {
            usersIds: userIds,
            positive: positive,
            user_id: this.props.user_id,
            ip_address: this.props.ip_address,
        };

        axios
            .post(`admin/users/mass-active.json`, data, { headers: { Authorization: `Bearer ${this.props.token}` } })
            .then((res) => {
                console.log(res.data)
                if (res.data.activatedUsersIds.length > 0) {
                    const data = [...this.state.data];
                    res.data.activatedUsersIds.map(userId => {
                        const index = data.findIndex((x) => x.id === parseInt(userId));
                        if (index !== -1) {
                            data[index].email_confirmed = positive
                        }
                    })
                    toast.success(`Email ${positive ? 'aktywowany' : 'dezaktywowany'} dla kont o nickach: ${res.data.activatedUsersNames.join(', ')}`)
                    this.setState({ data: data, filteredData: data });
                }
            })
            .catch((err) => {
                catchResponse(err);
            });
    };

    updateUser = (id, newData) => {
        /*lokalne grupy wsparcia*/
        const location = newData.silesian ? "Śląskie" : newData.downtown ? "Mazowieckie" : "";

        const data = {
            user: {
                id: newData.id,
                nickname: newData.nickname,
                email: newData.email,
                location: location,
                created_at: newData.created_at,
                last_login: newData.last_login,
                email_confirmed: newData.email_confirmed,
                isActive: newData.isActive,
                banned: newData.banned,
            },
            /* Activity logging */
            user_id: this.props.user_id,
            ip_address: this.props.ip_address,
        };
        const url = `admin/users/${id}.json`;
        axios
            .put(url, data, { headers: { Authorization: `Bearer ${this.props.token}` } })
            .then((res) => {
                axios
                    .get(url, { headers: { Authorization: `Bearer ${this.props.token}` } })
                    .then((res) => {
                        const fetchedOrder = res.data;
                        const array = [...this.state.data];
                        const index = array.findIndex((x) => x.id === fetchedOrder.id);
                        if (index !== -1) {
                            array[index].id = fetchedOrder.id;
                            array[index].nickname = fetchedOrder.nickname;
                            array[index].email = fetchedOrder.email;
                            array[index].location = fetchedOrder.location;
                            array[index].shipment_date = fetchedOrder.shipment_date;
                            array[index].created_at = fetchedOrder.created_at;
                            array[index].last_login = fetchedOrder.last_login;
                            array[index].email_confirmed = fetchedOrder.email_confirmed;
                            array[index].isActive = fetchedOrder.isActive;
                            array[index].banned = fetchedOrder.banned;
                            array[index].deleted = fetchedOrder.deleted;
                            array[index].downtown = fetchedOrder.downtown;
                            array[index].silesian = fetchedOrder.silesian;
                            this.setState({ data: array, filteredData: array });
                        }
                    })
                    .catch((err) => {
                        catchResponse(err);
                    });
                thenResponse(res);
            })
            .catch((err) => {
                catchResponse(err);
            });
    };

    deleteUser = (newData) => {
        const data = {
            email: newData.email,
            /* Activity logging */
            user_id: this.props.user_id,
            ip_address: this.props.ip_address,
        };

        const url = `admin/users/delete-user`;
        axios
            .put(url, data, { headers: { Authorization: `Bearer ${this.props.token}` } })
            .then((res) => {
                axios
                    .get(`admin/users/${newData.id}.json`, { headers: { Authorization: `Bearer ${this.props.token}` } })
                    .then((res) => {
                        const fetchedOrder = res.data;
                        const array = [...this.state.data];
                        const index = array.findIndex((x) => x.id === fetchedOrder.id);
                        if (index !== -1) {
                            array[index].id = fetchedOrder.id;
                            array[index].nickname = fetchedOrder.nickname;
                            array[index].email = fetchedOrder.email;
                            array[index].location = fetchedOrder.location;
                            array[index].shipment_date = fetchedOrder.shipment_date;
                            array[index].created_at = fetchedOrder.created_at;
                            array[index].last_login = fetchedOrder.last_login;
                            array[index].email_confirmed = fetchedOrder.email_confirmed;
                            array[index].isActive = fetchedOrder.isActive;
                            array[index].banned = fetchedOrder.banned;
                            array[index].deleted = fetchedOrder.deleted;
                            array[index].downtown = fetchedOrder.downtown;
                            array[index].silesian = fetchedOrder.silesian;
                            this.setState({ data: array, filteredData: array });
                        }
                    })
                    .catch((err) => {
                        catchResponse(err);
                    });
                thenResponse(res);
            })
            .catch((err) => {
                catchResponse(err);
            });
    };

    closeModal = () => {
        this.setState({
            openModal: false,
            currentUser: null,
        });
    };

    sendEmail = (values, currentUser) => {
        const data = {
            email: currentUser.email,
            topic: values.topic,
            content: values.content,
        };
        axios
            .post(`/admin/users/send-noreply-email`, data, { headers: { Authorization: `Bearer ${this.props.token}` } })
            .then((res) => {
                thenResponse(res);
                this.closeModal();
            })
            .catch((err) => {
                catchResponse(err);
            });
    };

    render() {
        let emptyDataMessage;
        if (!this.state.loaded) {
            emptyDataMessage = <Spinner />;
        } else {
            emptyDataMessage = "No data to display";
        }

        return (
            <>
                {this.state.openModal && (
                    <TransitionsModal
                        currentUser={this.state.currentUser}
                        closeModal={this.closeModal}
                        sendEmail={this.sendEmail}
                    />
                )}
                {this.state.addToSupportGroupModalIsOpen && (
                    <UserSupportGroup
                        token={this.props.token}
                        user_id={this.state.userId}
                        ip_address={this.props.ip_address}
                        onClose={() => this.setState({ addToSupportGroupModalIsOpen: false })}
                    />
                )}
                {!this.state.loaded && <div className={"overlay"}></div>}
                {authRedirect(this.props.token)}
                <ToastContainer />
                <HotKeys />
                <MaterialTable
                    tableRef={this.state.tableRef}
                    title={titleTemplate("Użytkownicy")}
                    localization={{
                        body: {
                            emptyDataSourceMessage: emptyDataMessage,
                        },
                    }}
                    icons={tableIcons}
                    columns={this.state.columnsHidden}
                    data={this.state.filteredData}
                    style={{ width: "100%" }}
                    actions={this.state.actions}
                    options={{
                        search: false,
                        pageSize: defaultPageSize,
                        filtering: true,
                        selection: true,
                    }}
                    components={{
                        Body: (props) => {
                            return (
                                <MTableBody
                                    {...props}
                                    onFilterChanged={(columnId, value) => {
                                        const columnName = this.state.columnsHidden[columnId].field;
                                        let singleColumnFilterList = this.state.singleColumnFilterList;
                                        let indexIsExist = this.state.singleColumnFilterList.findIndex(
                                            (filter) => filter.column.key === columnName
                                        );
                                        const sqlColumn = sortingPhraseHelper.filter(
                                            (phrase) => phrase.key === columnName
                                        );
                                        const table = this.state.tableRef.current;

                                        if (this.state.columnsHidden[columnId].type === "dateType") {
                                            if (indexIsExist !== -1) {
                                                if (value.isEmpty) {
                                                    singleColumnFilterList[indexIsExist].value = "IS NULL";
                                                } else if (value.isFull) {
                                                    singleColumnFilterList[indexIsExist].value = "IS NOT NULL";
                                                } else {
                                                    const start = moment(value.start).format("YYYY-MM-DD");
                                                    const end = moment(value.end).format("YYYY-MM-DD");
                                                    singleColumnFilterList[
                                                        indexIsExist
                                                    ].value = `BETWEEN "${start}" AND "${end}"`;
                                                }
                                            } else {
                                                if (value.isEmpty) {
                                                    singleColumnFilterList = [
                                                        ...singleColumnFilterList,
                                                        {
                                                            column: sqlColumn[0],
                                                            value: "IS NULL",
                                                            type: "dateType",
                                                        },
                                                    ];
                                                } else if (value.isFull) {
                                                    singleColumnFilterList = [
                                                        ...singleColumnFilterList,
                                                        {
                                                            column: sqlColumn[0],
                                                            value: "IS NOT NULL",
                                                            type: "dateType",
                                                        },
                                                    ];
                                                } else {
                                                    const start = moment(value.start).format("YYYY-MM-DD");
                                                    const end = moment(value.end).format("YYYY-MM-DD");
                                                    singleColumnFilterList = [
                                                        ...singleColumnFilterList,
                                                        {
                                                            column: sqlColumn[0],
                                                            value: `BETWEEN "${start}" AND "${end}"`,
                                                            type: "dateType",
                                                        },
                                                    ];
                                                }
                                            }
                                        } else if (this.state.columnsHidden[columnId].type === "text") {
                                            if (indexIsExist !== -1) {
                                                singleColumnFilterList[indexIsExist].value = `LIKE LOWER('%${value}%')`;
                                                table.state.columns[columnId].tableData.initialValue = value;
                                            } else {
                                                singleColumnFilterList = [
                                                    ...singleColumnFilterList,
                                                    {
                                                        column: sqlColumn[0],
                                                        value: `LIKE LOWER('%${value}%')`,
                                                        type: "text",
                                                    },
                                                ];
                                                table.state.columns[columnId].tableData.initialValue = value;
                                            }
                                        } else if (this.state.columnsHidden[columnId].type === "boolean") {
                                            if (indexIsExist !== -1) {
                                                if (value === "checked") {
                                                    singleColumnFilterList.splice(indexIsExist, 1);
                                                    table.onFilterChange(columnId, null);
                                                } else if (value === "unchecked") {
                                                    singleColumnFilterList[
                                                        indexIsExist
                                                    ].value = `=false OR ${sqlColumn[0].path} IS NULL`;
                                                    table.onFilterChange(columnId, undefined);
                                                } else if (!value) {
                                                    singleColumnFilterList[indexIsExist].value = "=true";
                                                    table.onFilterChange(columnId, "checked");
                                                }
                                            } else {
                                                singleColumnFilterList = [
                                                    ...singleColumnFilterList,
                                                    {
                                                        column: sqlColumn[0],
                                                        value: "=true",
                                                        type: "boolean",
                                                    },
                                                ];
                                                table.onFilterChange(columnId, "checked");
                                            }
                                        } else if (this.state.columnsHidden[columnId].type === "number") {
                                            const convertValue = value
                                                ? parseFloat(value.replace(/\s/g, "").replace(",", "."))
                                                : 0;

                                            if (indexIsExist !== -1) {
                                                singleColumnFilterList[indexIsExist].value = `= ${convertValue}`;
                                                table.state.columns[columnId].tableData.initialValue = convertValue;
                                            } else {
                                                singleColumnFilterList = [
                                                    ...singleColumnFilterList,
                                                    {
                                                        column: sqlColumn[0],
                                                        value: `= ${convertValue}`,
                                                        type: "number",
                                                    },
                                                ];
                                                table.state.columns[columnId].tableData.initialValue = convertValue;
                                            }
                                        } else if (this.state.columnsHidden[columnId].type === "select") {
                                            if (value === "all") value = `LIKE "%%" OR ${sqlColumn[0].path} IS NULL`;
                                            else if (value === "empty" || value === "unadded")
                                                value = `="" OR ${sqlColumn[0].path} IS NULL`;
                                            else if (value === "accepted" || value === "added")
                                                value = `<> '' AND ${sqlColumn[0].path} IS NOT NULL AND ${sqlColumn[0].path}`;
                                            else value = `LIKE "%${value}%"`;

                                            if (indexIsExist !== -1) {
                                                singleColumnFilterList[indexIsExist].value = value;
                                                table.state.columns[columnId].tableData.initialValue = value;
                                            } else {
                                                singleColumnFilterList = [
                                                    ...singleColumnFilterList,
                                                    {
                                                        column: sqlColumn[0],
                                                        value: value,
                                                        type: "select",
                                                    },
                                                ];
                                                table.state.columns[columnId].tableData.initialValue = value;
                                            }
                                        } else if (this.state.columnsHidden[columnId].type === "multiSelect") {
                                            if (this.state.columnsHidden[columnId].field === "accepted") {
                                                let convertValue = "";
                                                value.forEach((item, index) => {
                                                    if (item === "all")
                                                        convertValue +=
                                                            index < value.length - 1
                                                                ? `LIKE "%%" OR ${sqlColumn[0].path} IS NULL OR ${sqlColumn[0].path} `
                                                                : `LIKE "%%" OR ${sqlColumn[0].path} IS NULL`;
                                                    else if (item === "empty" || item === "unadded")
                                                        convertValue +=
                                                            index < value.length - 1
                                                                ? ` ="" OR ${sqlColumn[0].path} IS NULL OR ${sqlColumn[0].path} `
                                                                : `="" OR ${sqlColumn[0].path} IS NULL`;
                                                    else if (item === "accepted" || item === "added")
                                                        convertValue +=
                                                            index < value.length - 1
                                                                ? `OR ${sqlColumn[0].path} <> '' AND ${sqlColumn[0].path} IS NOT NULL AND ${sqlColumn[0].path} OR ${sqlColumn[0].path} `
                                                                : `<> '' AND ${sqlColumn[0].path} IS NOT NULL AND ${sqlColumn[0].path}`;
                                                    else
                                                        convertValue +=
                                                            index < value.length - 1
                                                                ? `LIKE "%${item}%" OR ${sqlColumn[0].path} `
                                                                : `LIKE "%${item}%"`;
                                                });

                                                if (value.length > 0) {
                                                    if (indexIsExist !== -1) {
                                                        singleColumnFilterList[indexIsExist].value = convertValue;
                                                        table.state.columns[columnId].tableData.initialValue = value;
                                                    } else {
                                                        singleColumnFilterList = [
                                                            ...singleColumnFilterList,
                                                            {
                                                                column: sqlColumn[0],
                                                                value: convertValue,
                                                                type: "multiSelect",
                                                            },
                                                        ];
                                                        table.state.columns[columnId].tableData.initialValue = value;
                                                    }
                                                } else {
                                                    singleColumnFilterList = singleColumnFilterList.filter(
                                                        (filter) => filter.column.key !== "accepted"
                                                    );
                                                    table.state.columns[columnId].tableData.initialValue = ["all"];
                                                }
                                            } else {
                                                if (indexIsExist !== -1) {
                                                    let multiFiltersValues = "";
                                                    value.forEach((filter, index) => {
                                                        multiFiltersValues +=
                                                            index === 0
                                                                ? `LIKE '%${value[index]}%'`
                                                                : ` OR ${sqlColumn[0].path} LIKE '%${value[index]}%'`;
                                                    });
                                                    singleColumnFilterList[indexIsExist].value = multiFiltersValues;
                                                    table.onFilterChange(columnId, value);
                                                } else {
                                                    singleColumnFilterList = [
                                                        ...singleColumnFilterList,
                                                        {
                                                            column: sqlColumn[0],
                                                            value: `LIKE '%${value}%'`,
                                                            type: "multiSelect",
                                                        },
                                                    ];
                                                    table.onFilterChange(columnId, value);
                                                }
                                            }
                                        }
                                        this.setState({
                                            singleColumnFilterList: singleColumnFilterList,
                                            filterPhrase: "",
                                        });
                                        this.goPage(
                                            0,
                                            this.state.limit,
                                            "",
                                            this.state.sortingColumnsList,
                                            singleColumnFilterList
                                        );
                                    }}
                                />
                            );
                        },
                        Pagination: (props) => {
                            return (
                                <TablePagination
                                    {...props}
                                    rowsPerPage={this.state.limit}
                                    count={this.state.totalRow}
                                    page={this.state.page}
                                    onChangePage={(e, page) => {
                                        this.state.tableRef.current.onChangePage(e, page);
                                        this.setState({ page: page });
                                        this.goPage(
                                            page,
                                            this.state.limit,
                                            this.state.filterPhrase,
                                            this.state.sortingColumnsList,
                                            this.state.singleColumnFilterList
                                        );
                                    }}
                                    onChangeRowsPerPage={(event) => {
                                        props.onChangeRowsPerPage(event);
                                        this.setState({ limit: event.target.value });
                                        this.goPage(
                                            this.state.page,
                                            event.target.value,
                                            this.state.filterPhrase,
                                            this.state.sortingColumnsList,
                                            this.state.singleColumnFilterList
                                        );
                                    }}
                                />
                            );
                        },
                        FilterRow: (props) => {
                            return <MTableFilterRow className="filterRow" {...props} />;
                        },
                        Row: (props) => <MTableBodyRow className={props.data.styles} {...props} />,
                        EditRow: (props) => <MTableEditRow className="editingRow" {...props} />,
                        Toolbar: (props) => {
                            const table = this.state.tableRef.current;

                            return (
                                <div className="mainToolbar">
                                    <MTableToolbar {...props} />

                                    {/* Usuwanie filtra z tabeli */}
                                    <Chip
                                        variant="outlined"
                                        style={{ marginLeft: 24, marginTop: 5 }}
                                        avatar={<HighlightOff />}
                                        label="Clear filters"
                                        onClick={() => {
                                            this.goPage();
                                            this.props.history.push(`/users?page=${1}&limit=${20}`);

                                            table.state.columns.forEach((column, index) => {
                                                if (column.field === "accepted") {
                                                    table.state.columns[index].tableData.filterValue = "all";
                                                    column.tableData.initialValue = "all";
                                                } else {
                                                    table.onFilterChange(index, null);
                                                    table.state.columns[index].tableData.filterValue = null;
                                                    column.tableData.initialValue = "";
                                                }
                                            });
                                        }}
                                    />
                                    {/* Eksportowanie danych z tabeli */}
                                    <Chip
                                        variant="outlined"
                                        style={{ marginLeft: 24, marginTop: 10 }}
                                        avatar={
                                            this.state.loaded ? (
                                                <GetApp style={{ padding: 5 }} />
                                            ) : (
                                                <ProgressCircular size={20} />
                                            )
                                        }
                                        label="Export data to Excel"
                                        onClick={() => {
                                            const startTime = Date.now();
                                            this.setState({ loaded: false, filteredData: [] });

                                            const {
                                                search,
                                                sortingColumnsList,
                                                singleColumnFilterList,
                                            } = getAndConvertULRParams(this.props.location.search, sortingPhraseHelper);
                                            const sortPath = getSorthPath(sortingColumnsList);
                                            const filterPath = getFilterPath(singleColumnFilterList);

                                            axios
                                                .get(
                                                    `admin/export-users-to-excel.json`,
                                                    {
                                                        params: {
                                                            phrase: search,
                                                            sortPath: sortPath,
                                                            filterPath: filterPath,
                                                        },
                                                    },
                                                    { headers: { Authorization: `Bearer ${this.props.token}` } }
                                                )
                                                .then((res) => {
                                                    console.log(
                                                        "Response time: " + (Date.now() - startTime) / 1000 + " s"
                                                    );
                                                    const usersData = res.data.users;
                                                    let filteredUsersData = usersData.map((user) => {
                                                        return {
                                                            id: user.id,
                                                            nazwa_użytkownika: user.nickname,
                                                            województwo: user.location,
                                                            email: user.email,
                                                            data_rejestracji: user.created_at,
                                                            ostatnio_aktywny: user.last_login,
                                                            email_aktywny: user.email_confirmed ? "TAK" : "NIE",
                                                            konto_aktywne: user.isActive ? "TAK" : "NIE",
                                                            konto_zablokowane: user.banned ? "TAK" : "NIE",
                                                            śródmieście: user.downtown ? "TAK" : "NIE",
                                                            śląskie: user.silesian ? "TAK" : "NIE",
                                                        };
                                                    });

                                                    json2excel({
                                                        data: filteredUsersData,
                                                        name: "users_" + formatDate(Date.now()),
                                                        formateDate: "dd/mm/yyyy",
                                                    });

                                                    this.setState({ loaded: true, filteredData: this.state.data });
                                                })
                                                .catch((err) => {
                                                    catchResponse(err);
                                                });
                                        }}
                                    />

                                    {/*Generowanie tagu szukania*/}
                                    {this.state.filterPhrase && this.state.filterPhrase.trim().length > 0 && (
                                        <Chip
                                            style={{ marginLeft: 24, marginTop: 10 }}
                                            onDelete={() => {
                                                this.goPage(
                                                    0,
                                                    this.state.limit,
                                                    "",
                                                    this.state.sortingColumnsList,
                                                    this.state.singleColumnFilterList
                                                );
                                                this.setState({ filterPhrase: "" });
                                            }}
                                            label={`search: ${this.state.filterPhrase}`}
                                            color="primary"
                                        />
                                    )}

                                    {/*Generowanie tagów filtrów kolumn*/}
                                    {this.state.singleColumnFilterList.map((filter) => {
                                        let value;
                                        let key;
                                        let icon = "";
                                        if (filter.type === "dateType")
                                            value = filter.value.replace("BETWEEN", "").replace("AND", "-");
                                        else if (filter.type === "text")
                                            value = filter.value
                                                .replace("LIKE", "")
                                                .replace("LOWER", "")
                                                .replace("('%", "")
                                                .replace("%')", "");
                                        else if (filter.type === "select") {
                                            if (filter.column.key === "main_lang_id") {
                                                value = filter.value === `LIKE "%1%"` ? "Polski" : "Rosyjski";
                                            } else {
                                                value = filter.value
                                                    .replace("LIKE", "")
                                                    .replace("'%", "")
                                                    .replace("%'", "");
                                            }
                                        } else if (filter.type === "multiSelect")
                                            value = filter.value
                                                .split("LIKE")
                                                .join("")
                                                .split("'%")
                                                .join("")
                                                .split("%'")
                                                .join("")
                                                .split(`OR ${filter.column.path}`)
                                                .join(",");
                                        else if (filter.type === "number") value = filter.value.replace("=", "");
                                        else if (filter.type === "boolean") {
                                            if (filter.value.includes("true")) {
                                                value = "checked";
                                                icon = <Check />;
                                            } else {
                                                value = "unchecked";
                                                icon = <Close />;
                                            }
                                        }
                                        key = this.state.columns.filter(
                                            (column) => column.field === filter.column.key
                                        )[0].title;

                                        return (
                                            <Chip
                                                style={{ marginLeft: 24, marginTop: 10 }}
                                                onDelete={() => {
                                                    const singleColumnFilterList = this.state.singleColumnFilterList.filter(
                                                        (item) => item.column.key !== filter.column.key
                                                    );
                                                    this.goPage(
                                                        0,
                                                        this.state.limit,
                                                        this.state.filterPhrase,
                                                        this.state.sortingColumnsList,
                                                        singleColumnFilterList
                                                    );
                                                    this.setState({ singleColumnFilterList: singleColumnFilterList });
                                                    const index = table.state.columns.findIndex(
                                                        (item) => item.field === filter.column.key
                                                    );
                                                    if (index !== -1) {
                                                        if (table.state.columns[index].field === "accepted") {
                                                            table.state.columns[index].tableData.initialValue = ["all"];
                                                        } else {
                                                            table.state.columns[index].tableData.initialValue = "";
                                                            table.onFilterChange(index, null);
                                                        }
                                                    }
                                                }}
                                                label={`${key.replace("_", " ")}:  ${value}`}
                                                icon={icon}
                                                variant="outlined"
                                            />
                                        );
                                    })}

                                    {/*Generowanie tagów sortowania*/}
                                    {this.state.sortingColumnsList.map((column) => {
                                        let key;
                                        let icon = "";
                                        if (column.order === "ASC") icon = <ArrowDownward />;
                                        if (column.order === "DESC") icon = <ArrowUpward />;

                                        const columnKey = column.sortBy.key;
                                        key = this.state.columns.filter((column) => column.field === columnKey)[0]
                                            .title;

                                        return (
                                            <Chip
                                                style={{ marginLeft: 24, marginTop: 10 }}
                                                onDelete={() => {
                                                    const sortingColumnsList = this.state.sortingColumnsList.filter(
                                                        (item) => item.sortBy.key !== column.sortBy.key
                                                    );
                                                    this.goPage(
                                                        0,
                                                        this.state.limit,
                                                        this.state.filterPhrase,
                                                        sortingColumnsList,
                                                        this.state.singleColumnFilterList
                                                    );
                                                    this.setState({ sortingColumnsList: sortingColumnsList });
                                                }}
                                                label={`${key}`}
                                                icon={icon}
                                            />
                                        );
                                    })}

                                    <div className={classes.SearchWrapper}>
                                        <SearchInput
                                            filterPhrase={this.state.filterPhrase}
                                            onChange={this.handleSerach}
                                        />
                                    </div>
                                </div>
                            );
                        },
                    }}
                    editable={{
                        onRowUpdate: (newData, oldData) =>
                            new Promise((resolve) => {
                                resolve();
                                this.updateUser(oldData.id, newData);
                            }),
                        onRowDelete: (newData, oldData) =>
                            new Promise((resolve) => {
                                resolve();
                                newData.deleted ? toast.error("User już został usunięty!") : this.deleteUser(newData);
                            }),
                    }}
                />
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user_id: state.auth.user_id,
        ip_address: state.auth.ip_address,
        token: state.auth.token,
        role: state.auth.role,
    };
};

export default connect(mapStateToProps)(Users);
