import React, { Component } from "react";
import { connect } from "react-redux";

import CssBaseline from "@material-ui/core/CssBaseline";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

import { Link } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import clsx from "clsx";
import Close from "@material-ui/icons/Close";
import MenuIcon from "@material-ui/icons/Menu";
import Typography from "@material-ui/core/Typography";
import IconLinks from "./IconLinks/IconLinks";
import Drawer from "@material-ui/core/Drawer";
import AccountPreview from "./AccountPreview/AccountPreview";
import DrawerLinks from "./DrawerLinks/DrawerLinks";

import { withStyles } from "@material-ui/core";

import { setSeasons } from "../../../store/reducers/systemInfo";

const drawerWidth = 200;

const styles = (theme) => ({
    appBar: {
        backgroundColor: "black",
        marginTop: -2,
        zIndex: 9000,
        paddingRight: "0 !important",
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    menuButtonHidden: {
        display: "none",
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        whiteSpace: "nowrap",
        width: drawerWidth,
        paddingTop: 80,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: "100vh",
        overflow: "auto",
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
    },
    fixedHeight: {
        height: 240,
    },
    accountPreview: {
        alignItems: "center",
        justifyContent: "center",
    },
    contentPreview: {
        width: "100%",
        textAlign: "center",
        paddingBottom: "20px",
    },
    multiSelectWrapper: {
        width: "300px",
        color: "#000",
    },
    GWPanel: {
        color: "#fff",
        textDecoration: "none",
    },
});

class Navbar extends Component {
    state = {
        open: false,
        seasons: [],
    };

    onClickDrawer = () => {
        this.setState({ open: !this.state.open });
    };

    render() {
        const { classes } = this.props;
        return (
            <div style={{ height: "100%" }}>
                <CssBaseline />
                <AppBar position="fixed" className={classes.appBar}>
                    <Toolbar className={classes.toolbar}>
                        {/* Otwieranie drawer */}
                        {this.props.token === null ? null : (
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={this.onClickDrawer}
                                edge="start"
                                className={clsx(classes.menuButton, this.state.open && classes.hide)}
                            >
                                {this.state.open ? <Close /> : <MenuIcon />}
                            </IconButton>
                        )}

                        <Typography variant="body1" color="inherit" noWrap className={classes.title}>
                            <Link className={classes.GWPanel} to="/">
                                <p>GWPanel</p>
                            </Link>
                        </Typography>

                        {this.props.token === null ? null : (
                            <IconLinks
                                onClickLinkHandler={() => this.setState({ open: false })}
                                style={{ float: "right" }}
                                avatar={this.props.avatar}
                                user_id={this.props.user_id}
                                role={this.props.role}
                                nickname={this.props.nickname}
                            />
                        )}
                    </Toolbar>

                    {this.props.token === null && this.props.user_id === null ? null : (
                        <Drawer
                            classes={{
                                paper: classes.drawerPaper,
                            }}
                            anchor="left"
                            open={this.state.open}
                            onClose={this.onClickDrawer}
                            style={{ height: "100%" }}
                        >
                            <div className={classes.accountPreview}>
                                <div className={classes.contentPreview}>
                                    <AccountPreview
                                        user_id={this.props.user_id}
                                        avatar_url={this.props.avatar}
                                        nickname={this.props.nickname}
                                        position={this.props.position}
                                        onClick={this.onClickDrawer}
                                    />
                                </div>
                            </div>

                            <DrawerLinks
                                onClickLinkHandler={this.onClickDrawer}
                                user_id={this.props.user_id}
                                role={this.props.role}
                                nickname={this.props.nickname}
                            />
                        </Drawer>
                    )}
                </AppBar>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        token: state.auth.token,
        user_id: state.auth.user_id,
        role: state.auth.role,
        nickname: state.auth.nickname,
        avatar: state.auth.avatar,
        position: state.auth.position,
        currentSeasons: state.systemInfo.currentSeasons,
    };
};

const mapDispatchToProps = { setSeasons };

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Navbar));
