import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

/* Redux */
import thunk from 'redux-thunk';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';

/* Reducers */
import authReducer from './store/reducers/auth';
import systemInfoReducer from "./store/reducers/systemInfo";

const composeEnhancers = process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__: null || compose;

const rootReducer = combineReducers({
    auth: authReducer,
    systemInfo: systemInfoReducer
});

/* REDUX store */
const store = createStore(
    rootReducer, composeEnhancers(
        applyMiddleware(thunk)
    )
);

const app = (
    <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>
);

document.body.style.zoom = 1;

ReactDOM.render(app, document.getElementById('root'));

serviceWorker.unregister(); // TODO: Change before deploy
