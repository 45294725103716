import React, {useEffect, useRef, useState} from 'react';
import {useFormik} from "formik";
import * as Yup from 'yup';
import classes from "./OnlineWebinar.module.scss";
import axios from "../../../axios-sw";
import {authRedirect, catchResponse, thenResponse} from "../../../shared/utility";
import Spinner from "../../../components/UI/Spinner/Spinner";
import {ToastContainer} from "react-toastify";
import {useSelector} from "react-redux";
import {Button, Paper} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import Checkbox from "@material-ui/core/Checkbox";
import Input from "@material-ui/core/Input";
import {ArrowBack, Delete} from "@material-ui/icons";
import Dropzone from "../../../components/System/OrderDropzone/OrderDropzone";
import DatePicker from "react-datepicker";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";


const OnlineWebinar = props => {

    const [id, setId] = useState(props.match.params.id);
    const [loading, setLoading] = useState(true);
    const [online_webinar, setOnlineWebinar] = useState(null);
    const [img, setImg] = useState([]);

    const token = useSelector(({auth}) => auth.token);
    const userId = useSelector(({auth}) => auth.user_id);
    const langId = useSelector(({auth}) => auth.main_lang_id);
    const ipAddress = useSelector(({auth}) => auth.ip_address);
    const dropzone = useRef();

    useEffect(() => {
        if (id) indexOnlineWebinar(id);
        else setLoading(false);
    }, [id])

    const indexOnlineWebinar = id => {
        axios.get(`/admin/online-webinars/${id}.json`, {headers: {'Authorization': `Bearer ${token}`}})
            .then(async res => {
                await setOnlineWebinar(res.data);
                await formik.setValues({
                    title: res.data.title,
                    img: res.data.img_url,
                    url: res.data.url,
                    start_date: res.data.start_date,
                    author: res.data.author,
                    description: res.data.description
                })
                setLoading(false);
            })
            .catch(err => {
                catchResponse(err);
            })
    }

    const addOnlineWebinar = data => {
        axios.post('/admin/online-webinars', data, {headers: {'Authorization': `Bearer ${token}`}})
            .then(async res => {
                await updateImgFiles(img, res.data.online_webinar_id, () => formik.setSubmitting(false));
                formik.setSubmitting(false);
                thenResponse(res);
                setId(res.data.online_webinar_id);
                props.history.push(`/online-webinar/${res.data.online_webinar_id}`);
            })
            .catch(err => {
                formik.setSubmitting(false);
                catchResponse(err)
            });
    }

    const updateOnlineWebinar = (id, data) => {
        axios.put(`/admin/online-webinars/${id}`, data, {headers: {'Authorization': `Bearer ${token}`}})
            .then(res => {
                updateImgFiles(img, id, () => formik.setSubmitting(false));
                formik.setSubmitting(false);
                thenResponse(res);
            })
            .catch(err => {
                formik.setSubmitting(false);
                catchResponse(err)
            });
    }

    const validationSchema = Yup.object({
        title: Yup.string()
            .required('Tytuł jest wymagany'),
        url: Yup.string()
            .required('Url jest wymagany'),
        author: Yup.string()
            .required('Author jest wymagany'),
        start_date: Yup.date()
            .required('Data jest wymagana'),
    })

    const formik = useFormik({
        initialValues: {
            title: '',
            img: '',
            url: '',
            start_date: Date.now(),
            author: '',
            description: ''
        },
        onSubmit: (values, actions) => {
            const data = {
                online_webinar: {
                    title: values.title,
                    img_url: values.img,
                    url: values.url,
                    start_date: values.start_date,
                    author: values.author,
                    description: values.description,
                    lang_id: langId
                },
                user_id: userId,
                ip_address: ipAddress
            }
            if (id) updateOnlineWebinar(id, data);
            else addOnlineWebinar(data);
        },
        validationSchema
    });

    const updateImgFiles = (img_urls, onlineWebinarId, callback) => {
        if (img_urls === null) {
            const formDataPreview = new FormData();
            formDataPreview.append('img_urls[]', null);
            formDataPreview.append('user_id', userId);
            formDataPreview.append('ip_address', ipAddress);

            axios.post(`/admin/online-webinars/img-urls/${onlineWebinarId}`, formDataPreview, {headers: {'Authorization': `Bearer ${token}`}})
                .catch(err => {
                    callback();
                    catchResponse(err)
                });

        } else if (img_urls.length > 0) {
            const formDataPreview = new FormData();
            for (let i = 0; i < img_urls.length; i++) {
                formDataPreview.append('img_urls[]', img_urls[i]);
            }
            formDataPreview.append('user_id', userId);
            formDataPreview.append('ip_address', ipAddress);
            axios.post(`/admin/online-webinars/img-urls/${onlineWebinarId}`, formDataPreview, {headers: {'Authorization': `Bearer ${token}`}})
                .catch(err => {
                    callback();
                    catchResponse(err)
                });
        }
    };

    return (
        <>{loading ? <Spinner/> :
            <div className={classes.OnlineWebinar}>
                {authRedirect(token)}
                <ToastContainer/>
                <Paper className={classes.Paper}>
                    <Button
                        className={classes.BackButton}
                        variant="contained"
                        onClick={() => props.history.push('/online-webinars')}>
                        <ArrowBack/>
                    </Button>
                    {(id && online_webinar) ? <h1>Webinarium Online: {online_webinar.title}</h1> : <h1>Dodaj Webinarium online</h1>}
                    <form className={classes.OnlineWebinarForm} onSubmit={formik.handleSubmit}>
                        <FormControl className={classes.FormControl}>
                            <p className={classes.Label}>Nazwa</p>
                            <Input
                                id="title"
                                error={formik.errors.title ? true : false}
                                className={classes.Input}
                                {...formik.getFieldProps('title')}
                            />
                            <p className={classes.InputFeedback}>{formik.errors.title}</p>
                        </FormControl>

                        <FormControl className={classes.FormControl}>
                            <div className={classes.DropzoneContainer}>
                                <p className={classes.Label}>Zjęcie</p>
                                <div className={classes.Dropzone}>
                                    <Button className={classes.DropzoneDelete} onClick={(e) => {
                                        e.preventDefault();
                                        dropzone.current.setInitialFiles();
                                        const onlineWebinarData = online_webinar;
                                        onlineWebinarData.img_url = [];
                                        setOnlineWebinar(onlineWebinarData);
                                        setImg(null)
                                        formik.setFieldValue('img_url', null);
                                    }}>
                                        <Delete/>
                                    </Button>
                                    <Dropzone
                                        files={
                                            online_webinar &&
                                            typeof online_webinar.img_url !== 'undefined'
                                                ? [online_webinar.img_url]
                                                : []}
                                        disabled={false}
                                        ref={dropzone}
                                        onDrop={files => {
                                            formik.setFieldValue('img', files);
                                            setImg(files)
                                        }}/>
                                </div>
                                <p className={classes.InputFeedback}>{formik.errors.img}</p>
                            </div>
                        </FormControl>
                        <FormControl className={classes.FormControl}>
                            <p className={classes.Label}>Data Rozpoczęcia:</p>
                            <DatePicker
                                selected={new Date(formik.values.start_date)}
                                onChange={date => formik.setFieldValue('start_date', date)}
                                dateFormat="yyyy-MM-dd HH:mm"
                                showTimeSelect
                                showTimeInput
                                timeInputLabel="Godzina:"
                            />
                            <p className={classes.InputFeedback}>{formik.errors.start_date}</p>
                        </FormControl>
                        <FormControl className={classes.FormControl}>
                            <p className={classes.Label}>Link</p>
                            <Input
                                id="url"
                                error={!!formik.errors.title}
                                className={classes.Input}
                                {...formik.getFieldProps('url')}
                            />
                            <p className={classes.InputFeedback}>{formik.errors.url}</p>
                        </FormControl>
                        <FormControl className={classes.FormControl}>
                            <p className={classes.Label}>Autor</p>
                            <Input
                                id="author"
                                error={!!formik.errors.author}
                                className={classes.Input}
                                {...formik.getFieldProps('author')}
                            />
                            <p className={classes.InputFeedback}>{formik.errors.author}</p>
                        </FormControl>
                        <FormControl className={classes.FormControl}>
                            <p className={classes.Label}>Opis</p>
                            <CKEditor
                                id="description"
                                editor={ ClassicEditor }
                                data={`${formik.values.description}`}
                                config={{
                                    toolbar: ['heading', '|', 'bold', 'italic', 'blockQuote', 'link', 'numberedList', 'bulletedList', 'imageUpload', 'insertTable',
                                        'tableColumn', 'tableRow', 'mergeTableCells', '|', 'undo', 'redo']
                                }}
                                onChange={ ( e, editor ) => {
                                    const data = editor.getData();
                                    formik.setFieldValue('description', data)
                                } }
                            />
                            <p className={classes.InputFeedback}>{formik.errors.description}</p>
                        </FormControl>
                        <Button
                            onClick={formik.handleSubmit}
                            variant="contained"
                            style={{backgroundColor: '#006400', color: "#fff"}}
                        >
                            {(id && online_webinar) ? 'Zapisz zmiany' : 'Dodaj Webinarium'}
                        </Button>
                    </form>
                </Paper>
            </div>
        }
        </>
    )
}

export default OnlineWebinar;