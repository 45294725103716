import React, {useEffect, useRef, useState} from 'react';
import {useFormik} from "formik";
import * as Yup from 'yup';
import classes from "./ThematicGroup.module.scss";
import axios from "../../../axios-sw";
import {authRedirect, catchResponse, thenResponse} from "../../../shared/utility";
import Spinner from "../../../components/UI/Spinner/Spinner";
import {toast, ToastContainer} from "react-toastify";
import {useSelector} from "react-redux";
import {Button, Paper} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import Checkbox from "@material-ui/core/Checkbox";
import Input from "@material-ui/core/Input";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {ArrowBack, Delete} from "@material-ui/icons";
import Dropzone from "../../../components/System/OrderDropzone/OrderDropzone";
import {Picky} from "react-picky";


const ThematicGroup = props => {

    const [id, setId] = useState(props.match.params.id);
    const [loading, setLoading] = useState(true);
    const [thematicGroup, setThematicGroup] = useState(null);
    const [img, setImg] = useState([]);
    const [generalCategories, setGeneralCategories] = useState([]);
    const [generalTags, setGeneralTags]= useState([]);

    const token = useSelector(({auth}) => auth.token);
    const userId = useSelector(({auth}) => auth.user_id);
    const ipAddress = useSelector(({auth}) => auth.ip_address);
    const mainLangId = useSelector(({auth}) => auth.main_lang_id);
    const dropzone = useRef();

    useEffect(() => {
        if(mainLangId) indexGeneralCategories();
        indexGeneralTags();
        if (id) indexThematicGroup(id);
        else setLoading(false);
    }, [id, mainLangId])

    const indexThematicGroup = id => {
        axios.get(`admin/thematic-groups/${id}.json`, {headers: {'Authorization': `Bearer ${token}`}})
            .then(async res => {
                await setThematicGroup(res.data);
                await formik.setValues({
                    name: res.data.name,
                    img: res.data.img_url,
                    short_description: res.data.short_description,
                    adult_only: res.data.adult_only,
                    enable_fight_stats: res.data.enable_fight_stats,
                    required_confirmation_province: res.data.required_confirmation_province,
                    general_category_id: res.data.general_category_id,
                    voivodeship: res.data.voivodeship,
                    general_tags: res.data.general_tags,
                    confirm_location_title: res.data.confirm_location_title,
                    confirm_location_description: res.data.confirm_location_description,
                    position: res.data.position
                })

                setLoading(false);
            })
            .catch(err => {
                catchResponse(err);
            })
    }

    const indexGeneralCategories = () => {
        axios.get('/general-categories.json', {headers: {'Authorization': `Bearer ${token}`}})
            .then(res => {
                const result = res.data.map(function (obj) {
                    return {value: obj.id, label: obj.name}
                });
                setGeneralCategories( result);
            })
            .catch(err => {
                catchResponse(err)
            })
    };

    const indexGeneralTags = () => {
        axios.get(`admin/general-tags/${mainLangId}.json`, {headers: {'Authorization': `Bearer ${token}`}})
            .then(res => {
                setGeneralTagValue(res.data);
                //setGeneralTags(res.data);
            })
            .catch(err => {
                catchResponse(err)
            })
    };

    const addThematicGroup = data => {
        axios.post('admin/thematic-groups', data, {headers: {'Authorization': `Bearer ${token}`}})
            .then(async res => {
                await updateImgFiles(img, res.data.thematic_group_id, () => formik.setSubmitting(false));
                formik.setSubmitting(false);
                thenResponse(res);
                setId(res.data.thematic_group_id);
                props.history.push(`/thematic-group/${res.data.thematic_group_id}`);
            })
            .catch(err => {
                formik.setSubmitting(false);
                catchResponse(err)
            });
    }

    const updateThematicGroup = (id, data) => {
        axios.put(`admin/thematic-groups/${id}`, data, {headers: {'Authorization': `Bearer ${token}`}})
            .then(res => {
                updateImgFiles(img, id, () => formik.setSubmitting(false));
                indexThematicGroup(id);
                setGeneralTagValue(generalTags)
                formik.setSubmitting(false);
                thenResponse(res);
            })
            .catch(err => {
                formik.setSubmitting(false);
                catchResponse(err)
            });
    }

    const validationSchema = Yup.object({
        name: Yup.string()
            .required('Nazwa jest wymagana'),
        short_description: Yup.string()
            .required('Treść jest wymagana!')
            .test('test', 'Treść jest wymagana!', value => value === undefined || value.trim().length > 0 ),
        general_category_id: Yup.number()
            .required('Kategoria jest wymagana!'),
        general_tags: Yup.array()
            .required('Wymagany min. 1 tag')
    })

    const formik = useFormik({
        initialValues: {
            name: '',
            img: '',
            short_description: '',
            adult_only: false,
            enable_fight_stats: false,
            general_category_id: '',
            required_confirmation_province: false,
            voivodeship: '',
            general_tags: [],
            confirm_location_description: '',
            confirm_location_title: '',
            position: 0
        },
        onSubmit: (values, actions) => {
            let data = {};
            if(id) {
                data = {
                    thematic_group: {
                        name: values.name,
                        img_url: values.img,
                        short_description: values.short_description,
                        adult_only: values.adult_only,
                        enable_fight_stats: values.enable_fight_stats,
                        required_confirmation_province: values.required_confirmation_province,
                        general_category_id: values.general_category_id,
                        voivodeship: values.voivodeship,
                        confirm_location_title: values.confirm_location_title,
                        confirm_location_description: values.confirm_location_description,
                        position: values.position
                    },
                    general_tags: values.general_tags.filter(item => item.newValue),
                    user_id: userId,
                    ip_address: ipAddress
                }
            } else {
                data = {
                    thematic_group: {
                        name: values.name,
                        img_url: values.img,
                        short_description: values.short_description,
                        adult_only: values.adult_only,
                        enable_fight_stats: values.enable_fight_stats,
                        required_confirmation_province: values.required_confirmation_province,
                        voivodeship: values.voivodeship,
                        lang_id: mainLangId,
                        general_category_id: values.general_category_id,
                        description: '',
                        thematic_group_type: '',
                        group_stats_groups_count: 0,
                        group_stats_members_count: 0,
                        tag_type: 0,
                        confirm_location_title: values.confirm_location_title,
                        confirm_location_description: values.confirm_location_description,
                        position: values.position
                    },
                    general_tags: values.general_tags,
                    user_id: userId,
                    ip_address: ipAddress
                }
            }
            if(id) updateThematicGroup(id, data);
            else addThematicGroup(data);
        },
        validationSchema
    });

    const updateImgFiles = (img_urls, thematicGroupId, callback) => {
        if (img_urls === null) {
            const formDataPreview = new FormData();
            formDataPreview.append('img_urls[]', null);
            formDataPreview.append('user_id', userId);
            formDataPreview.append('ip_address', ipAddress);

            axios.post(`/admin/thematic-groups/img-urls/${thematicGroupId}`, formDataPreview, {headers: {'Authorization': `Bearer ${token}`}})
                .catch(err => {
                    callback();
                    catchResponse(err)
                });

        } else if (img_urls.length > 0) {
            const formDataPreview = new FormData();
            for (let i = 0; i < img_urls.length; i++) {
                formDataPreview.append('img_urls[]', img_urls[i]);
            }
            formDataPreview.append('user_id', userId);
            formDataPreview.append('ip_address', ipAddress);
            axios.post(`/admin/thematic-groups/img-urls/${thematicGroupId}`, formDataPreview, {headers: {'Authorization': `Bearer ${token}`}})
                .catch(err => {
                    callback();
                    catchResponse(err)
                });
        }
    };

    const setGeneralTagValue = (data) => {
        let filter = data;
        let currentTags = formik.values.general_tags.map(item => item.value)
        if(id) {
            filter = data.filter((item) => !currentTags.includes(item.value))
            setGeneralTags(filter)
        } else {
            setGeneralTags(filter);
        }
    }

    return (
        <>{loading ? <Spinner/> :
            <div className={classes.ThematicGroup}>
                {authRedirect(token)}
                <ToastContainer/>
                <Paper className={classes.Paper}>
                    <Button
                        className={classes.BackButton}
                        variant="contained"
                        onClick={() => props.history.push('/thematic-app')}>
                        <ArrowBack />
                    </Button>
                    {(id && thematicGroup) ? <h1>Artykuł: {thematicGroup.name}</h1> : <h1>Dodaj Grupę Tematyczną</h1>}
                    <form className={classes.ThematicGroupForm} onSubmit={formik.handleSubmit}>
                        <FormControl className={classes.FormControl}>
                            <p className={classes.Label}>Nazwa Grupy</p>
                            <Input
                                id="name"
                                error={formik.errors.name ? true : false}
                                className={classes.Input}
                                {...formik.getFieldProps('name')}
                            />
                            <p className={classes.InputFeedback}>{formik.errors.name}</p>
                        </FormControl>

                        <FormControl className={classes.FormControl}>
                            <div className={classes.DropzoneContainer}>
                                <p className={classes.Label}>Zdjęcie Grupy Tematycznej</p>
                                <div className={classes.Dropzone}>
                                    <Button className={classes.DropzoneDelete} onClick={(e) => {
                                        e.preventDefault();
                                        dropzone.current.setInitialFiles();
                                        const articleData = thematicGroup;
                                        articleData.img_url = [];
                                        setThematicGroup(articleData);
                                        setImg(null)
                                        formik.setFieldValue('img_url', null);
                                    }}>
                                        <Delete />
                                    </Button>
                                    <Dropzone files={thematicGroup && typeof thematicGroup.img_url !== 'undefined' ? thematicGroup.img_url : []}
                                              disabled={false}
                                              ref={dropzone}
                                              onDrop={files => {
                                                  formik.setFieldValue('img', files);
                                                  setImg(files)
                                              }}/>
                                </div>
                                <p className={classes.InputFeedback}>{formik.errors.img}</p>
                            </div>
                        </FormControl>

                        <FormControl className={classes.FormControl}>
                            <p className={classes.Label}>Opis</p>
                            <CKEditor
                                id="short_description"
                                editor={ ClassicEditor }
                                data={`${formik.values.short_description}`}
                                config={{
                                    toolbar: ['heading', '|', 'bold', 'italic', 'blockQuote', 'link', 'numberedList', 'bulletedList', 'imageUpload', 'insertTable',
                                        'tableColumn', 'tableRow', 'mergeTableCells', '|', 'undo', 'redo']
                                }}
                                onChange={ ( e, editor ) => {
                                    const data = editor.getData();
                                    formik.setFieldValue('short_description', data)
                                } }
                            />
                            <p className={classes.InputFeedback}>{formik.errors.short_description}</p>
                        </FormControl>
                        <FormControl className={classes.FormControl}>
                            <p className={classes.Label}>Wybierz kategorię</p>
                            <Picky
                                id="general_category_id"
                                error={formik.errors.general_category_id ? true : false}
                                className={classes.Input}
                                {...formik.getFieldProps('general_category_id')}
                                options ={generalCategories}
                                labelKey="label"
                                valueKey="value"
                                multiple={false}
                                includeFilter
                                includeSelectAll
                                value={formik.values.general_category_id ? generalCategories.find(item => item.value === formik.values.general_category_id) : null}
                                onChange={(e) => {
                                    formik.setFieldValue('general_category_id', e.value)
                                }}
                            />
                            <p className={classes.InputFeedback}>{formik.errors.general_category_id}</p>
                        </FormControl>
                        <FormControl className={classes.FormControl}>
                            <p className={classes.Label}>Grupa Tylko dla dorosłych ?</p>
                            <Checkbox
                                className={classes.Checkbox}
                                id="adult_only"
                                checked={formik.values.adult_only}
                                {...formik.getFieldProps('adult_only')}
                            />
                        </FormControl>
                        <FormControl className={classes.FormControl}>
                            <p className={classes.Label}>Posiada Statystyki ?</p>
                            <Checkbox
                                className={classes.Checkbox}
                                id="enable_fight_stats"
                                checked={formik.values.enable_fight_stats}
                                {...formik.getFieldProps('enable_fight_stats')}
                            />
                        </FormControl>
                        <FormControl className={classes.FormControl}>
                            <p className={classes.Label}>Pozycja</p>
                            <Input
                                id="position"
                                className={classes.Input}
                                {...formik.getFieldProps('position')}
                            />
                            <p className={classes.InputFeedback}>{formik.errors.visits_counter}</p>
                        </FormControl>
                        <FormControl className={classes.FormControl}>
                            <p className={classes.Label}>Potwierdzenie Lokalizacji ?</p>
                            <Checkbox
                                className={classes.Checkbox}
                                id="required_confirmation_province"
                                checked={formik.values.required_confirmation_province}
                                {...formik.getFieldProps('required_confirmation_province')}
                            />
                        </FormControl>
                        <FormControl className={classes.FormControl}>
                            <p className={classes.Label}>Województwo</p>
                            <Input
                                id="voivodeship"
                                className={classes.Input}
                                {...formik.getFieldProps('voivodeship')}
                            />
                        </FormControl>
                        <FormControl className={classes.FormControl}>
                            <p className={classes.Label}>Potwierdź lokalizacje - Tytuł</p>
                            <Input
                                id="confirm_location_title"
                                className={classes.Input}
                                {...formik.getFieldProps('confirm_location_title')}
                            />
                        </FormControl>
                        <FormControl className={classes.FormControl}>
                            <p className={classes.Label}>Potwierdź lokalizacje - Opis</p>
                            <Input
                                id="confirm_location_description"
                                error={formik.errors.voivodeship ? true : false}
                                className={classes.Input}
                                {...formik.getFieldProps('confirm_location_description')}
                            />
                            <p className={classes.InputFeedback}>{formik.errors.voivodeship}</p>
                        </FormControl>
                        <FormControl className={classes.FormControl}>
                            <p className={classes.Label}>Wybierz Tagi (Nie można usunąć zapisanego tagu)</p>
                            <Picky
                                id="general_tags"
                                error={formik.errors.general_tags ? true : false}
                                className={classes.Input}
                                {...formik.getFieldProps('general_tags')}
                                options ={generalTags}
                                labelKey="label"
                                valueKey="value"
                                multiple={true}
                                includeFilter
                                includeSelectAll
                                numberDisplayed={9999}
                                value={formik.values.general_tags}
                                onChange={(e) => {
                                    let generalTagsId = generalTags.map(item => item.value)
                                    const newValue = e.map(item => {
                                        if (generalTagsId.includes(item.value)) return {...item, newValue: true}
                                        else return item;
                                    })
                                    formik.setFieldValue('general_tags', newValue)
                                }}
                            />
                            <p className={classes.InputFeedback}>{formik.errors.general_tags}</p>
                        </FormControl>
                        <Button
                            onClick={formik.handleSubmit}
                            variant="contained"
                            style={{backgroundColor: '#006400', color:"#fff"}}
                        >
                            {(id && thematicGroup) ? 'Zapisz zmiany' : 'Dodaj Grupę'}
                        </Button>
                    </form>
                </Paper>
            </div>
        }
        </>
    )
}

export default ThematicGroup;