import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import {useFormik} from "formik";
import * as Yup from 'yup';
import TextField from '@material-ui/core/TextField';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Button from '@material-ui/core/Button';
import SendIcon from '@material-ui/icons/Send';

const useStyles = makeStyles(() =>
    createStyles({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        paper: {
            padding: '25px',
            minHeight: '510px',
            width: '80%',
            maxWidth: '1200px',
            height: '70%',
            backgroundColor: '#fff',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
        },
        title: {
            textAlign: 'center'
        },
        emailForm: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
        },
        emailTopic: {
            width: '80%',
            maxWidth: '750px',
            marginBottom: 0
        },
        emailContent: {
            marginTop: '15px',
            fontSize: '17px',
            padding: '17px',
            border: '1px solid rgba(0, 0, 0, 0.23)',
            width: '80%',
            maxWidth: '750px'
        },
        emailErrorText: {
            color: '#d50f25',
            margin: 0,
            marginTop: '5px',
            marginBottom: '5px',
            fontSize: '12px',
            width: '80%',
            maxWidth: '750px'
        },

        button: {
            margin: '10px 0'
        }
    }),
);

export default function TransitionsModal({closeModal, currentUser, sendEmail}) {
    const classes = useStyles();

    const handleClose = () => {
        closeModal()
    };

    const validationSchema = Yup.object({
        topic: Yup.string()
            .required('Temat jest wymagany'),
        content: Yup.string()
            .required('Treść jest wymagana!')
            .test('test', 'Treść jest wymagana!', value => value === undefined || value.trim().length > 0 )
    })

    const formik = useFormik({
        initialValues: {
            topic: '',
            content: ''
        },
        onSubmit: async (values, actions) => {
            sendEmail(values, currentUser)
        },
        validationSchema
    });

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={true}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={true}>
                    <div className={classes.paper}>
                        <p id="transition-modal-title" className={classes.title}>Wyślij wiadomość do użytkownika <b>{currentUser.nickname} ({currentUser.email})</b></p>
                        <form className={classes.emailForm} onSubmit={formik.handleSubmit}>
                            <TextField
                                id="topic"
                                name="topic"
                                label="Temat"
                                margin="normal"
                                variant="outlined"
                                className={classes.emailTopic}
                                {...formik.getFieldProps('topic')}
                            />
                            { formik.errors.topic && formik.touched.topic && <p className={classes.emailErrorText}> {formik.errors.topic} </p> }
                            <TextareaAutosize
                                id="content"
                                name="content"
                                rows={10}
                                rowsMax={10}
                                className={classes.emailContent}
                                aria-label="maximum height"
                                placeholder="Wiadomość..."
                                {...formik.getFieldProps('content')}
                            />
                            { formik.errors.content && formik.touched.content && <p className={classes.emailErrorText}> {formik.errors.content} </p> }
                            <Button
                                variant="outlined"
                                color="primary"
                                type="submit"
                                className={classes.button}
                            >
                                Wyślij wiadomość
                            </Button>
                        </form>
                        <Button
                            variant="outlined"
                            color="secondary"
                            type="submit"
                            className={classes.button}
                            onClick={handleClose}
                        >
                            Powrót
                        </Button>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
}