import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import MaterialTable, { MTableBodyRow, MTableEditRow, MTableToolbar } from "material-table";

// Shared
import { catchResponse, thenResponse } from "../../../shared/utility";
import { tableIcons, titleTemplate } from "../../../shared/tableVariables";
import Columns from "../../../shared/tables";
import axios from "../../../axios-sw";

// Components
import { HotKeysSubtable } from "../../../components/System/HotKeys/HotKeys";

class StoriesComments extends Component {
    state = {
        columns: Columns.ukraineStoriesCommentsColumns,
        data: [],
    };

    componentDidMount() {
        this.indexStoryComments();
    }

    indexStoryComments = () => {
        const { token, storyId } = this.props;
        axios
            .get(`/admin/show-story-comments/${storyId}.json`, { headers: { Authorization: `Bearer ${token}` } })
            .then((res) => {
                this.setState({ data: res.data });
            })
            .catch((err) => {
                catchResponse(err);
            });
    };

    destroyStoryComment = (id) => {
        const data = {
            user_id: this.props.user_id,
            ip_address: this.props.ip_address,
        };
        axios
            .delete("/admin/story-comments/" + id, {
                data: data,
                headers: { Authorization: `Bearer ${this.props.token}` },
            })
            .then((res) => {
                this.indexStoryComments();
                thenResponse(res);
            })
            .catch((err) => {
                catchResponse(err);
            });
    };

    updateStoryComment = (id, newData) => {
        const data = {
            story_comment: {
                nick: newData.nick,
                content_pl: newData.content_pl,
                content_ua: newData.content_ua,
                content_en: newData.content_en,
            },
            source_lang: newData.source_lang,
            user_id: this.props.user_id,
            ip_address: this.props.ip_address,
        };
        const url = "admin/story-comments/" + id + ".json";
        axios
            .put(url, data, { headers: { Authorization: `Bearer ${this.props.token}` } })
            .then((res) => {
                axios
                    .get(url, { headers: { Authorization: `Bearer ${this.props.token}` } })
                    .then((res) => {
                        const fetchedStoryComment = res.data;
                        const array = [...this.state.data];
                        const index = array.findIndex((x) => x.id === fetchedStoryComment.id);
                        if (index !== -1) {
                            array[index].nick = fetchedStoryComment.nick;
                            array[index].content_pl = fetchedStoryComment.content_pl;
                            array[index].content_ua = fetchedStoryComment.content_ua;
                            array[index].content_en = fetchedStoryComment.content_en;
                            array[index].created_at = fetchedStoryComment.created_at;
                            array[index].source_lang = fetchedStoryComment.source_lang;
                            this.setState({ data: array });
                        }
                    })
                    .catch((err) => {
                        catchResponse(err);
                    });
                thenResponse(res);
            })
            .catch((err) => {
                catchResponse(err);
            });
    };

    render() {
        return (
            <Paper>
                <HotKeysSubtable />
                <MaterialTable
                    title={titleTemplate("Komentarze")}
                    icons={tableIcons}
                    style={{ width: "100%" }}
                    columns={this.state.columns}
                    data={this.state.data}
                    localization={{
                        editRow: {
                            cancelTooltip: "Back",
                        },
                    }}
                    options={{
                        pageSize: 5,
                        exportButton: false,
                        filtering: false,
                        search: false,
                        paging: false,
                    }}
                    components={{
                        EditRow: (props) => <MTableEditRow autoFocus className="subtableEditingRow " {...props} />,
                        Row: (props) => <MTableBodyRow className={props.data.styles} {...props} />,
                        Toolbar: (props) => {
                            return (
                                <div className="subtableToolbar">
                                    <MTableToolbar {...props} />
                                </div>
                            );
                        },
                    }}
                    editable={{
                        onRowUpdate: (newData, oldData) =>
                            new Promise((resolve) => {
                                resolve();
                                this.updateStoryComment(oldData.id, newData);
                            }),
                        onRowDelete: (oldData) =>
                            new Promise((resolve) => {
                                resolve();
                                this.destroyStoryComment(oldData.id);
                            }),
                    }}
                />
            </Paper>
        );
    }
}

export default StoriesComments;
