import React, {useEffect, useRef, useState} from 'react';
import {useFormik} from "formik";
import * as Yup from 'yup';
import classes from "./MediaForm.module.scss";
import axios from "../../../axios-sw";
import {authRedirect, catchResponse, thenResponse} from "../../../shared/utility";
import Spinner from "../../../components/UI/Spinner/Spinner";
import {ToastContainer} from "react-toastify";
import {useSelector} from "react-redux";
import {Button, Paper} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import Checkbox from "@material-ui/core/Checkbox";
import Input from "@material-ui/core/Input";
import {ArrowBack, Delete} from "@material-ui/icons";
import Dropzone from "../../../components/System/OrderDropzone/OrderDropzone";
import DatePicker from "react-datepicker";


const MediaForm = props => {

    const [id, setId] = useState(props.match.params.id);
    const [loading, setLoading] = useState(true);
    const [mediaForm, setMediaForm] = useState(null);
    const [img, setImg] = useState([]);

    const token = useSelector(({auth}) => auth.token);
    const userId = useSelector(({auth}) => auth.user_id);
    const ipAddress = useSelector(({auth}) => auth.ip_address);
    const mainLangId = useSelector(({auth}) => auth.main_lang_id);
    const dropzone = useRef();

    useEffect(() => {
        if (id) indexMediaForm(id);
        else setLoading(false);
    }, [id])

    const indexMediaForm = id => {
        axios.get(`/admin/media/${id}.json`, {headers: {'Authorization': `Bearer ${token}`}})
            .then(async res => {
                await setMediaForm(res.data);
                await formik.setValues({
                    title: res.data.title,
                    img: res.data.img_url,
                    url: res.data.url,
                    created_at: res.data.created_at,
                    published: res.data.published
                })
                setLoading(false);
            })
            .catch(err => {
                catchResponse(err);
            })
    }

    const addMediaForm = data => {
        axios.post('/admin/media', data, {headers: {'Authorization': `Bearer ${token}`}})
            .then(async res => {
                await updateImgFiles(img, res.data.media_id, () => formik.setSubmitting(false));
                formik.setSubmitting(false);
                thenResponse(res);
                setId(res.data.media_id);
                props.history.push(`/media-form/${res.data.media_id}`);
            })
            .catch(err => {
                formik.setSubmitting(false);
                catchResponse(err)
            });
    }

    const updateMediaForm = (id, data) => {
        axios.put(`/admin/media/${id}`, data, {headers: {'Authorization': `Bearer ${token}`}})
            .then(res => {
                updateImgFiles(img, id, () => formik.setSubmitting(false));
                formik.setSubmitting(false);
                thenResponse(res);
            })
            .catch(err => {
                formik.setSubmitting(false);
                catchResponse(err)
            });
    }

    const validationSchema = Yup.object({
        title: Yup.string()
            .required('Tytuł jest wymagany'),
        url: Yup.string()
            .required('Url jest wymagany'),
        created_at: Yup.date()
            .required('Data jest wymagana')
    })

    const formik = useFormik({
        initialValues: {
            title: '',
            img: '',
            url: '',
            created_at: Date.now(),
            published: false
        },
        onSubmit: (values, actions) => {
            const data = {
                media: {
                    title: values.title,
                    img_url: values.img,
                    url: values.url,
                    created_at: values.created_at,
                    published: values.published,
                    lang_id: mainLangId
                },
                user_id: userId,
                ip_address: ipAddress
            }
            if (id) updateMediaForm(id, data);
            else addMediaForm(data);
        },
        validationSchema
    });

    const updateImgFiles = (img_urls, mediaFormId, callback) => {
        if (img_urls === null) {
            const formDataPreview = new FormData();
            formDataPreview.append('img_urls[]', null);
            formDataPreview.append('user_id', userId);
            formDataPreview.append('ip_address', ipAddress);

            axios.post(`/admin/media/img-urls/${mediaFormId}`, formDataPreview, {headers: {'Authorization': `Bearer ${token}`}})
                .catch(err => {
                    callback();
                    catchResponse(err)
                });

        } else if (img_urls.length > 0) {
            const formDataPreview = new FormData();
            for (let i = 0; i < img_urls.length; i++) {
                formDataPreview.append('img_urls[]', img_urls[i]);
            }
            formDataPreview.append('user_id', userId);
            formDataPreview.append('ip_address', ipAddress);
            axios.post(`/admin/media/img-urls/${mediaFormId}`, formDataPreview, {headers: {'Authorization': `Bearer ${token}`}})
                .catch(err => {
                    callback();
                    catchResponse(err)
                });
        }
    };

    return (
        <>{loading ? <Spinner/> :
            <div className={classes.Media}>
                {authRedirect(token)}
                <ToastContainer/>
                <Paper className={classes.Paper}>
                    <Button
                        className={classes.BackButton}
                        variant="contained"
                        onClick={() => props.history.push('/media')}>
                        <ArrowBack/>
                    </Button>
                    {(id && mediaForm) ? <h1>Media: {mediaForm.title}</h1> : <h1>Dodaj Media</h1>}
                    <form className={classes.MediaForm} onSubmit={formik.handleSubmit}>
                        <FormControl className={classes.FormControl}>
                            <p className={classes.Label}>Nazwa</p>
                            <Input
                                id="title"
                                error={formik.errors.title ? true : false}
                                className={classes.Input}
                                {...formik.getFieldProps('title')}
                            />
                            <p className={classes.InputFeedback}>{formik.errors.title}</p>
                        </FormControl>

                        <FormControl className={classes.FormControl}>
                            <div className={classes.DropzoneContainer}>
                                <p className={classes.Label}>Zjęcie</p>
                                <div className={classes.Dropzone}>
                                    <Button className={classes.DropzoneDelete} onClick={(e) => {
                                        e.preventDefault();
                                        dropzone.current.setInitialFiles();
                                        const mediaFormData = mediaForm;
                                        mediaFormData.img_url = [];
                                        setMediaForm(mediaFormData);
                                        setImg(null)
                                        formik.setFieldValue('img_url', null);
                                    }}>
                                        <Delete/>
                                    </Button>
                                    <Dropzone
                                        files={mediaForm && typeof mediaForm.img_url !== 'undefined' ? mediaForm.img_url : []}
                                        disabled={false}
                                        ref={dropzone}
                                        onDrop={files => {
                                            formik.setFieldValue('img', files);
                                            setImg(files)
                                        }}/>
                                </div>
                                <p className={classes.InputFeedback}>{formik.errors.img}</p>
                            </div>
                        </FormControl>
                        <FormControl className={classes.FormControl}>
                            <p className={classes.Label}>Data Utworzenia</p>
                            <DatePicker
                                selected={new Date(formik.values.created_at)}
                                onChange={date => formik.setFieldValue('created_at', date)}
                                dateFormat="yyyy-MM-dd"
                            />
                            <p className={classes.InputFeedback}>{formik.errors.created_at}</p>
                        </FormControl>
                        <FormControl className={classes.FormControl}>
                            <p className={classes.Label}>Link</p>
                            <Input
                                id="url"
                                error={formik.errors.title ? true : false}
                                className={classes.Input}
                                {...formik.getFieldProps('url')}
                            />
                            <p className={classes.InputFeedback}>{formik.errors.url}</p>
                        </FormControl>

                        <FormControl className={classes.FormControl}>
                            <p className={classes.Label}>Czy Opublikować ?</p>
                            <Checkbox
                                className={classes.Checkbox}
                                id="published"
                                checked={formik.values.published}
                                {...formik.getFieldProps('published')}
                            />
                        </FormControl>
                        <Button
                            onClick={formik.handleSubmit}
                            variant="contained"
                            style={{backgroundColor: '#006400', color: "#fff"}}
                        >
                            {(id && mediaForm) ? 'Zapisz zmiany' : 'Dodaj Media'}
                        </Button>
                    </form>
                </Paper>
            </div>
        }
        </>
    )
}

export default MediaForm;