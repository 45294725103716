import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import axios from "../../axios-sw";
import {authRedirect} from "../../shared/utility";
import {ToastContainer} from "react-toastify";
import Spinner from "../../components/UI/Spinner/Spinner";
import {Button} from "@material-ui/core";
import classes from "./Statistics.module.scss"

const Statistics = () => {
    //STATE
    const [statistics, setStatistics] = useState(null);
    const [loading, setLoading] = useState(true);

    //SELECTORS
    const token = useSelector(({auth}) => auth.token);

    //FUNCTIONS
    useEffect(() => {
        fetchStatistics()
    }, [])

    const fetchStatistics = () => {
        setLoading(true);
        axios.get(`admin/users/index-statistics.json`, {headers: {'Authorization': `Bearer ${token}`}})
            .then(res => {
                let result = [];
                for (let property in res.data) {
                    statisticsList.forEach(statistic => {
                        if (statistic.key === property) {
                            result = [...result, {
                                key: statistic.key,
                                name: statistic.name,
                                value: res.data[property]
                            }]
                        }
                    })
                    setStatistics(result);
                }
                setLoading(false);
            })
            .catch(err => {
                console.error(err)
            })
    }

    const statisticsList = [
        {name: "Liczba Użytkowników", key: 'number_of_users'},
        {name: "Wszystkie Rejestracje", key: 'all_registrations'},
        {name: "Rejestracje w ostatnich 24 godzinach", key: 'last_day_registered'},
        {name: "Rejestracje w ostatnim tygodniu", key: 'last_week_registered'},
        {name: "Użytkownicy Online", key: 'online'},
        {name: "Liczba grup wsparcia", key: 'number_of_support_group'},
        {name: "Liczba grup wsparcia dodanych w tym tygodniu:", key: 'last_week_support_group_added'},
        {name: "Liczba grup tematycznych", key: 'number_of_thematic_group'},
        {name: "Usunięte konta w tygodniu", key: 'last_week_deleted'},
    ]
    return (
        <>
            <h1>Statystyki:</h1>
            {authRedirect(token)}
            <ToastContainer/>
            {loading ? <Spinner/> :
                <>
                    <ul className={classes.StatisticsList}>
                        {statistics.map(item => {
                            return <li className={classes.ListItem} key={item.key}><b>{item.name}: </b>{item.value} </li>
                        })}
                    </ul>
                    <Button
                        onClick={fetchStatistics}
                        variant="outlined"
                        color="primary"
                        type="submit"
                    >
                        Przeładuj statystyki
                    </Button>
                </>
            }
        </>
    )
}

export default Statistics;