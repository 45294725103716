import React from 'react';

import {Link as NavLink} from 'react-router-dom';
import Link from '@material-ui/core/Link';
import ListItemIcon from '@material-ui/core/ListItemIcon';

import AccountIcon from '@material-ui/icons/AccountCircle';

import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ExitToApp from '@material-ui/icons/ExitToApp';

const IconLinks = (props) => {

    const [anchorEl, setAnchorEl] = React.useState(null);

    function handleClick(event) {
        setAnchorEl(event.currentTarget);
        props.onClickLinkHandler();
    }

    function handleClose() {
        setAnchorEl(null);
    }

    return (
        <>
            <IconButton color='inherit' aria-controls='account-menu' aria-haspopup='true' onClick={handleClick}>
                {props.avatar !== null ?
                    <>
                        <p style={{fontSize: 12, paddingTop: 2, marginLeft: 10}}>{props.nickname}</p>
                    </>
                    : <AccountIcon/>}
            </IconButton>

            <Menu
                id='account-menu'
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                style={{top: 60}}
                PaperProps={{
                    style: {
                        width: 200,
                    },
                }}
            >
                <Link
                    style={{textDecoration: 'none', color: 'black'}}
                    component={NavLink}
                    to='/logout'>
                    <MenuItem onClick={handleClose}>
                        <ListItemIcon>
                            <ExitToApp fontSize="small"/>
                        </ListItemIcon>
                        Wyloguj się
                    </MenuItem>
                </Link>

            </Menu>
        </>
    )
};

export default IconLinks;