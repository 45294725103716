import React, { Component } from "react";
import { connect } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { Chip, TablePagination } from "@material-ui/core";
import { ArrowDownward, ArrowUpward, Check, Close, HighlightOff } from "@material-ui/icons";
import MaterialTable, { MTableBody, MTableBodyRow, MTableEditRow, MTableFilterRow, MTableToolbar } from "material-table";
import moment from "moment";

// Shared
import axios from "../../axios-sw";
import Columns from "../../shared/tables";
import { tableIcons, titleTemplate } from "../../shared/tableVariables";
import { defaultPageSize } from "../../shared/defines";
import {
    authRedirect,
    catchResponse,
    createSingleColumnFilterVal,
    createSortPath,
    getAndConvertULRParams,
    getFilterPath,
    getSorthPath,
    thenResponse,
} from "../../shared/utility";

// Components
import Spinner from "../../components/UI/Spinner/Spinner";
import { HotKeys } from "../../components/System/HotKeys/HotKeys";
import SearchInput from "../../components/UI/SearchInput/SearchInput";
import StoriesComments from "./StoriesComments/StoriesComments";

// Styles
import classes from "../../Common.module.scss";

const sortingPhraseHelper = [
    { key: "nick", path: "stories.nick" },
    { key: "title_pl", path: "stories.title_pl" },
    { key: "title_ua", path: "stories.title_ua" },
    { key: "title_en", path: "stories.title_en" },
    { key: "created_at", path: "stories.created_at" },
    { key: "source_lang", path: "stories.source_lang" },
    { key: "content_pl", path: "stories.content_pl" },
    { key: "content_ua", path: "stories.content_ua" },
    { key: "content_en", path: "stories.content_en" },
    { key: "story_category", path: "stories.story_category_id" },
    { key: "trusted", path: "stories.trusted" },
];
class UkraineStories extends Component {
    state = {
        columns: Columns.ukraineStoriesColumns,
        columnsHidden: Columns.ukraineStoriesColumns,
        currentStateColumns: null,
        data: [],
        filteredData: [],
        filterPhrase: "",
        sortBy: null,
        sortingColumnsList: [],
        singleColumnFilterList: [],
        firstLoad: true,
        tableRef: React.createRef(),
        loaded: false,
        ukraineStories: [],
    };

    componentDidMount() {
        const { page, limit, search, sortingColumnsList, singleColumnFilterList } = getAndConvertULRParams(this.props.location.search, sortingPhraseHelper);
        this.indexStories(page - 1, limit, search, sortingColumnsList, singleColumnFilterList);

        document.querySelectorAll(".MuiTableSortLabel-root").forEach((icon, index) => {
            icon.addEventListener("click", () => {
                let sortingColumnsList = this.state.sortingColumnsList;
                const table = this.state.tableRef.current;
                let indexIsExist = this.state.sortingColumnsList.findIndex((column) => column.sortBy.key === table.state.columns[index - 1].field);
                if (indexIsExist !== -1) {
                    if (sortingColumnsList[indexIsExist].order === "DESC") sortingColumnsList.splice(indexIsExist, 1);
                    else if (sortingColumnsList[indexIsExist].order === "ASC") sortingColumnsList[indexIsExist].order = "DESC";
                    else if (!sortingColumnsList[indexIsExist].order) sortingColumnsList[indexIsExist].order = "ASC";
                    this.setState({ sortingColumnsList: sortingColumnsList });
                } else {
                    const sqlColumn = sortingPhraseHelper.filter((phrase) => phrase.key === table.state.columns[index - 1].field);
                    sortingColumnsList = [...sortingColumnsList, { sortBy: sqlColumn[0], order: "ASC" }];
                    this.setState({ sortingColumnsList: sortingColumnsList });
                }

                this.goPage(0, this.state.limit, this.state.filterPhrase, sortingColumnsList, this.state.singleColumnFilterList);
            });
        });
        const table = this.state.tableRef.current;
        singleColumnFilterList.forEach((filter) => {
            const index = this.state.tableRef.current.state.columns.findIndex((column) => column.field === filter.column.key);
            if (filter.type === "boolean") {
                if (filter.value.includes("true")) this.state.tableRef.current.onFilterChange(index, "checked");
                else if (filter.value.includes("false")) this.state.tableRef.current.onFilterChange(index, undefined);
                else this.state.tableRef.current.onFilterChange(index, null);
            } else if (filter.type === "text") {
                const value = filter.value
                    .replace("LIKE", "")
                    .replace("LOWER", "")
                    .replace("('%", "")
                    .replace("%')", "")
                    .trim();
                this.state.tableRef.current.state.columns[index].tableData.initialValue = value;
            } else if (filter.type === "number") {
                const value = filter.value.replace("=", "").trim();
                this.state.tableRef.current.state.columns[index].tableData.initialValue = value;
            } else if (filter.type === "select") {
                /*WYJĄTKI*/
                if (filter.column.key === "post_type" && filter.value === `<>0`) table.onFilterChange(index, "1");
                if (filter.column.key === "post_type" && filter.value === `=1`) table.onFilterChange(index, "0");
            } else if (filter.type === "multiSelect") {
                if (filter.column.key === "accepted") {
                    let value = [];
                    if (filter.column.key === "accepted" && filter.value.includes(`LIKE "%%" OR ${filter.column.path} IS NULL`)) value = [...value, "all"];
                    if (filter.column.key === "accepted" && filter.value.includes(`="" OR ${filter.column.path} IS NULL`)) value = [...value, "empty"];
                    if (filter.column.key === "accepted" && filter.value.includes(`<> '' AND ${filter.column.path} IS NOT NULL AND ${filter.column.path}`))
                        value = [...value, "accepted"];
                    if (filter.column.key === "accepted" && filter.value.includes(`LIKE "%hold%"`)) value = [...value, "hold"];
                    if (filter.column.key === "accepted" && filter.value.includes(`LIKE "%cancel%"`)) value = [...value, "cancel"];
                    this.state.tableRef.current.state.columns[index].tableData.initialValue = value;
                } else {
                    let value = filter.value
                        .split("LIKE")
                        .join("")
                        .split("'%")
                        .join("")
                        .split("%'")
                        .join("")
                        .split(`OR ${filter.column.path}`)
                        .join(",")
                        .trim();
                    let valueArray = value.split(",").map((item) => item.trim());
                    table.onFilterChange(index, valueArray);
                }
            }
        });
        if (singleColumnFilterList.filter((filter) => filter.type === "boolean").length === 0) {
            for (let i = 0; i < table.state.columns.length; i++) {
                if (table.state.columns[i].type !== "select" && table.state.columns[i].type !== "multiSelect") {
                    table.onFilterChange(i, null);
                    table.state.columns[i].tableData.filterValue = null;
                }
            }
        }
    }

    handleSerach = (val) => {
        let convertVal = val.split("'").join("");

        const table = this.state.tableRef.current;
        const singleColumnFilterList = [];
        table.state.columns.forEach((column) => {
            column.tableData.initialValue = "";
        });
        this.goPage(0, this.state.limit, convertVal, this.state.sortingColumnsList, singleColumnFilterList);
        this.setState({ filterPhrase: convertVal, singleColumnFilterList: singleColumnFilterList });
    };

    goPage = (page = 0, limit = 20, search = null, sortingColumnsList = [], singleColumnFilterList = []) => {
        this.setState({ loaded: false, filteredData: [], data: [] });
        const searchVal = search && search.trim().length > 0 ? `&search=${search}` : "";

        const sortByVal = createSortPath(sortingColumnsList);
        const singleColumnFilterVal = createSingleColumnFilterVal(singleColumnFilterList);

        this.props.history.push(`/ukraine-stories?page=${page + 1}&limit=${limit}${searchVal}${sortByVal}${singleColumnFilterVal}`);
        this.indexStories(page, limit, search, sortingColumnsList, singleColumnFilterList, this.state.currentStateColumns);
    };

    indexStories(page = 1, limit = 20, phrase = null, sortingColumnsList = [], singleColumnFilterList = []) {
        const sortPath = getSorthPath(sortingColumnsList);
        const filterPath = getFilterPath(singleColumnFilterList);
        const startTime = Date.now();

        axios
            .get(
                `admin/stories/${page}/${limit}.json`,
                {
                    params: {
                        phrase: phrase,
                        sortPath: sortPath,
                        filterPath: filterPath,
                    },
                },
                { headers: { Authorization: `Bearer ${this.props.token}` } }
            )
            .then((res) => {
                this.state.tableRef.current.state.orderBy = -1;
                this.state.tableRef.current.state.orderDirection = "";
                this.state.tableRef.current.dataManager.orderBy = -1;
                this.state.tableRef.current.dataManager.orderDirection = "";

                const responseTime = (Date.now() - startTime) / 1000;
                console.log("Response time: " + responseTime + " s");

                this.setState({
                    data: res.data.stories,
                    filteredData: res.data.stories,
                    totalRow: res.data.total_row,
                    page: parseInt(page),
                    limit: parseInt(limit),
                    filterPhrase: phrase,
                    sortingColumnsList: sortingColumnsList,
                    singleColumnFilterList: singleColumnFilterList,
                    loaded: true,
                });
            })
            .catch((err) => {
                catchResponse(err);
            });
    }

    updatePost = (id, newData) => {
        const data = {
            story: {
                nick: newData.nick,
                title_pl: newData.title_pl,
                title_ua: newData.title_ua,
                title_en: newData.title_en,
                content_pl: newData.content_pl,
                content_ua: newData.content_ua,
                content_en: newData.content_en,
            },
            source_lang: newData.source_lang,
            user_id: this.props.user_id,
            ip_address: this.props.ip_address,
        };
        axios
            .put(`admin/stories/${id}.json`, data, { headers: { Authorization: `Bearer ${this.props.token}` } })
            .then((res) => {
                axios
                    .get(`admin/stories/${id}.json`, { headers: { Authorization: `Bearer ${this.props.token}` } })
                    .then((res) => {
                        const fetchedStory = res.data;
                        const array = [...this.state.data];
                        const index = array.findIndex((x) => x.id === fetchedStory.id);
                        if (index !== -1) {
                            array[index].nick = fetchedStory.nick;
                            array[index].title_pl = fetchedStory.title_pl;
                            array[index].title_ua = fetchedStory.title_ua;
                            array[index].title_en = fetchedStory.title_en;
                            array[index].content_pl = fetchedStory.content_pl;
                            array[index].content_ua = fetchedStory.content_ua;
                            array[index].content_en = fetchedStory.content_en;
                            array[index].source_lang = fetchedStory.source_lang;
                            this.setState({ data: array, filteredData: array });
                        }
                    })
                    .catch((err) => {
                        catchResponse(err);
                    });
                thenResponse(res);
            })
            .catch((err) => {
                catchResponse(err);
            });
    };

    deletePost = (id) => {
        axios
            .delete(`/admin/stories/${id}'.json'`, {
                data: {
                    user_id: this.props.user_id,
                    ip_address: this.props.ip_address,
                },
                headers: { Authorization: `Bearer ${this.props.token}` },
            })
            .then((res) => {
                const { page, limit, search, sortingColumnsList, singleColumnFilterList } = getAndConvertULRParams(
                    this.props.location.search,
                    sortingPhraseHelper
                );
                this.indexStories(page - 1, limit, search, sortingColumnsList, singleColumnFilterList);
                thenResponse(res);
            })
            .catch((err) => {
                catchResponse(err);
            });
    };

    mapOrder = (array, order, key) => {
        array.sort((a, b) => {
            let A = a[key],
                B = b[key];
            if (order.indexOf(A) > order.indexOf(B)) {
                return 1;
            } else {
                return -1;
            }
        });
        return array;
    };

    fetchDataPostComments = async (postsData) => {
        const postsIds = postsData.map((item) => item.id);

        const fetchURL = (url, params) => axios.get(url, params);
        let postCommentsData = [];

        let promiseArray = postsData.map((item) => {
            return fetchURL(`admin/show-post-comments/${item.id}.json`);
        });

        await Promise.all(promiseArray)
            .then((data) => {
                data.map((item) => {
                    postCommentsData = [...postCommentsData, ...item.data];
                });
            })
            .catch((err) => {
                toast.error(err);
            });
        console.log(postCommentsData);
        /*FILTROWANIE DANYCH NA PODSTAWIE FILTRÓW*/
        let filteredPostCommentsData = [];
        postCommentsData.map((postComment) => {
            postsIds.map((id) => {
                if (postComment.post_id === id) {
                    filteredPostCommentsData = [...filteredPostCommentsData, postComment];
                }
            });
        });

        /*SORTOWANIE DANYCH NA PODSTAWIE KOLEJNOŚCI POSTÓW*/
        filteredPostCommentsData = this.mapOrder(filteredPostCommentsData, postsIds, "id");

        return filteredPostCommentsData;
    };

    render() {
        let emptyDataMessage;
        if (!this.state.loaded) {
            emptyDataMessage = <Spinner />;
        } else {
            emptyDataMessage = "No data to display";
        }

        return (
            <>
                {!this.state.loaded && <div className={"overlay"}></div>}
                {authRedirect(this.props.token)}
                <ToastContainer />
                <HotKeys />
                <MaterialTable
                    tableRef={this.state.tableRef}
                    title={titleTemplate("Historie na stronie helpukraine.grupawsparcia.pl", "Pozwala na administrację treści")}
                    localization={{
                        body: {
                            emptyDataSourceMessage: emptyDataMessage,
                        },
                    }}
                    icons={tableIcons}
                    columns={this.state.columnsHidden}
                    data={this.state.filteredData}
                    style={{ width: "100%" }}
                    detailPanel={(rowData) => {
                        return (
                            <div className="detailPanel">
                                <StoriesComments
                                    token={this.props.token}
                                    user_id={this.props.user_id}
                                    ip_address={this.props.ip_address}
                                    storyId={rowData.id}
                                />
                            </div>
                        );
                    }}
                    options={{
                        search: false,
                        pageSize: defaultPageSize,
                        filtering: true,
                    }}
                    components={{
                        Body: (props) => {
                            return (
                                <MTableBody
                                    {...props}
                                    onFilterChanged={(columnId, value) => {
                                        const columnName = this.state.columnsHidden[columnId].field;
                                        let singleColumnFilterList = this.state.singleColumnFilterList;
                                        let indexIsExist = this.state.singleColumnFilterList.findIndex((filter) => filter.column.key === columnName);
                                        const sqlColumn = sortingPhraseHelper.filter((phrase) => phrase.key === columnName);
                                        const table = this.state.tableRef.current;

                                        if (this.state.columnsHidden[columnId].type === "dateType") {
                                            if (indexIsExist !== -1) {
                                                if (value.isEmpty) {
                                                    singleColumnFilterList[indexIsExist].value = "IS NULL";
                                                } else if (value.isFull) {
                                                    singleColumnFilterList[indexIsExist].value = "IS NOT NULL";
                                                } else {
                                                    const start = moment(value.start).format("YYYY-MM-DD");
                                                    const end = moment(value.end).format("YYYY-MM-DD");
                                                    singleColumnFilterList[indexIsExist].value = `BETWEEN "${start}" AND "${end}"`;
                                                }
                                            } else {
                                                if (value.isEmpty) {
                                                    singleColumnFilterList = [
                                                        ...singleColumnFilterList,
                                                        {
                                                            column: sqlColumn[0],
                                                            value: "IS NULL",
                                                            type: "dateType",
                                                        },
                                                    ];
                                                } else if (value.isFull) {
                                                    singleColumnFilterList = [
                                                        ...singleColumnFilterList,
                                                        {
                                                            column: sqlColumn[0],
                                                            value: "IS NOT NULL",
                                                            type: "dateType",
                                                        },
                                                    ];
                                                } else {
                                                    const start = moment(value.start).format("YYYY-MM-DD");
                                                    const end = moment(value.end).format("YYYY-MM-DD");
                                                    singleColumnFilterList = [
                                                        ...singleColumnFilterList,
                                                        {
                                                            column: sqlColumn[0],
                                                            value: `BETWEEN "${start}" AND "${end}"`,
                                                            type: "dateType",
                                                        },
                                                    ];
                                                }
                                            }
                                        } else if (this.state.columnsHidden[columnId].type === "text") {
                                            if (indexIsExist !== -1) {
                                                singleColumnFilterList[indexIsExist].value = `LIKE LOWER('%${value}%')`;
                                                table.state.columns[columnId].tableData.initialValue = value;
                                            } else {
                                                singleColumnFilterList = [
                                                    ...singleColumnFilterList,
                                                    {
                                                        column: sqlColumn[0],
                                                        value: `LIKE LOWER('%${value}%')`,
                                                        type: "text",
                                                    },
                                                ];
                                                table.state.columns[columnId].tableData.initialValue = value;
                                            }
                                        } else if (this.state.columnsHidden[columnId].type === "boolean") {
                                            if (indexIsExist !== -1) {
                                                if (value === "checked") {
                                                    singleColumnFilterList.splice(indexIsExist, 1);
                                                    table.onFilterChange(columnId, null);
                                                } else if (value === "unchecked") {
                                                    singleColumnFilterList[indexIsExist].value = `=false OR ${sqlColumn[0].path} IS NULL`;
                                                    table.onFilterChange(columnId, undefined);
                                                } else if (!value) {
                                                    singleColumnFilterList[indexIsExist].value = "=true";
                                                    table.onFilterChange(columnId, "checked");
                                                }
                                            } else {
                                                singleColumnFilterList = [
                                                    ...singleColumnFilterList,
                                                    {
                                                        column: sqlColumn[0],
                                                        value: "=true",
                                                        type: "boolean",
                                                    },
                                                ];
                                                table.onFilterChange(columnId, "checked");
                                            }
                                        } else if (this.state.columnsHidden[columnId].type === "number") {
                                            const convertValue = value ? parseFloat(value.replace(/\s/g, "").replace(",", ".")) : 0;

                                            if (indexIsExist !== -1) {
                                                singleColumnFilterList[indexIsExist].value = `= ${convertValue}`;
                                                table.state.columns[columnId].tableData.initialValue = convertValue;
                                            } else {
                                                singleColumnFilterList = [
                                                    ...singleColumnFilterList,
                                                    {
                                                        column: sqlColumn[0],
                                                        value: `= ${convertValue}`,
                                                        type: "number",
                                                    },
                                                ];
                                                table.state.columns[columnId].tableData.initialValue = convertValue;
                                            }
                                        } else if (this.state.columnsHidden[columnId].type === "select") {
                                            if (this.state.columnsHidden[columnId].field === "post_type") {
                                                if (value === "1") value = "<>0";
                                                else value = "=0";
                                            }

                                            if (indexIsExist !== -1) {
                                                singleColumnFilterList[indexIsExist].value = value;
                                                table.state.columns[columnId].tableData.initialValue = value;
                                            } else {
                                                singleColumnFilterList = [
                                                    ...singleColumnFilterList,
                                                    {
                                                        column: sqlColumn[0],
                                                        value: value,
                                                        type: "select",
                                                    },
                                                ];
                                                table.state.columns[columnId].tableData.initialValue = value;
                                            }
                                        } else if (this.state.columnsHidden[columnId].type === "multiSelect") {
                                            if (this.state.columnsHidden[columnId].field === "accepted") {
                                                let convertValue = "";
                                                value.forEach((item, index) => {
                                                    if (item === "all")
                                                        convertValue +=
                                                            index < value.length - 1
                                                                ? `LIKE "%%" OR ${sqlColumn[0].path} IS NULL OR ${sqlColumn[0].path} `
                                                                : `LIKE "%%" OR ${sqlColumn[0].path} IS NULL`;
                                                    else if (item === "empty" || item === "unadded")
                                                        convertValue +=
                                                            index < value.length - 1
                                                                ? ` ="" OR ${sqlColumn[0].path} IS NULL OR ${sqlColumn[0].path} `
                                                                : `="" OR ${sqlColumn[0].path} IS NULL`;
                                                    else if (item === "accepted" || item === "added")
                                                        convertValue +=
                                                            index < value.length - 1
                                                                ? `OR ${sqlColumn[0].path} <> '' AND ${sqlColumn[0].path} IS NOT NULL AND ${sqlColumn[0].path} OR ${sqlColumn[0].path} `
                                                                : `<> '' AND ${sqlColumn[0].path} IS NOT NULL AND ${sqlColumn[0].path}`;
                                                    else
                                                        convertValue +=
                                                            index < value.length - 1 ? `LIKE "%${item}%" OR ${sqlColumn[0].path} ` : `LIKE "%${item}%"`;
                                                });

                                                if (value.length > 0) {
                                                    if (indexIsExist !== -1) {
                                                        singleColumnFilterList[indexIsExist].value = convertValue;
                                                        table.state.columns[columnId].tableData.initialValue = value;
                                                    } else {
                                                        singleColumnFilterList = [
                                                            ...singleColumnFilterList,
                                                            {
                                                                column: sqlColumn[0],
                                                                value: convertValue,
                                                                type: "multiSelect",
                                                            },
                                                        ];
                                                        table.state.columns[columnId].tableData.initialValue = value;
                                                    }
                                                } else {
                                                    singleColumnFilterList = singleColumnFilterList.filter((filter) => filter.column.key !== "accepted");
                                                    table.state.columns[columnId].tableData.initialValue = ["all"];
                                                }
                                            } else {
                                                if (indexIsExist !== -1) {
                                                    let multiFiltersValues = "";
                                                    value.forEach((filter, index) => {
                                                        multiFiltersValues +=
                                                            index === 0 ? `LIKE '%${value[index]}%'` : ` OR ${sqlColumn[0].path} LIKE '%${value[index]}%'`;
                                                    });
                                                    singleColumnFilterList[indexIsExist].value = multiFiltersValues;
                                                    table.onFilterChange(columnId, value);
                                                } else {
                                                    singleColumnFilterList = [
                                                        ...singleColumnFilterList,
                                                        {
                                                            column: sqlColumn[0],
                                                            value: `LIKE '%${value}%'`,
                                                            type: "multiSelect",
                                                        },
                                                    ];
                                                    table.onFilterChange(columnId, value);
                                                }
                                            }
                                        }
                                        this.setState({ singleColumnFilterList: singleColumnFilterList, filterPhrase: "" });
                                        this.goPage(0, this.state.limit, "", this.state.sortingColumnsList, singleColumnFilterList);
                                    }}
                                />
                            );
                        },
                        Pagination: (props) => {
                            return (
                                <TablePagination
                                    {...props}
                                    rowsPerPage={this.state.limit}
                                    count={this.state.totalRow}
                                    page={this.state.page}
                                    onChangePage={(e, page) => {
                                        this.state.tableRef.current.onChangePage(e, page);
                                        this.setState({ page: page });
                                        this.goPage(
                                            page,
                                            this.state.limit,
                                            this.state.filterPhrase,
                                            this.state.sortingColumnsList,
                                            this.state.singleColumnFilterList
                                        );
                                    }}
                                    onChangeRowsPerPage={(event) => {
                                        props.onChangeRowsPerPage(event);
                                        this.setState({ limit: event.target.value });
                                        this.goPage(
                                            this.state.page,
                                            event.target.value,
                                            this.state.filterPhrase,
                                            this.state.sortingColumnsList,
                                            this.state.singleColumnFilterList
                                        );
                                    }}
                                />
                            );
                        },
                        FilterRow: (props) => {
                            return <MTableFilterRow className="filterRow" {...props} />;
                        },
                        Row: (props) => <MTableBodyRow className={props.data.styles} {...props} />,
                        EditRow: (props) => {
                            return <MTableEditRow className="editingRow" {...props} />;
                        },
                        Toolbar: (props) => {
                            const table = this.state.tableRef.current;

                            return (
                                <div className="mainToolbar">
                                    <MTableToolbar {...props} />

                                    {/* Usuwanie filtra z tabeli */}
                                    <Chip
                                        variant="outlined"
                                        style={{ marginLeft: 24, marginTop: 5 }}
                                        avatar={<HighlightOff />}
                                        label="Clear filters"
                                        onClick={() => {
                                            this.goPage();
                                            this.props.history.push(`/ukraine-stories?page=${1}&limit=${20}`);

                                            table.state.columns.forEach((column, index) => {
                                                if (column.field === "accepted") {
                                                    table.state.columns[index].tableData.filterValue = "all";
                                                    column.tableData.initialValue = "all";
                                                } else {
                                                    table.onFilterChange(index, null);
                                                    table.state.columns[index].tableData.filterValue = null;
                                                    column.tableData.initialValue = "";
                                                }
                                            });
                                        }}
                                    />

                                    {/*Generowanie tagu szukania*/}
                                    {this.state.filterPhrase && this.state.filterPhrase.trim().length > 0 && (
                                        <Chip
                                            style={{ marginLeft: 24, marginTop: 10 }}
                                            onDelete={() => {
                                                this.goPage(0, this.state.limit, "", this.state.sortingColumnsList, this.state.singleColumnFilterList);
                                                this.setState({ filterPhrase: "" });
                                            }}
                                            label={`search: ${this.state.filterPhrase}`}
                                            color="primary"
                                        />
                                    )}

                                    {/*Generowanie tagów filtrów kolumn*/}
                                    {this.state.singleColumnFilterList.map((filter) => {
                                        let value;
                                        let key;
                                        let icon = "";
                                        if (filter.type === "dateType") value = filter.value.replace("BETWEEN", "").replace("AND", "-");
                                        else if (filter.type === "text")
                                            value = filter.value
                                                .replace("LIKE", "")
                                                .replace("LOWER", "")
                                                .replace("('%", "")
                                                .replace("%')", "");
                                        else if (filter.type === "select") {
                                            if (filter.column.key === "post_type") {
                                                value = filter.value === "<>0" ? "Upadek" : "Wpis";
                                            } else {
                                                value = filter.value
                                                    .replace("LIKE", "")
                                                    .replace("'%", "")
                                                    .replace("%'", "");
                                            }
                                        } else if (filter.type === "multiSelect")
                                            value = filter.value
                                                .split("LIKE")
                                                .join("")
                                                .split("'%")
                                                .join("")
                                                .split("%'")
                                                .join("")
                                                .split(`OR ${filter.column.path}`)
                                                .join(",");
                                        else if (filter.type === "number") value = filter.value.replace("=", "");
                                        else if (filter.type === "boolean") {
                                            if (filter.value.includes("true")) {
                                                value = "checked";
                                                icon = <Check />;
                                            } else {
                                                value = "unchecked";
                                                icon = <Close />;
                                            }
                                        }

                                        key = this.state.columns.filter((column) => column.field === filter.column.key)[0].title;

                                        return (
                                            <Chip
                                                style={{ marginLeft: 24, marginTop: 10 }}
                                                onDelete={() => {
                                                    const singleColumnFilterList = this.state.singleColumnFilterList.filter(
                                                        (item) => item.column.key !== filter.column.key
                                                    );
                                                    this.goPage(
                                                        0,
                                                        this.state.limit,
                                                        this.state.filterPhrase,
                                                        this.state.sortingColumnsList,
                                                        singleColumnFilterList
                                                    );
                                                    this.setState({ singleColumnFilterList: singleColumnFilterList });
                                                    const index = table.state.columns.findIndex((item) => item.field === filter.column.key);
                                                    if (index !== -1) {
                                                        if (table.state.columns[index].field === "accepted") {
                                                            table.state.columns[index].tableData.initialValue = ["all"];
                                                        } else {
                                                            table.state.columns[index].tableData.initialValue = "";
                                                            table.onFilterChange(index, null);
                                                        }
                                                    }
                                                }}
                                                label={`${key.replace("_", " ")}:  ${value}`}
                                                icon={icon}
                                                variant="outlined"
                                            />
                                        );
                                    })}

                                    {/*Generowanie tagów sortowania*/}
                                    {this.state.sortingColumnsList.map((column) => {
                                        let key;
                                        let icon = "";
                                        if (column.order === "ASC") icon = <ArrowDownward />;
                                        if (column.order === "DESC") icon = <ArrowUpward />;

                                        const columnKey = column.sortBy.key;
                                        key = this.state.columns.filter((column) => column.field === columnKey)[0].title;

                                        return (
                                            <Chip
                                                style={{ marginLeft: 24, marginTop: 10 }}
                                                onDelete={() => {
                                                    const sortingColumnsList = this.state.sortingColumnsList.filter(
                                                        (item) => item.sortBy.key !== column.sortBy.key
                                                    );
                                                    this.goPage(
                                                        0,
                                                        this.state.limit,
                                                        this.state.filterPhrase,
                                                        sortingColumnsList,
                                                        this.state.singleColumnFilterList
                                                    );
                                                    this.setState({ sortingColumnsList: sortingColumnsList });
                                                }}
                                                label={`${key}`}
                                                icon={icon}
                                            />
                                        );
                                    })}

                                    <div className={classes.SearchWrapper}>
                                        <SearchInput filterPhrase={this.state.filterPhrase} onChange={this.handleSerach} />
                                    </div>
                                </div>
                            );
                        },
                    }}
                    editable={{
                        onRowUpdate: (newData, oldData) =>
                            new Promise((resolve) => {
                                resolve();
                                this.updatePost(oldData.id, newData);
                            }),
                        onRowDelete: (oldData) =>
                            new Promise((resolve) => {
                                resolve();
                                oldData.tableData.showDetailPanel = undefined;
                                this.deletePost(oldData.id);
                            }),
                    }}
                />
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user_id: state.auth.user_id,
        ip_address: state.auth.ip_address,
        token: state.auth.token,
        role: state.auth.role,
    };
};

export default connect(mapStateToProps)(UkraineStories);
