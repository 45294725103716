import React from "react";
import { Link } from "react-router-dom";

import ReactHtmlParser from "react-html-parser";
import { MenuItem, Select } from "@material-ui/core";
import FilterDateRange from "../components/System/FilterDateRange/FilterDateRange";
import { checkDateRange, customCheckobxComponent, editComponent, editTextarea, selectEditInput, selectSourceLanguage, sortByDate } from "./tables/helperTables";
import FilterInput from "../components/System/FilterInput/FilterInput";

export default {
    usersColumns: [
        {
            title: "Id",
            field: "id",
            type: "number",
            filterComponent: (term) => {
                const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
                return <FilterInput term={term} onChange={onChange} />;
            },
        },
        {
            title: "Nazwa Użytkownika",
            field: "nickname",
            editComponent: (props) => editComponent(props, true),
            type: "text",
            filterComponent: (term) => {
                const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
                return <FilterInput term={term} onChange={onChange} />;
            },
        },
        {
            title: "Województwo",
            field: "location",
            editComponent: (props) => editComponent(props, true),
            type: "text",
            filterComponent: (term) => {
                const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
                return <FilterInput term={term} onChange={onChange} />;
            },
        },
        {
            title: "Adres Email",
            field: "email",
            editComponent: (props) => editComponent(props, true),
            type: "text",
            filterComponent: (term) => {
                const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
                return <FilterInput term={term} onChange={onChange} />;
            },
        },
        {
            title: "Data Rejestracji",
            field: "created_at",
            type: "dateType",
            editComponent: (props) => editComponent(props, false),
            filterComponent: (term) => {
                const onChange = (dataRange) => term.onFilterChanged(term.columnDef.tableData.id, dataRange);
                return <FilterDateRange onChange={onChange} />;
            },
            customFilterAndSearch: (term, rowData) => {
                return checkDateRange(term, rowData.dateType);
            },
            customSort: (a, b, c, d) => sortByDate(a, b, d, "dateType"),
        },
        {
            title: "Ostatnia aktywność",
            field: "last_login",
            type: "dateType",
            editComponent: (props) => editComponent(props, false),
            filterComponent: (term) => {
                const onChange = (dataRange) => term.onFilterChanged(term.columnDef.tableData.id, dataRange);
                return <FilterDateRange onChange={onChange} />;
            },
            customFilterAndSearch: (term, rowData) => {
                return checkDateRange(term, rowData.last_login);
            },
            render: (rowData) => {
                return <div>{!rowData.last_login || !rowData.last_login.trim() === "" ? <p>Brak</p> : <p>{rowData.last_login}</p>}</div>;
            },
            customSort: (a, b, c, d) => sortByDate(a, b, d, "last_readed"),
        },
        { title: "Email Aktywny", field: "email_confirmed", type: "boolean" },
        { title: "Konto Aktywne", field: "isActive", type: "boolean" },
        { title: "Konto Zablokowane", field: "banned", type: "boolean" },
        /*{title: 'Uprawnienia Admina', field: 'admin_permissions', type: 'boolean'},*/
        {
            title: "Śródmieście",
            field: "downtown",
            type: "boolean",
            editComponent: (props) => {
                return customCheckobxComponent(props, "silesian");
            },
        },
        {
            title: "Śląskie",
            field: "silesian",
            type: "boolean",
            editComponent: (props) => {
                return customCheckobxComponent(props, "downtown");
            },
        },
        {
            title: "Główny język",
            field: "main_lang_id",
            editable: "never",
            editComponent: (props) => editComponent(props, true),
            type: "select",
            filterComponent: (term) => {
                return (
                    <Select
                        value={term.columnDef.tableData.filterValue}
                        onChange={(e) => {
                            term.columnDef.tableData.filterValue = e.target.value === "1" ? "1" : 0;
                            term.onFilterChanged(term.columnDef.tableData.id, e.target.value);
                        }}
                    >
                        <MenuItem value={"1"}>Polski</MenuItem>
                        <MenuItem value={"2"}>Rosyjski</MenuItem>
                    </Select>
                );
            },
            render: (rowData) => {
                return <div>{rowData.main_lang_id === 1 ? <p>Polski</p> : <p>Rosyjski</p>}</div>;
            },
        },
    ],

    supportGroupsColumns: [
        {
            title: "Status",
            field: "pending",
            type: "boolean",
            editable: "never",
            filterComponent: (term) => {
                const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
                return <FilterInput term={term} onChange={onChange} />;
            },
            render: (rowData) => {
                return (
                    <div>
                        <p>{rowData.pending ? <p style={{ color: "red" }}>Oczekuje na potwierdzenie</p> : <p style={{ color: "green" }}>Zatwierdzona</p>}</p>
                    </div>
                );
            },
        },
        {
            title: "Nazwa Grupy",
            field: "name",
            type: "text",
            filterComponent: (term) => {
                const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
                return <FilterInput term={term} onChange={onChange} />;
            },
        },
        {
            title: "Data Utworzenia",
            field: "creation_date",
            type: "dateType",
            editComponent: (props) => editComponent(props, false),
            filterComponent: (term) => {
                const onChange = (dataRange) => term.onFilterChanged(term.columnDef.tableData.id, dataRange);
                return <FilterDateRange onChange={onChange} />;
            },
            customFilterAndSearch: (term, rowData) => {
                return checkDateRange(term, rowData.creation_date);
            },
            customSort: (a, b, c, d) => sortByDate(a, b, d, "creation_date"),
        },
        {
            title: "Opis",
            field: "description",
            editComponent: (props) => editTextarea(props, true),
            type: "text",
            filterComponent: (term) => {
                const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
                return <FilterInput term={term} onChange={onChange} />;
            },
        },
        {
            title: "Liczba członków",
            field: "max_members",
            editComponent: (props) => editComponent(props, true),
            type: "number",
            filterComponent: (term) => {
                const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
                return <FilterInput term={term} onChange={onChange} />;
            },
        },
        {
            title: "Kategoria",
            field: "category",
            editComponent: (props) => editComponent(props, false),
            type: "text",
            filterComponent: (term) => {
                const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
                return <FilterInput term={term} onChange={onChange} />;
            },
        },
        {
            title: "Admin",
            field: "admin",
            editComponent: (props) => selectEditInput(props, false),
            type: "text",
            filterComponent: (term) => {
                const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
                return <FilterInput term={term} onChange={onChange} />;
            },
            render: (rowData) => {
                return (
                    <div>
                        <Link to={`/users?page=1&limit=20&search=${encodeURIComponent(rowData.admin)}`}>{rowData.admin}</Link>
                    </div>
                );
            },
            cellStyle: { width: "180px" },
            headerStyle: { width: "180px" },
        },
        {
            title: "Automatycznie dodawany",
            field: "auto_add",
            type: "boolean",
        },
    ],

    localGroupsColumns: [
        {
            title: "Nazwa Grupy",
            field: "name",
            type: "text",
            filterComponent: (term) => {
                const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
                return <FilterInput term={term} onChange={onChange} />;
            },
        },
        {
            title: "Opis",
            field: "description",
            editComponent: (props) => editTextarea(props, true),
            type: "text",
            filterComponent: (term) => {
                const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
                return <FilterInput term={term} onChange={onChange} />;
            },
            render: (rowData) => {
                return <div>{ReactHtmlParser(rowData.description)}</div>;
            },
        },
        {
            title: "Status",
            field: "pending",
            type: "select",
            filterComponent: (term) => {
                return (
                    <Select
                        value={term.columnDef.tableData.filterValue}
                        onChange={(e) => {
                            term.columnDef.tableData.filterValue = e.target.value === "1" ? "1" : 0;
                            term.onFilterChanged(term.columnDef.tableData.id, e.target.value);
                        }}
                    >
                        <MenuItem value={"1"}>Oczekuje</MenuItem>
                        <MenuItem value={"0"}>Zaakceptowane</MenuItem>
                    </Select>
                );
            },
            render: (rowData) => {
                return (
                    <div>
                        <p>{rowData.pending ? <p style={{ color: "red" }}>Oczekuje</p> : <p style={{ color: "green" }}>Zaakceptowane</p>}</p>
                    </div>
                );
            },
        },
        {
            title: "Osoba kontaktowa",
            field: "contact_person",
            type: "text",
            filterComponent: (term) => {
                const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
                return <FilterInput term={term} onChange={onChange} />;
            },
        },
        {
            title: "Numer Telefonu",
            field: "phone",
            type: "text",
            filterComponent: (term) => {
                const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
                return <FilterInput term={term} onChange={onChange} />;
            },
        },
        {
            title: "Email",
            field: "email",
            type: "text",
            filterComponent: (term) => {
                const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
                return <FilterInput term={term} onChange={onChange} />;
            },
        },
        {
            title: "Ulica",
            field: "street",
            type: "text",
            filterComponent: (term) => {
                const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
                return <FilterInput term={term} onChange={onChange} />;
            },
        },
        {
            title: "Nr budynku",
            field: "number_building",
            type: "text",
            filterComponent: (term) => {
                const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
                return <FilterInput term={term} onChange={onChange} />;
            },
        },
        {
            title: "Nr mieszkania",
            field: "apartament_number",
            type: "text",
            filterComponent: (term) => {
                const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
                return <FilterInput term={term} onChange={onChange} />;
            },
        },
        {
            title: "Kod Pocztowy",
            field: "post_code",
            type: "text",
            filterComponent: (term) => {
                const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
                return <FilterInput term={term} onChange={onChange} />;
            },
        },
        {
            title: "Miasto",
            field: "city",
            type: "text",
            filterComponent: (term) => {
                const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
                return <FilterInput term={term} onChange={onChange} />;
            },
        },
        {
            title: "Powiat",
            field: "county",
            type: "text",
            filterComponent: (term) => {
                const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
                return <FilterInput term={term} onChange={onChange} />;
            },
        },
        {
            title: "Państwo",
            field: "country",
            type: "text",
            filterComponent: (term) => {
                const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
                return <FilterInput term={term} onChange={onChange} />;
            },
        },
        {
            title: "Województwo",
            field: "voivodeship",
            type: "text",
            filterComponent: (term) => {
                const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
                return <FilterInput term={term} onChange={onChange} />;
            },
        },
        {
            title: "Lat",
            field: "latitude",
            type: "text",
            filterComponent: (term) => {
                const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
                return <FilterInput term={term} onChange={onChange} />;
            },
        },
        {
            title: "Longitude",
            field: "longitude",
            type: "text",
            filterComponent: (term) => {
                const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
                return <FilterInput term={term} onChange={onChange} />;
            },
        },
        {
            title: "Typ Grupy",
            field: "local_group_type",
            type: "select",

            render: (rowData) => {
                return (
                    <div>
                        {rowData.local_group_type === 0 ? <p>Lokalne Grupa</p> : rowData.local_group_type === 1 ? <p>Ośrodek Wsparcia</p> : <p>Nieokreślony</p>}
                    </div>
                );
            },
            filterComponent: (term) => {
                return (
                    <Select
                        value={term.columnDef.tableData.filterValue}
                        onChange={(e) => {
                            term.columnDef.tableData.filterValue = e.target.value === "1" ? "1" : 0;
                            term.onFilterChanged(term.columnDef.tableData.id, e.target.value);
                        }}
                    >
                        <MenuItem value={"0"}>Lokalne Grupa</MenuItem>
                        <MenuItem value={"1"}>Ośrodek Wsparcia</MenuItem>
                    </Select>
                );
            },
        },
    ],

    thematicGroupsColumns: [
        {
            title: "Nazwa Grupy",
            field: "name",
            type: "text",
            filterComponent: (term) => {
                const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
                return <FilterInput term={term} onChange={onChange} />;
            },
        },
        {
            title: "Opis",
            field: "short_description",
            editComponent: (props) => editTextarea(props, true),
            type: "text",
            filterComponent: (term) => {
                const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
                return <FilterInput term={term} onChange={onChange} />;
            },
            render: (rowData) => {
                return <div>{ReactHtmlParser(rowData.short_description)}</div>;
            },
        },
        {
            title: "Główna Kategoria",
            field: "main_category",
            editComponent: (props) => selectEditInput(props, false),
            type: "text",
            filterComponent: (term) => {
                const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
                return <FilterInput term={term} onChange={onChange} />;
            },
        },
        {
            title: "Województwo",
            field: "voivodeship",
            editComponent: (props) => editComponent(props, true),
            type: "text",
            filterComponent: (term) => {
                const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
                return <FilterInput term={term} onChange={onChange} />;
            },
        },
        { title: "Wymagane potwierdzenie województwa", field: "required_confirmation_province", type: "boolean" },
        { title: "Posiada statystyki", field: "enable_fight_stats", type: "boolean" },
    ],

    mainCategories: [
        {
            title: "Nazwa Grupy",
            field: "name",
            type: "text",
            filterComponent: (term) => {
                const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
                return <FilterInput term={term} onChange={onChange} />;
            },
        },
        {
            title: "Opis",
            field: "description",
            editComponent: (props) => editTextarea(props, true),
            type: "text",
            filterComponent: (term) => {
                const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
                return <FilterInput term={term} onChange={onChange} />;
            },
        },
        {
            title: "Pozycja",
            field: "position",
            editComponent: (props) => editComponent(props, true),
            type: "number",
            filterComponent: (term) => {
                const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
                return <FilterInput term={term} onChange={onChange} />;
            },
        },
    ],

    // POSTS
    postsColumns: [
        {
            title: "Tytuł",
            field: "title",
            type: "text",
            filterComponent: (term) => {
                const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
                return <FilterInput term={term} onChange={onChange} />;
            },
        },
        {
            title: "Data Utworzenia",
            field: "created_at",
            type: "dateType",
            editComponent: (props) => editComponent(props, false),
            filterComponent: (term) => {
                const onChange = (dataRange) => term.onFilterChanged(term.columnDef.tableData.id, dataRange);
                return <FilterDateRange onChange={onChange} />;
            },
            customFilterAndSearch: (term, rowData) => {
                return checkDateRange(term, rowData.creation_date);
            },
            customSort: (a, b, c, d) => sortByDate(a, b, d, "creation_date"),
        },
        {
            title: "Treść",
            field: "content",
            editComponent: (props) => editTextarea(props, true),
            type: "text",
            filterComponent: (term) => {
                const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
                return <FilterInput term={term} onChange={onChange} />;
            },
        },
        {
            title: "Autor",
            field: "author",
            editable: "never",
            editComponent: (props) => editComponent(props, true),
            type: "text",
            filterComponent: (term) => {
                const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
                return <FilterInput term={term} onChange={onChange} />;
            },
            render: (rowData) => {
                return (
                    <div>
                        <Link to={`/users?page=1&limit=20&search=${encodeURIComponent(rowData.author)}`}>{rowData.author}</Link>
                    </div>
                );
            },
        },
        {
            title: "Kategoria",
            field: "category",
            editable: "never",
            editComponent: (props) => editComponent(props, true),
            type: "text",
            filterComponent: (term) => {
                const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
                return <FilterInput term={term} onChange={onChange} />;
            },
        },
        {
            title: "Typ",
            field: "post_type",
            editable: "never",
            editComponent: (props) => editComponent(props, true),
            type: "select",
            filterComponent: (term) => {
                return (
                    <Select
                        value={term.columnDef.tableData.filterValue}
                        onChange={(e) => {
                            term.columnDef.tableData.filterValue = e.target.value === "1" ? "1" : 0;
                            term.onFilterChanged(term.columnDef.tableData.id, e.target.value);
                        }}
                    >
                        <MenuItem value={"1"}>Upadek</MenuItem>
                        <MenuItem value={"0"}>Wpis</MenuItem>
                    </Select>
                );
            },
            render: (rowData) => {
                return <div>{rowData.post_type ? <p>Upadek</p> : <p>Wpis</p>}</div>;
            },
        },
    ],
    postCommentsColumns: [
        {
            title: "Treść",
            field: "content",
            editComponent: (props) => editTextarea(props, true),
            type: "text",
            filterComponent: (term) => {
                const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
                return <FilterInput term={term} onChange={onChange} />;
            },
        },
        {
            title: "Autor",
            field: "author",
            editable: "never",
            editComponent: (props) => editComponent(props, true),
            type: "text",
            filterComponent: (term) => {
                const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
                return <FilterInput term={term} onChange={onChange} />;
            },
        },
        {
            title: "Data Utworzenia",
            field: "created_at",
            type: "dateType",
            editable: "never",
            editComponent: (props) => editComponent(props, false),
            filterComponent: (term) => {
                const onChange = (dataRange) => term.onFilterChanged(term.columnDef.tableData.id, dataRange);
                return <FilterDateRange onChange={onChange} />;
            },
            customFilterAndSearch: (term, rowData) => {
                return checkDateRange(term, rowData.creation_date);
            },
            customSort: (a, b, c, d) => sortByDate(a, b, d, "creation_date"),
        },
        {
            title: "Post",
            field: "post",
            editable: "never",
            editComponent: (props) => editComponent(props, true),
            type: "text",
            filterComponent: (term) => {
                const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
                return <FilterInput term={term} onChange={onChange} />;
            },
        },
    ],

    // UKRAINE STORIES
    ukraineStoriesColumns: [
        {
            title: "Nick",
            field: "nick",
            type: "text",
            filterComponent: (term) => {
                const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
                return <FilterInput term={term} onChange={onChange} />;
            },
        },
        {
            title: "Tytuł (PL)",
            field: "title_pl",
            type: "text",
            filterComponent: (term) => {
                const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
                return <FilterInput term={term} onChange={onChange} />;
            },
        },
        {
            title: "Tytuł (UA)",
            field: "title_ua",
            type: "text",
            filterComponent: (term) => {
                const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
                return <FilterInput term={term} onChange={onChange} />;
            },
        },
        {
            title: "Tytuł (EN)",
            field: "title_en",
            type: "text",
            filterComponent: (term) => {
                const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
                return <FilterInput term={term} onChange={onChange} />;
            },
        },
        {
            title: "Data Utworzenia",
            field: "created_at",
            editable: "never",
            type: "dateType",
            editComponent: (props) => editComponent(props, false),
            filterComponent: (term) => {
                const onChange = (dataRange) => term.onFilterChanged(term.columnDef.tableData.id, dataRange);
                return <FilterDateRange onChange={onChange} />;
            },
            customFilterAndSearch: (term, rowData) => {
                return checkDateRange(term, rowData.creation_date);
            },
            customSort: (a, b, c, d) => sortByDate(a, b, d, "creation_date"),
        },
        {
            title: "Język źródłowy",
            field: "source_lang",
            editComponent: (props) => selectSourceLanguage(props, ["pl", "ua", "en"]),
            type: "text",
            filterComponent: (term) => {
                const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
                return <FilterInput term={term} onChange={onChange} />;
            },
        },
        {
            title: "Treść (PL)",
            field: "content_pl",
            editComponent: (props) => editTextarea(props, true),
            type: "text",
            filterComponent: (term) => {
                const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
                return <FilterInput term={term} onChange={onChange} />;
            },
        },
        {
            title: "Treść (UA)",
            field: "content_ua",
            editComponent: (props) => editTextarea(props, true),
            type: "text",
            filterComponent: (term) => {
                const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
                return <FilterInput term={term} onChange={onChange} />;
            },
        },
        {
            title: "Treść (EN)",
            field: "content_en",
            editComponent: (props) => editTextarea(props, true),
            type: "text",
            filterComponent: (term) => {
                const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
                return <FilterInput term={term} onChange={onChange} />;
            },
        },
        {
            title: "Kategoria",
            field: "story_category",
            editable: "never",
            editComponent: (props) => editComponent(props, true),
            type: "text",
            filterComponent: (term) => {
                const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
                return <FilterInput term={term} onChange={onChange} />;
            },
        },
        {
            title: "Profil zaufany",
            field: "trusted",
            editable: "never",
            type: "boolean",
        },
    ],
    ukraineStoriesCommentsColumns: [
        {
            title: "Nick",
            field: "nick",
            type: "text",
            filterComponent: (term) => {
                const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
                return <FilterInput term={term} onChange={onChange} />;
            },
        },
        {
            title: "Treść (PL)",
            field: "content_pl",
            editComponent: (props) => editTextarea(props, true),
            type: "text",
            filterComponent: (term) => {
                const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
                return <FilterInput term={term} onChange={onChange} />;
            },
        },
        {
            title: "Treść (UA)",
            field: "content_ua",
            editComponent: (props) => editTextarea(props, true),
            type: "text",
            filterComponent: (term) => {
                const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
                return <FilterInput term={term} onChange={onChange} />;
            },
        },
        {
            title: "Treść (EN)",
            field: "content_en",
            editComponent: (props) => editTextarea(props, true),
            type: "text",
            filterComponent: (term) => {
                const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
                return <FilterInput term={term} onChange={onChange} />;
            },
        },
        {
            title: "Data Utworzenia",
            field: "created_at",
            type: "dateType",
            editable: "never",
            editComponent: (props) => editComponent(props, false),
            filterComponent: (term) => {
                const onChange = (dataRange) => term.onFilterChanged(term.columnDef.tableData.id, dataRange);
                return <FilterDateRange onChange={onChange} />;
            },
            customFilterAndSearch: (term, rowData) => {
                return checkDateRange(term, rowData.creation_date);
            },
            customSort: (a, b, c, d) => sortByDate(a, b, d, "creation_date"),
        },
        {
            title: "Język źródłowy",
            field: "source_lang",
            editComponent: (props) => selectSourceLanguage(props, ["pl", "ua", "en"]),
            type: "text",
            filterComponent: (term) => {
                const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
                return <FilterInput term={term} onChange={onChange} />;
            },
        },
        {
            title: "Profil zaufany",
            field: "trusted",
            editable: "never",
            type: "boolean",
        },
    ],

    offersColumns: [
        {
            title: "Accepted",
            field: "accepted",
            type: "boolean",
            editable: "never",
            filterComponent: (term) => {
                const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
                return <FilterInput term={term} onChange={onChange} />;
            },
            render: (rowData) => {
                return (
                    <div>
                        {!rowData.accepted || typeof rowData.accepted === "undefined" ? (
                            <p style={{ color: "red" }}>Oczekuje na potwierdzenie</p>
                        ) : (
                            <p style={{ color: "green" }}>Zatwierdzona</p>
                        )}
                    </div>
                );
            },
        },
        {
            title: "Created at",
            field: "created_at",
            type: "dateType",
            editable: "never",
            editComponent: (props) => editComponent(props, false),
            filterComponent: (term) => {
                const onChange = (dataRange) => term.onFilterChanged(term.columnDef.tableData.id, dataRange);
                return <FilterDateRange onChange={onChange} />;
            },
            customFilterAndSearch: (term, rowData) => {
                return checkDateRange(term, rowData.creation_date);
            },
            customSort: (a, b, c, d) => sortByDate(a, b, d, "creation_date"),
        },
        {
            title: "Title",
            field: "title",
            editable: "never",
            editComponent: (props) => editTextarea(props, true),
            type: "text",
            filterComponent: (term) => {
                const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
                return <FilterInput term={term} onChange={onChange} />;
            },
        },
        {
            title: "Description",
            field: "description",
            editable: "never",
            editComponent: (props) => editTextarea(props, true),
            type: "text",
            filterComponent: (term) => {
                const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
                return <FilterInput term={term} onChange={onChange} />;
            },
            render: (rowData) => {
                return <div>{ReactHtmlParser(rowData.description)}</div>;
            },
        },
        {
            title: "Cover",
            field: "cover",
            editable: "never",
            editComponent: (props) => editTextarea(props, true),
            type: "text",
            filterComponent: (term) => {
                const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
                return <FilterInput term={term} onChange={onChange} />;
            },
            render: (rowData) => (
                <img style={{ width: "150px" }} src={`${process.env.REACT_APP_BACKEND_URL}/uploads/offer_photo/${rowData.cover}`} alt="Offer Cover" />
            ),
        },
        {
            title: "Location",
            field: "location",
            editable: "never",
            editComponent: (props) => editTextarea(props, true),
            type: "text",
            filterComponent: (term) => {
                const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
                return <FilterInput term={term} onChange={onChange} />;
            },
            render: (rowData) => {
                return (
                    <div>
                        <a href={`https://www.google.com/maps/search/?api=1&query=${rowData.lat},${rowData.lng}`}>{rowData.location}</a>
                    </div>
                );
            },
        },
        {
            title: "Price",
            field: "price",
            editable: "never",
            editComponent: (props) => editTextarea(props, true),
            type: "text",
            filterComponent: (term) => {
                const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
                return <FilterInput term={term} onChange={onChange} />;
            },
            render: (rowData) => (rowData.price === 0 ? <p>Bez ceny</p> : <p>{rowData.price + " " + rowData.currency}</p>),
        },
        {
            title: "Nazwa Użytkownika",
            field: "nickname",
            editComponent: (props) => editComponent(props, true),
            type: "text",
            filterComponent: (term) => {
                const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
                return <FilterInput term={term} onChange={onChange} />;
            },
            render: (rowData) => {
                return (
                    <div>
                        <Link to={`/users?page=1&limit=20&search=${encodeURIComponent(rowData.nickname)}`}>{rowData.nickname}</Link>
                    </div>
                );
            },
        },
        {
            title: "Kategoria",
            field: "category",
            editComponent: (props) => editComponent(props, true),
            type: "text",
            filterComponent: (term) => {
                const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
                return <FilterInput term={term} onChange={onChange} />;
            },
        },
    ],

    storyArticlesColumns: [
        {
            title: "Tytuł (PL)",
            field: "title_pl",
            editComponent: (props) => editComponent(props, true),
            type: "text",
            filterComponent: (term) => {
                const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
                return <FilterInput term={term} onChange={onChange} />;
            },
        },
        {
            title: "Tytuł (UA)",
            field: "title_ua",
            editComponent: (props) => editComponent(props, true),
            type: "text",
            filterComponent: (term) => {
                const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
                return <FilterInput term={term} onChange={onChange} />;
            },
        },
        {
            title: "Tytuł (EN)",
            field: "title_en",
            editComponent: (props) => editComponent(props, true),
            type: "text",
            filterComponent: (term) => {
                const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
                return <FilterInput term={term} onChange={onChange} />;
            },
        },
        {
            title: "Link (PL)",
            field: "slug_pl",
            editComponent: (props) => editComponent(props, true),
            type: "text",
            filterComponent: (term) => {
                const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
                return <FilterInput term={term} onChange={onChange} />;
            },
        },
        {
            title: "Link (UA)",
            field: "slug_ua",
            editComponent: (props) => editComponent(props, true),
            type: "text",
            filterComponent: (term) => {
                const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
                return <FilterInput term={term} onChange={onChange} />;
            },
        },
        {
            title: "Link (EN)",
            field: "slug_en",
            editComponent: (props) => editComponent(props, true),
            type: "text",
            filterComponent: (term) => {
                const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
                return <FilterInput term={term} onChange={onChange} />;
            },
        },
        {
            title: "Data Utworzenia",
            field: "created_at",
            type: "dateType",
            editable: "never",
            editComponent: (props) => editComponent(props, false),
            filterComponent: (term) => {
                const onChange = (dataRange) => term.onFilterChanged(term.columnDef.tableData.id, dataRange);
                return <FilterDateRange onChange={onChange} />;
            },
            customFilterAndSearch: (term, rowData) => {
                return checkDateRange(term, rowData.creation_date);
            },
            customSort: (a, b, c, d) => sortByDate(a, b, d, "creation_date"),
        },
        {
            title: "Skrócona Treść (PL)",
            field: "short_content_pl",
            editable: "never",
            editComponent: (props) => editTextarea(props, true),
            type: "text",
            filterComponent: (term) => {
                const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
                return <FilterInput term={term} onChange={onChange} />;
            },
            render: (rowData) => {
                return <div>{ReactHtmlParser(rowData.short_content_pl)}</div>;
            },
        },
        {
            title: "Skrócona Treść (UA)",
            field: "short_content_ua",
            editable: "never",
            editComponent: (props) => editTextarea(props, true),
            type: "text",
            filterComponent: (term) => {
                const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
                return <FilterInput term={term} onChange={onChange} />;
            },
            render: (rowData) => {
                return <div>{ReactHtmlParser(rowData.short_content_ua)}</div>;
            },
        },
        {
            title: "Opublikowany",
            field: "published",
            editable: "never",
            type: "boolean",
        },
        // {
        //     title: "Ilość odsłon",
        //     field: "visits_counter",
        //     editComponent: (props) => editComponent(props, true),
        //     type: "text",
        //     filterComponent: (term) => {
        //         const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        //         return <FilterInput term={term} onChange={onChange} />;
        //     },
        // },
    ],

    volunteersColumns: [
        {
            title: "Avatar",
            field: "image",
            sorting: false,
            filtering: false,
            editable: "never",
            render: (rowData) => {
                let content;
                if (rowData.img_url.length > 0) {
                    content = <img src={`${process.env.REACT_APP_BACKEND_URL}${rowData.img_url[0].url}`} alt={rowData.reference} />;
                } else {
                    content = <p>Brak zdjęcia</p>;
                }
                return content;
            },
        },
        {
            title: "Imię i nazwisko",
            field: "fullname",
            editable: "never",
            type: "text",
            filterComponent: (term) => {
                const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
                return <FilterInput term={term} onChange={onChange} />;
            },
        },
        {
            title: "About me (PL)",
            field: "about_me_pl",
            editable: "never",
            type: "text",
            filterComponent: (term) => {
                const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
                return <FilterInput term={term} onChange={onChange} />;
            },
            render: (rowData) => {
                return <div>{ReactHtmlParser(rowData.about_me_pl)}</div>;
            },
        },
        {
            title: "About me (UA)",
            field: "about_me_ua",
            editable: "never",
            type: "text",
            filterComponent: (term) => {
                const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
                return <FilterInput term={term} onChange={onChange} />;
            },
            render: (rowData) => {
                return <div>{ReactHtmlParser(rowData.about_me_ua)}</div>;
            },
        },
        {
            title: "About me (EN)",
            field: "about_me_en",
            editable: "never",
            type: "text",
            filterComponent: (term) => {
                const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
                return <FilterInput term={term} onChange={onChange} />;
            },
            render: (rowData) => {
                return <div>{ReactHtmlParser(rowData.about_me_en)}</div>;
            },
        },
        {
            title: "Numer telefonu",
            field: "phone",
            editable: "never",
            type: "text",
            filterComponent: (term) => {
                const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
                return <FilterInput term={term} onChange={onChange} />;
            },
        },
        {
            title: "Poniedziałek",
            field: "monday",
            editable: "never",
            type: "text",
            filterComponent: (term) => {
                const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
                return <FilterInput term={term} onChange={onChange} />;
            },
        },
        {
            title: "Wtorek",
            field: "tuesday",
            editable: "never",
            type: "text",
            filterComponent: (term) => {
                const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
                return <FilterInput term={term} onChange={onChange} />;
            },
        },
        {
            title: "Środa",
            field: "wednesday",
            editable: "never",
            type: "text",
            filterComponent: (term) => {
                const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
                return <FilterInput term={term} onChange={onChange} />;
            },
        },
        {
            title: "Czwartek",
            field: "thursday",
            editable: "never",
            type: "text",
            filterComponent: (term) => {
                const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
                return <FilterInput term={term} onChange={onChange} />;
            },
        },
        {
            title: "Piątek",
            field: "friday",
            editable: "never",
            type: "text",
            filterComponent: (term) => {
                const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
                return <FilterInput term={term} onChange={onChange} />;
            },
        },
        {
            title: "Sobota",
            field: "saturday",
            editable: "never",
            type: "text",
            filterComponent: (term) => {
                const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
                return <FilterInput term={term} onChange={onChange} />;
            },
        },
        {
            title: "Niedziela",
            field: "sunday",
            editable: "never",
            type: "text",
            filterComponent: (term) => {
                const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
                return <FilterInput term={term} onChange={onChange} />;
            },
        },
    ],

    articlesColumns: [
        {
            title: "Tytuł",
            field: "title",
            editComponent: (props) => editComponent(props, true),
            type: "text",
            filterComponent: (term) => {
                const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
                return <FilterInput term={term} onChange={onChange} />;
            },
        },
        {
            title: "Data Utworzenia",
            field: "created_at",
            type: "dateType",
            editable: "never",
            editComponent: (props) => editComponent(props, false),
            filterComponent: (term) => {
                const onChange = (dataRange) => term.onFilterChanged(term.columnDef.tableData.id, dataRange);
                return <FilterDateRange onChange={onChange} />;
            },
            customFilterAndSearch: (term, rowData) => {
                return checkDateRange(term, rowData.creation_date);
            },
            customSort: (a, b, c, d) => sortByDate(a, b, d, "creation_date"),
        },
        {
            title: "Skrócona Treść",
            field: "short_content",
            editable: "never",
            editComponent: (props) => editTextarea(props, true),
            type: "text",
            filterComponent: (term) => {
                const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
                return <FilterInput term={term} onChange={onChange} />;
            },
            render: (rowData) => {
                return <div>{ReactHtmlParser(rowData.short_content)}</div>;
            },
        },
        {
            title: "Opublikowany",
            field: "published",
            editable: "never",
            type: "boolean",
        },
        {
            title: "Ilość odsłon",
            field: "visits_counter",
            editComponent: (props) => editComponent(props, true),
            type: "text",
            filterComponent: (term) => {
                const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
                return <FilterInput term={term} onChange={onChange} />;
            },
        },
    ],

    mediaColumns: [
        {
            title: "Nazwa",
            field: "title",
            editComponent: (props) => editComponent(props, true),
            type: "text",
            filterComponent: (term) => {
                const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
                return <FilterInput term={term} onChange={onChange} />;
            },
        },
        {
            title: "Data Utworzenia",
            field: "created_at",
            type: "dateType",
            editable: "never",
            editComponent: (props) => editComponent(props, false),
            filterComponent: (term) => {
                const onChange = (dataRange) => term.onFilterChanged(term.columnDef.tableData.id, dataRange);
                return <FilterDateRange onChange={onChange} />;
            },
            customFilterAndSearch: (term, rowData) => {
                return checkDateRange(term, rowData.creation_date);
            },
            customSort: (a, b, c, d) => sortByDate(a, b, d, "creation_date"),
        },
        {
            title: "url",
            field: "url",
            editComponent: (props) => editComponent(props, true),
            type: "text",
            filterComponent: (term) => {
                const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
                return <FilterInput term={term} onChange={onChange} />;
            },
        },
        {
            title: "Opublikowany",
            field: "published",
            editable: "never",
            type: "boolean",
        },
    ],

    partnersColumns: [
        {
            title: "Nazwa",
            field: "title",
            editComponent: (props) => editComponent(props, true),
            type: "text",
            filterComponent: (term) => {
                const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
                return <FilterInput term={term} onChange={onChange} />;
            },
        },
        {
            title: "Data Utworzenia",
            field: "created_at",
            type: "dateType",
            editable: "never",
            editComponent: (props) => editComponent(props, false),
            filterComponent: (term) => {
                const onChange = (dataRange) => term.onFilterChanged(term.columnDef.tableData.id, dataRange);
                return <FilterDateRange onChange={onChange} />;
            },
            customFilterAndSearch: (term, rowData) => {
                return checkDateRange(term, rowData.creation_date);
            },
            customSort: (a, b, c, d) => sortByDate(a, b, d, "creation_date"),
        },
        {
            title: "Url",
            field: "url",
            editComponent: (props) => editComponent(props, true),
            type: "text",
            filterComponent: (term) => {
                const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
                return <FilterInput term={term} onChange={onChange} />;
            },
        },
        {
            title: "Opublikowany",
            field: "published",
            editable: "never",
            type: "boolean",
        },
    ],

    bannersColumns: [
        {
            title: "Nazwa",
            field: "title",
            editComponent: (props) => editComponent(props, true),
            type: "text",
            filterComponent: (term) => {
                const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
                return <FilterInput term={term} onChange={onChange} />;
            },
        },
        {
            title: "Data Utworzenia",
            field: "created_at",
            type: "dateType",
            editable: "never",
            editComponent: (props) => editComponent(props, false),
            filterComponent: (term) => {
                const onChange = (dataRange) => term.onFilterChanged(term.columnDef.tableData.id, dataRange);
                return <FilterDateRange onChange={onChange} />;
            },
            customFilterAndSearch: (term, rowData) => {
                return checkDateRange(term, rowData.creation_date);
            },
            customSort: (a, b, c, d) => sortByDate(a, b, d, "creation_date"),
        },
        {
            title: "Url",
            field: "url",
            editComponent: (props) => editComponent(props, true),
            type: "text",
            filterComponent: (term) => {
                const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
                return <FilterInput term={term} onChange={onChange} />;
            },
        },
        {
            title: "Pozycja",
            field: "position",
            editable: "never",
            type: "text",
            filterComponent: (term) => {
                const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
                return <FilterInput term={term} onChange={onChange} />;
            },
        },
    ],

    onlineWebinarsColumns: [
        {
            title: "Nazwa",
            field: "title",
            editComponent: (props) => editComponent(props, true),
            type: "text",
            filterComponent: (term) => {
                const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
                return <FilterInput term={term} onChange={onChange} />;
            },
        },
        {
            title: "Url",
            field: "url",
            editComponent: (props) => editComponent(props, true),
            type: "text",
            filterComponent: (term) => {
                const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
                return <FilterInput term={term} onChange={onChange} />;
            },
        },
        {
            title: "Author",
            field: "author",
            editComponent: (props) => editComponent(props, true),
            type: "text",
            filterComponent: (term) => {
                const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
                return <FilterInput term={term} onChange={onChange} />;
            },
        },
        {
            title: "Description",
            field: "description",
            editComponent: (props) => editComponent(props, true),
            type: "text",
            filterComponent: (term) => {
                const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
                return <FilterInput term={term} onChange={onChange} />;
            },
            render: (rowData) => {
                return <div>{ReactHtmlParser(rowData.description)}</div>;
            },
        },
        {
            title: "Start Date",
            field: "start_date",
            type: "dateType",
            editable: "never",
            editComponent: (props) => editComponent(props, false),
            filterComponent: (term) => {
                const onChange = (dataRange) => term.onFilterChanged(term.columnDef.tableData.id, dataRange);
                return <FilterDateRange onChange={onChange} />;
            },
            customFilterAndSearch: (term, rowData) => {
                return checkDateRange(term, rowData.start_date);
            },
            customSort: (a, b, c, d) => sortByDate(a, b, d, "start_date"),
        },
        {
            title: "Potwierdzonych udziałów(użytkowników)",
            field: "members_count",
            editable: "never",
            type: "text",
            filterComponent: (term) => {
                const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
                return <FilterInput term={term} onChange={onChange} />;
            },
        },
        {
            title: "Język",
            field: "lang_id",
            editable: "never",
            editComponent: (props) => editComponent(props, true),
            type: "select",
            filterComponent: (term) => {
                return (
                    <Select
                        value={term.columnDef.tableData.filterValue}
                        onChange={(e) => {
                            term.columnDef.tableData.filterValue = e.target.value === "1" ? "1" : 0;
                            term.onFilterChanged(term.columnDef.tableData.id, e.target.value);
                        }}
                    >
                        <MenuItem value={"1"}>Polski</MenuItem>
                        <MenuItem value={"2"}>Rosyjski</MenuItem>
                    </Select>
                );
            },
            render: (rowData) => {
                return <div>{rowData.lang_id === 1 ? <p>Polski</p> : <p>Rosyjski</p>}</div>;
            },
        },
    ],

    SupportGroupMessagesColumns: [
        {
            title: "Autor",
            field: "author",
            editComponent: (props) => editComponent(props, true),
            type: "text",
            filterComponent: (term) => {
                const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
                return <FilterInput term={term} onChange={onChange} />;
            },
        },
        {
            title: "Wiadomość",
            field: "message",
            editComponent: (props) => editTextarea(props, true),
            type: "text",
            filterComponent: (term) => {
                const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
                return <FilterInput term={term} onChange={onChange} />;
            },
        },
        {
            title: "Grupa Wsparcia",
            field: "support_group",
            editComponent: (props) => editComponent(props, true),
            type: "text",
            filterComponent: (term) => {
                const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
                return <FilterInput term={term} onChange={onChange} />;
            },
        },
        {
            title: "Administrator Grupy",
            field: "admin",
            editComponent: (props) => editComponent(props, true),
            type: "text",
            filterComponent: (term) => {
                const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
                return <FilterInput term={term} onChange={onChange} />;
            },
        },
        {
            title: "Data Wysłania",
            field: "send_date",
            type: "dateType",
            editable: "never",
            editComponent: (props) => editComponent(props, false),
            filterComponent: (term) => {
                const onChange = (dataRange) => term.onFilterChanged(term.columnDef.tableData.id, dataRange);
                return <FilterDateRange onChange={onChange} />;
            },
            customFilterAndSearch: (term, rowData) => {
                return checkDateRange(term, rowData.creation_date);
            },
            customSort: (a, b, c, d) => sortByDate(a, b, d, "send_date"),
        },
    ],

    ReportsColumns: [
        {
            title: "Użytkownik",
            field: "nickname",
            editable: "never",
            editComponent: (props) => editComponent(props, true),
            type: "text",
            filterComponent: (term) => {
                const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
                return <FilterInput term={term} onChange={onChange} />;
            },
        },
        {
            title: "Komentarz",
            field: "comment",
            editable: "never",
            editComponent: (props) => editTextarea(props, true),
            type: "text",
            filterComponent: (term) => {
                const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
                return <FilterInput term={term} onChange={onChange} />;
            },
        },
        {
            title: "Notatka",
            field: "note",
            editComponent: (props) => editTextarea(props, true),
            type: "text",
            filterComponent: (term) => {
                const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
                return <FilterInput term={term} onChange={onChange} />;
            },
        },
        {
            title: "Rodzaj zgłoszenia",
            field: "report_type",
            editable: "never",
            editComponent: (props) => editTextarea(props, true),
            type: "select",
            filterComponent: (term) => {
                return (
                    <Select
                        value={term.columnDef.tableData.filterValue}
                        onChange={(e) => {
                            //term.columnDef.tableData.filterValue = e.target.value === "1" ? "1" : 0;
                            term.onFilterChanged(term.columnDef.tableData.id, e.target.value);
                        }}
                    >
                        <MenuItem value={"1"}>Wpis</MenuItem>
                        <MenuItem value={"2"}>Komentarz</MenuItem>
                        <MenuItem value={"3"}>Grupa</MenuItem>
                        <MenuItem value={"4"}>Użytkownik</MenuItem>
                        <MenuItem value={"5"}>Oferta</MenuItem>
                    </Select>
                );
            },
            render: (rowData) => {
                return (
                    <div>
                        {rowData.report_type === 1 ? (
                            <p>Wpis</p>
                        ) : rowData.report_type === 2 ? (
                            <p>Komentarz</p>
                        ) : rowData.report_type === 3 ? (
                            <p>Grupa</p>
                        ) : rowData.report_type === 4 ? (
                            <p>Użytkownik</p>
                        ) : rowData.report_type === 5 ? (
                            <p>Oferta</p>
                        ) : (
                            <p>Nieokreślony</p>
                        )}
                    </div>
                );
            },
        },
        {
            title: "Link",
            field: "object_url",
            editable: "never",
            editComponent: (props) => editTextarea(props, true),
            type: "text",
            filterComponent: (term) => {
                const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
                return <FilterInput term={term} onChange={onChange} />;
            },
        },
        {
            title: "Data Zgłoszenia",
            field: "created_at",
            editable: "never",
            type: "dateType",
            editComponent: (props) => editComponent(props, false),
            filterComponent: (term) => {
                const onChange = (dataRange) => term.onFilterChanged(term.columnDef.tableData.id, dataRange);
                return <FilterDateRange onChange={onChange} />;
            },
            customFilterAndSearch: (term, rowData) => {
                return checkDateRange(term, rowData.dateType);
            },
            customSort: (a, b, c, d) => sortByDate(a, b, d, "dateType"),
        },
        { title: "Przeczytane", field: "is_read", type: "boolean" },
    ],

    trustedProfilesColumns: [
        {
            title: "Użytkownik",
            field: "nickname",
            editable: "never",
            type: "text",
            filterComponent: (term) => {
                const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
                return <FilterInput term={term} onChange={onChange} />;
            },
        },
        {
            title: "Email",
            field: "email",
            editable: "never",
            type: "text",
            filterComponent: (term) => {
                const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
                return <FilterInput term={term} onChange={onChange} />;
            },
        },
        {
            title: "Imię",
            field: "first_name",
            editable: "never",
            type: "text",
            filterComponent: (term) => {
                const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
                return <FilterInput term={term} onChange={onChange} />;
            },
        },
        {
            title: "Nazwisko",
            field: "surname",
            editable: "never",
            type: "text",
            filterComponent: (term) => {
                const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
                return <FilterInput term={term} onChange={onChange} />;
            },
        },
        {
            title: "Nr telefonu",
            field: "phone_number",
            editable: "never",
            type: "text",
            filterComponent: (term) => {
                const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
                return <FilterInput term={term} onChange={onChange} />;
            },
        },
        {
            title: "O mnie",
            field: "about_me",
            editable: "never",
            type: "text",
            filterComponent: (term) => {
                const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
                return <FilterInput term={term} onChange={onChange} />;
            },
        },
        {
            title: "O mnie (Do akceptacji)",
            field: "temp_about_me",
            editable: "never",
            type: "text",
            filterComponent: (term) => {
                const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
                return <FilterInput term={term} onChange={onChange} />;
            },
            render: (rowData) => {
                return <p style={{ color: "darkred" }}>{rowData.temp_about_me}</p>;
            },
        },
        {
            title: "Adres",
            field: "address",
            editable: "never",
            type: "text",
            filterComponent: (term) => {
                const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
                return <FilterInput term={term} onChange={onChange} />;
            },
        },
        {
            title: "Profil aktywny",
            field: "active",
            editable: "never",
            type: "boolean",
        },
    ],
};
