import React, {forwardRef, useImperativeHandle, useState, useEffect} from 'react';
import {useDropzone} from 'react-dropzone';

const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    flexWrap: 'wrap',
    marginTop: 16
};

const thumb = {
    display: 'inline-flex',
    height: '225px',
    width: '100%',
    justifyContent: 'center',
    boxSizing: 'border-box'
};

const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden',
    justifyContent: 'center'
};

const img = {
    display: 'block',
    width: 'auto',
    height: '100%'
};

const orderDropzone = {
    height: '100%',
    textAlign: 'center',
    padding: 10,
    cursor: 'pointer',
    outline: 'none'
};

const paragraph = {
    fontSize: 10,
    margin: 0,
    padding: 0
};

const OrderDropzone = forwardRef((props, ref) => {

    const [files, setFiles] = useState([]);
    const {getRootProps, getInputProps} = useDropzone({
        accept: 'image/*',
        onDrop: acceptedFiles => {
            setFiles(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));
            props.onDrop(acceptedFiles);
        }
    });

    useImperativeHandle(ref, () => ({
        setInitialFiles() {
            setFiles([]);
        }
    }));

    const thumbs = files.map(file => (
        <div style={thumb} key={file.name}>
            <div style={thumbInner}>
                <img
                    alt={file.name}
                    src={file.preview}
                    style={img}
                />
            </div>
        </div>
    ));

    useEffect(() => () => {
        files.forEach(file => URL.revokeObjectURL(file.preview));
    }, [files]);

    const showPreview = (files) => {
        const result = [];
        files.forEach((file, i) => {
            result.push(
                <div style={thumb} key={i}>
                    <div style={thumbInner}>
                        <a href={process.env.REACT_APP_BACKEND_URL + file.url}
                           rel="noopener noreferrer" target="_blank">
                            <img
                                alt='File'
                                src={process.env.REACT_APP_BACKEND_URL + file.url}
                                style={img}
                            />
                        </a>
                    </div>
                </div>
            )
        });
        return result;
    };

    return (
        <section style={{width: '100%', height: '100%', outline: 'none'}}>
            <div {...getRootProps({className: 'dropzone'})} style={orderDropzone}>
                <input disabled={props.disabled} {...getInputProps()} />
                {(files.length === 0 && props.files.length === 0) ? null : <p style={paragraph}>Change file{(files.length > 1 || props.files.length > 1) ? 's' : null}</p>} {/* Nagłówek */}
                {files.length > 0 ?
                    <aside style={thumbsContainer}>
                        {thumbs}
                    </aside> : null}
                {(files.length === 0 && props.files.length === 0 && !props.disabled) ? <p style={paragraph}>Add files</p> : null} {/* Nagłówek dla przypadku gdy nie ma plików */}
                {(files.length === 0 && props.files.length > 0) ? <aside style={thumbsContainer}>{showPreview(props.files)}</aside> : null} {/* showPreview tylko jeżeli nie ma tymczasowych plików w tablicy */}
            </div>
        </section>
    )
});

export default OrderDropzone;