import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Button, Paper, FormControl, Input } from "@material-ui/core";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { ArrowBack, Delete } from "@material-ui/icons";

// Shared
import axios from "../../../axios-sw";
import { authRedirect, catchResponse, thenResponse } from "../../../shared/utility";

// Components
import Spinner from "../../../components/UI/Spinner/Spinner";
import Dropzone from "../../../components/System/OrderDropzone/OrderDropzone";

// Styles
import classes from "./UkraineVolunteer.module.scss";

const UkraineVolunteer = (props) => {
    const [id, setId] = useState(props.match.params.id);
    const [loading, setLoading] = useState(true);
    const [volunteer, setVolunteer] = useState(null);
    const [img, setImg] = useState([]);

    const token = useSelector(({ auth }) => auth.token);
    const userId = useSelector(({ auth }) => auth.user_id);
    const ipAddress = useSelector(({ auth }) => auth.ip_address);
    const dropzone = useRef();

    useEffect(() => {
        if (id) indexVolunteer(id);
        else setLoading(false);
    }, [id]);

    const indexVolunteer = (id) => {
        axios
            .get(`admin/volunteers/${id}.json`, { headers: { Authorization: `Bearer ${token}` } })
            .then(async (res) => {
                await setVolunteer(res.data);
                await formik.setValues({
                    fullname: res.data.fullname,
                    about_me_pl: res.data.about_me_pl,
                    about_me_ua: res.data.about_me_ua,
                    about_me_en: res.data.about_me_en,
                    phone: res.data.phone,
                    monday: res.data.monday,
                    tuesday: res.data.tuesday,
                    wednesday: res.data.wednesday,
                    thursday: res.data.thursday,
                    friday: res.data.friday,
                    saturday: res.data.saturday,
                    sunday: res.data.sunday,
                    img: res.data.img_url,
                });
                setLoading(false);
            })
            .catch((err) => {
                catchResponse(err);
            });
    };

    const addVolunteer = (data) => {
        axios
            .post("admin/volunteers", data, { headers: { Authorization: `Bearer ${token}` } })
            .then(async (res) => {
                await updateImgFiles(img, res.data.volunteer_id, () => formik.setSubmitting(false));
                formik.setSubmitting(false);
                thenResponse(res);
                setId(res.data.volunteer_id);
                props.history.push(`/ukraine-volunteer/${res.data.volunteer_id}`);
            })
            .catch((err) => {
                formik.setSubmitting(false);
                catchResponse(err);
            });
    };

    const updateVolunteer = (id, data) => {
        axios
            .put(`admin/volunteers/${id}`, data, { headers: { Authorization: `Bearer ${token}` } })
            .then((res) => {
                updateImgFiles(img, id, () => formik.setSubmitting(false));
                formik.setSubmitting(false);
                thenResponse(res);
            })
            .catch((err) => {
                formik.setSubmitting(false);
                catchResponse(err);
            });
    };

    const validationSchema = Yup.object({
        fullname: Yup.string().required("Tytuł (PL) jest wymagany"),
        about_me_pl: Yup.string().required("Tytuł (PL) jest wymagany"),
        about_me_ua: Yup.string().required("Tytuł (PL) jest wymagany"),
    });

    const formik = useFormik({
        initialValues: {
            fullname: "",
            about_me_pl: "",
            about_me_ua: "",
            about_me_en: "",
            phone: "",
            monday: "",
            tuesday: "",
            wednesday: "",
            thursday: "",
            friday: "",
            saturday: "",
            sunday: "",
            img: "",
        },
        onSubmit: (values) => {
            const data = {
                volunteer: {
                    fullname: values.fullname,
                    about_me_pl: values.about_me_pl,
                    about_me_ua: values.about_me_ua,
                    about_me_en: values.about_me_en,
                    phone: values.phone,
                    monday: values.monday,
                    tuesday: values.tuesday,
                    wednesday: values.wednesday,
                    thursday: values.thursday,
                    friday: values.friday,
                    saturday: values.saturday,
                    sunday: values.sunday,
                    img_url: values.img,
                },
                user_id: userId,
                ip_address: ipAddress,
            };
            if (id) updateVolunteer(id, data);
            else addVolunteer(data);
        },
        validationSchema,
    });

    const updateImgFiles = (img_urls, volunteerId, callback) => {
        if (img_urls === null) {
            const formDataPreview = new FormData();
            formDataPreview.append("img_urls[]", null);
            formDataPreview.append("user_id", userId);
            formDataPreview.append("ip_address", ipAddress);

            axios.post(`/admin/volunteers/img-urls/${volunteerId}`, formDataPreview, { headers: { Authorization: `Bearer ${token}` } }).catch((err) => {
                callback();
                catchResponse(err);
            });
        } else if (img_urls.length > 0) {
            const formDataPreview = new FormData();
            for (let i = 0; i < img_urls.length; i++) {
                formDataPreview.append("img_urls[]", img_urls[i]);
            }
            formDataPreview.append("user_id", userId);
            formDataPreview.append("ip_address", ipAddress);
            axios.post(`/admin/volunteers/img-urls/${volunteerId}`, formDataPreview, { headers: { Authorization: `Bearer ${token}` } }).catch((err) => {
                callback();
                catchResponse(err);
            });
        }
    };

    return (
        <>
            {loading ? (
                <Spinner />
            ) : (
                <div className={classes.Article}>
                    {authRedirect(token)}
                    <ToastContainer />

                    <Paper className={classes.Paper}>
                        <Button className={classes.BackButton} variant="contained" onClick={() => props.history.push("/ukraine-volunteers")}>
                            <ArrowBack />
                        </Button>
                        {id && volunteer ? <h1>Eksport: {volunteer.fullname}</h1> : <h1>Dodaj Artykuł</h1>}

                        <form className={classes.ArticleForm} onSubmit={formik.handleSubmit}>
                            {/* IMAGE */}
                            <FormControl className={classes.FormControl}>
                                <div className={classes.DropzoneContainer}>
                                    <p className={classes.Label}>Główne Zjęcie</p>
                                    <div className={classes.Dropzone}>
                                        <Button
                                            className={classes.DropzoneDelete}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                dropzone.current.setInitialFiles();
                                                const volunteerData = volunteer;
                                                volunteerData.img_url = [];
                                                setVolunteer(volunteerData);
                                                setImg(null);
                                                formik.setFieldValue("img_url", null);
                                            }}
                                        >
                                            <Delete />
                                        </Button>
                                        <Dropzone
                                            files={volunteer && typeof volunteer.img_url !== "undefined" ? volunteer.img_url : []}
                                            disabled={false}
                                            ref={dropzone}
                                            onDrop={(files) => {
                                                formik.setFieldValue("img", files);
                                                setImg(files);
                                            }}
                                        />
                                    </div>
                                    <p className={classes.InputFeedback}>{formik.errors.img}</p>
                                </div>
                            </FormControl>

                            {/* fullname */}
                            <FormControl className={classes.FormControl}>
                                <p className={classes.Label}>Imię i nazwisko</p>
                                <Input
                                    id="fullname"
                                    error={formik.errors.fullname ? true : false}
                                    className={classes.Input}
                                    {...formik.getFieldProps("fullname")}
                                />
                                <p className={classes.InputFeedback}>{formik.errors.fullname}</p>
                            </FormControl>

                            {/* about_me_pl */}
                            <FormControl className={classes.FormControl}>
                                <p className={classes.Label}>About me (PL)</p>
                                <CKEditor
                                    id="about_me_pl"
                                    editor={ClassicEditor}
                                    data={`${formik.values.about_me_pl}`}
                                    config={{
                                        toolbar: [
                                            "heading",
                                            "|",
                                            "bold",
                                            "italic",
                                            "blockQuote",
                                            "link",
                                            "numberedList",
                                            "bulletedList",
                                            "imageUpload",
                                            "insertTable",
                                            "tableColumn",
                                            "tableRow",
                                            "mergeTableCells",
                                            "|",
                                            "undo",
                                            "redo",
                                        ],
                                    }}
                                    onChange={(e, editor) => {
                                        const data = editor.getData();
                                        formik.setFieldValue("about_me_pl", data);
                                    }}
                                />
                                <p className={classes.InputFeedback}>{formik.errors.about_me_pl}</p>
                            </FormControl>

                            {/* about_me_ua */}
                            <FormControl className={classes.FormControl}>
                                <p className={classes.Label}>About me (UA)</p>
                                <CKEditor
                                    id="about_me_ua"
                                    editor={ClassicEditor}
                                    data={`${formik.values.about_me_ua}`}
                                    config={{
                                        toolbar: [
                                            "heading",
                                            "|",
                                            "bold",
                                            "italic",
                                            "blockQuote",
                                            "link",
                                            "numberedList",
                                            "bulletedList",
                                            "imageUpload",
                                            "insertTable",
                                            "tableColumn",
                                            "tableRow",
                                            "mergeTableCells",
                                            "|",
                                            "undo",
                                            "redo",
                                        ],
                                    }}
                                    onChange={(e, editor) => {
                                        const data = editor.getData();
                                        formik.setFieldValue("about_me_ua", data);
                                    }}
                                />
                                <p className={classes.InputFeedback}>{formik.errors.about_me_ua}</p>
                            </FormControl>

                            {/* about_me_en */}
                            <FormControl className={classes.FormControl}>
                                <p className={classes.Label}>About me (EN)</p>
                                <CKEditor
                                    id="about_me_en"
                                    editor={ClassicEditor}
                                    data={`${formik.values.about_me_en}`}
                                    config={{
                                        toolbar: [
                                            "heading",
                                            "|",
                                            "bold",
                                            "italic",
                                            "blockQuote",
                                            "link",
                                            "numberedList",
                                            "bulletedList",
                                            "imageUpload",
                                            "insertTable",
                                            "tableColumn",
                                            "tableRow",
                                            "mergeTableCells",
                                            "|",
                                            "undo",
                                            "redo",
                                        ],
                                    }}
                                    onChange={(e, editor) => {
                                        const data = editor.getData();
                                        formik.setFieldValue("about_me_en", data);
                                    }}
                                />
                                <p className={classes.InputFeedback}>{formik.errors.about_me_en}</p>
                            </FormControl>

                            {/* phone */}
                            <FormControl className={classes.FormControl}>
                                <p className={classes.Label}>Numer telefonu</p>
                                <Input
                                    id="phone"
                                    error={formik.errors.phone ? true : false}
                                    className={classes.Input}
                                    {...formik.getFieldProps("phone")}
                                />
                                <p className={classes.InputFeedback}>{formik.errors.phone}</p>
                            </FormControl>

                            {/* monday */}
                            <FormControl className={classes.FormControl}>
                                <p className={classes.Label}>Poniedziałek</p>
                                <Input
                                    id="monday"
                                    error={formik.errors.monday ? true : false}
                                    className={classes.Input}
                                    {...formik.getFieldProps("monday")}
                                />
                                <p className={classes.InputFeedback}>{formik.errors.monday}</p>
                            </FormControl>

                            {/* tuesday */}
                            <FormControl className={classes.FormControl}>
                                <p className={classes.Label}>Wtorek</p>
                                <Input
                                    id="tuesday"
                                    error={formik.errors.tuesday ? true : false}
                                    className={classes.Input}
                                    {...formik.getFieldProps("tuesday")}
                                />
                                <p className={classes.InputFeedback}>{formik.errors.tuesday}</p>
                            </FormControl>

                            {/* wednesday */}
                            <FormControl className={classes.FormControl}>
                                <p className={classes.Label}>Środa</p>
                                <Input
                                    id="wednesday"
                                    error={formik.errors.wednesday ? true : false}
                                    className={classes.Input}
                                    {...formik.getFieldProps("wednesday")}
                                />
                                <p className={classes.InputFeedback}>{formik.errors.wednesday}</p>
                            </FormControl>

                            {/* thursday */}
                            <FormControl className={classes.FormControl}>
                                <p className={classes.Label}>Czwartek</p>
                                <Input
                                    id="thursday"
                                    error={formik.errors.thursday ? true : false}
                                    className={classes.Input}
                                    {...formik.getFieldProps("thursday")}
                                />
                                <p className={classes.InputFeedback}>{formik.errors.thursday}</p>
                            </FormControl>

                            {/* friday */}
                            <FormControl className={classes.FormControl}>
                                <p className={classes.Label}>Piątek</p>
                                <Input
                                    id="friday"
                                    error={formik.errors.friday ? true : false}
                                    className={classes.Input}
                                    {...formik.getFieldProps("friday")}
                                />
                                <p className={classes.InputFeedback}>{formik.errors.friday}</p>
                            </FormControl>

                            {/* saturday */}
                            <FormControl className={classes.FormControl}>
                                <p className={classes.Label}>Sobota</p>
                                <Input
                                    id="saturday"
                                    error={formik.errors.saturday ? true : false}
                                    className={classes.Input}
                                    {...formik.getFieldProps("saturday")}
                                />
                                <p className={classes.InputFeedback}>{formik.errors.saturday}</p>
                            </FormControl>

                            {/* sunday */}
                            <FormControl className={classes.FormControl}>
                                <p className={classes.Label}>Niedziela</p>
                                <Input
                                    id="sunday"
                                    error={formik.errors.sunday ? true : false}
                                    className={classes.Input}
                                    {...formik.getFieldProps("sunday")}
                                />
                                <p className={classes.InputFeedback}>{formik.errors.sunday}</p>
                            </FormControl>

                            <Button onClick={formik.handleSubmit} variant="contained" style={{ backgroundColor: "#006400", color: "#fff" }}>
                                {id && volunteer ? "Zapisz zmiany" : "Dodaj Artykuł"}
                            </Button>
                        </form>
                    </Paper>
                </div>
            )}
        </>
    );
};

export default UkraineVolunteer;
