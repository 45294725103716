import React, {Component} from 'react';
import axios from '../../axios-sw';
import {connect} from 'react-redux';

import {ToastContainer} from 'react-toastify';

import MaterialTable, {MTableBody, MTableBodyRow, MTableEditRow, MTableFilterRow, MTableToolbar} from 'material-table';
import {tableIcons, titleTemplate} from '../../shared/tableVariables';

import {Chip, TablePagination} from '@material-ui/core';

import Columns from '../../shared/tables';
import {HotKeys} from "../../components/System/HotKeys/HotKeys";

import {
    authRedirect,
    catchResponse,
    createSingleColumnFilterVal,
    createSortPath,
    formatDate,
    getAndConvertULRParams,
    getFilterPath,
    getSorthPath,
    thenResponse,
} from '../../shared/utility';

import {ArrowDownward, ArrowUpward, Cancel, Check, Close, GetApp, HighlightOff} from "@material-ui/icons";
import Spinner from "../../components/UI/Spinner/Spinner";

import {defaultPageSize} from '../../shared/defines';

import {json2excel} from 'js2excel';
import classes from "../../Common.module.scss";
import moment from "moment";
import SearchInput from "../../components/UI/SearchInput/SearchInput";
import ProgressCircular from "../../components/UI/ProgressCircular/ProgressCircular";

const sortingPhraseHelper = [
    {key: "email", path: "users.email"},
    {key: "nickname", path: "users.nickname"},
    {key: "first_name", path: "trusted_profiles.first_name"},
    {key: "surname", path: "trusted_profiles.surname"},
    {key: "phone_number", path: "trusted_profiles.phone_number"},
    {key: "about_me", path: "trusted_profiles.about_me"},
    {key: "temp_about_me", path: "trusted_profiles.temp_about_me"},
    {key: "address", path: "trusted_profiles.address"},
    {key: "active", path: "trusted_profiles.active"}
]

class TrustedProfiles extends Component {
    state = {
        columns: Columns.trustedProfilesColumns,
        columnsHidden: Columns.trustedProfilesColumns,
        currentStateColumns: null,
        data: [],
        filteredData: [],
        totalRow: 0,
        filterPhrase: '',
        sortBy: null,
        trustedProfile: null,
        sortingColumnsList: [],
        singleColumnFilterList: [],
        firstLoad: true,
        tableRef: React.createRef(),
        loaded: false,
        rowData: null,
        actions: [
            ({active}) => ({
                icon: active ? Cancel : Check,
                tooltip: active ? 'Anuluj Profil' : 'Zatwierdź Profil',
                onClick: (e, {id, active}) => {
                    const data = {
                        trusted_profile: {
                            active: !active,
                        },
                        user_id: this.props.user_id,
                        ip_address: this.props.ip_address
                    };

                    const url = `admin/trusted-profiles/${id}.json`;
                    axios.put(url, data, {headers: {'Authorization': `Bearer ${this.props.token}`}})
                        .then(res => {
                            axios.get(url, {headers: {'Authorization': `Bearer ${this.props.token}`}})
                                .then(res => this.setTrustedProfile(res.data))
                                .catch(err => catchResponse(err));
                            thenResponse(res)
                        })
                        .catch(err => {
                            catchResponse(err)
                        })
                }
            }),
            ({temp_about_me}) => ({
                icon: temp_about_me ? Check : Cancel,
                tooltip: temp_about_me ? 'Potwierdź nowy opis' : 'Opis potwierdzony',
                onClick: (e, {id, temp_about_me}) => {
                    if (temp_about_me) {
                        const data = {
                            user_id: this.props.user_id,
                            ip_address: this.props.ip_address
                        };

                        const postUrl = `admin/update-trusted-profiles/${id}.json`;
                        const getUrl = `admin/trusted-profiles/${id}.json`;
                        axios.post(postUrl, data, {headers: {'Authorization': `Bearer ${this.props.token}`}})
                            .then(res => {
                                axios.get(getUrl, {headers: {'Authorization': `Bearer ${this.props.token}`}})
                                    .then(res => this.setTrustedProfile(res.data))
                                    .catch(err => catchResponse(err));
                                thenResponse(res)
                            })
                            .catch(err => {
                                catchResponse(err)
                            })
                    }
                }
            }),
        ],
    };

    /*COMPONENT LIFE CYCLE FUNCTIONS*/
    async componentDidMount() {
        const {
            page,
            limit,
            search,
            sortingColumnsList,
            singleColumnFilterList
        } = getAndConvertULRParams(this.props.location.search, sortingPhraseHelper)
        this.indexTrustedProfiles(page - 1, limit, search, sortingColumnsList, singleColumnFilterList);

        this.assigmentFiltersValueToInputs(singleColumnFilterList);
        this.setSortingForColumns();

    };

    /*HELPER FUNCTIONS FOR COMPONENT*/
    indexTrustedProfiles = (page = 1, limit = 20, phrase = null, sortingColumnsList = [], singleColumnFilterList = []) => {
        const sortPath = getSorthPath(sortingColumnsList);
        const filterPath = getFilterPath(singleColumnFilterList);

        const startTime = Date.now();

        axios.get(`/admin/trusted-profiles/${page}/${limit}.json`, {
            params: {
                phrase: phrase,
                sortPath: sortPath,
                filterPath: filterPath
            }
        }, {headers: {'Authorization': `Bearer ${this.props.token}`}})
            .then(res => {
                this.state.tableRef.current.state.orderBy = -1;
                this.state.tableRef.current.state.orderDirection = ""
                this.state.tableRef.current.dataManager.orderBy = -1;
                this.state.tableRef.current.dataManager.orderDirection = ""

                const responseTime = ((Date.now() - startTime) / 1000);
                console.log('Response time: ' + responseTime + ' s');

                this.setState({
                    data: res.data.trusted_profiles,
                    filteredData: res.data.trusted_profiles,
                    totalRow: res.data.total_row,
                    page: parseInt(page),
                    limit: parseInt(limit),
                    filterPhrase: phrase,
                    sortingColumnsList: sortingColumnsList,
                    singleColumnFilterList: singleColumnFilterList,
                    loaded: true
                });

            })
            .catch(err => {
                this.setState({loaded: true});
                catchResponse(err)
            })
    };

    setTrustedProfile = fetchedTrustedProfile => {
        const array = [...this.state.data];
        const index = array.findIndex(x => x.id === fetchedTrustedProfile.id);
        if (index !== -1) {
            array[index].email = fetchedTrustedProfile.email;
            array[index].nickname = fetchedTrustedProfile.nickname;
            array[index].first_name = fetchedTrustedProfile.first_name;
            array[index].surname = fetchedTrustedProfile.surname;
            array[index].phone_number = fetchedTrustedProfile.phone_number;
            array[index].about_me = fetchedTrustedProfile.about_me;
            array[index].address = fetchedTrustedProfile.address;
            array[index].active = fetchedTrustedProfile.active;
            array[index].temp_about_me = fetchedTrustedProfile.temp_about_me;
        }
        this.setState({data: array})
    };

    confirmDialog = (msg) => {
        return new Promise(function (resolve, reject) {
            let confirmed = window.confirm(msg);
            return confirmed ? resolve(true) : reject(false);
        });
    };

    setSortingForColumns = () => {
        const sortLabelsDOM = document.querySelectorAll('.MuiTableSortLabel-root');
        sortLabelsDOM.forEach(label => {
            label.addEventListener("click", e => {

                let sortingColumnsList = this.state.sortingColumnsList;
                const labelName = label.querySelector('div').innerHTML.trim();
                const labelField = this.state.columns.find(column => column.title === labelName).field

                let indexIsExist = this.state.sortingColumnsList.findIndex(column => column.sortBy.key === labelField)

                if (indexIsExist !== -1) {
                    if (sortingColumnsList[indexIsExist].order === "DESC") sortingColumnsList.splice(indexIsExist, 1);
                    else if (sortingColumnsList[indexIsExist].order === "ASC") sortingColumnsList[indexIsExist].order = "DESC";
                    else if (!sortingColumnsList[indexIsExist].order) sortingColumnsList[indexIsExist].order = "ASC";
                    this.setState({sortingColumnsList: sortingColumnsList});
                } else {
                    const sqlColumn = sortingPhraseHelper.filter(phrase => phrase.key === labelField)
                    sortingColumnsList = [...sortingColumnsList, {sortBy: sqlColumn[0], order: "ASC"}]
                    this.setState({sortingColumnsList: sortingColumnsList})
                }

                this.goPage(0, this.state.limit, this.state.filterPhrase, sortingColumnsList, this.state.singleColumnFilterList)
            })
        })
    }

    handleSerach = (val) => {
        let convertVal = val.split("'").join("");

        const table = this.state.tableRef.current;
        const singleColumnFilterList = [];
        table.state.columns.forEach(column => {
            column.tableData.initialValue = '';
        })
        this.goPage(0, this.state.limit, convertVal, this.state.sortingColumnsList, singleColumnFilterList)
        this.setState({filterPhrase: convertVal, singleColumnFilterList: singleColumnFilterList})
    }

    goPage = (page = 0, limit = 20, search = null, sortingColumnsList = [], singleColumnFilterList = []) => {
        this.setState({loaded: false, filteredData: [], data: []})
        const searchVal = search && search.trim().length > 0 ? `&search=${search}` : '';

        const sortByVal = createSortPath(sortingColumnsList);
        const singleColumnFilterVal = createSingleColumnFilterVal(singleColumnFilterList)

        this.props.history.push(`/trusted-profiles?page=${page + 1}&limit=${limit}${searchVal}${sortByVal}${singleColumnFilterVal}`)
        this.indexTrustedProfiles(page, limit, search, sortingColumnsList, singleColumnFilterList, this.state.currentStateColumns)
    }

    assigmentFiltersValueToInputs = singleColumnFilterList => {
        const table = this.state.tableRef.current;
        singleColumnFilterList.forEach(filter => {
            const index = this.state.tableRef.current.state.columns.findIndex(column => column.field === filter.column.key);
            if (filter.type === "boolean") {
                if (filter.value.includes('true')) this.state.tableRef.current.onFilterChange(index, "checked");
                else if (filter.value.includes('false')) this.state.tableRef.current.onFilterChange(index, undefined);
                else this.state.tableRef.current.onFilterChange(index, null);
            } else if (filter.type === "text") {
                const value = filter.value.replace("LIKE", "").replace("'%", "").replace("%'", "").trim();
                this.state.tableRef.current.state.columns[index].tableData.initialValue = value;
            } else if (filter.type === "number") {
                const value = filter.value.replace("=", "").trim();
                this.state.tableRef.current.state.columns[index].tableData.initialValue = value;
            } else if (filter.type === "select") {
            } else if (filter.type === "multiSelect") {
                let value = filter.value.split("LIKE").join("").split("'%").join("").split("%'").join("").split(`OR ${filter.column.path}`).join(",").trim();
                let valueArray = value.split(",").map(item => item.trim());
                table.onFilterChange(index, valueArray);
            }
        })
        if (singleColumnFilterList.filter(filter => filter.type === 'boolean').length === 0) {
            for (let i = 0; i < table.state.columns.length; i++) {
                if (table.state.columns[i].type !== 'select' && table.state.columns[i].type !== 'multiSelect') {
                    table.onFilterChange(i, null);
                    table.state.columns[i].tableData.filterValue = null;
                }
            }
        }
    }

    render() {

        let emptyDataMessage;
        if (!this.state.loaded) {
            emptyDataMessage = <Spinner/>
        } else {
            emptyDataMessage = 'No data to display'
        }

        return (
            <>
                {!this.state.loaded && <div className={"overlay"}></div>}
                {authRedirect(this.props.token)}
                <ToastContainer/>
                <HotKeys/>
                <MaterialTable
                    tableRef={this.state.tableRef}
                    title={titleTemplate('Profile Zaufane')}
                    localization={{
                        body: {
                            emptyDataSourceMessage: emptyDataMessage,
                            editRow: {
                                cancelTooltip: 'Back'
                            }
                        }
                    }}
                    icons={tableIcons}
                    columns={this.state.columnsHidden}
                    data={this.state.filteredData}
                    style={{width: '100%'}}
                    actions={this.state.actions}
                    options={{
                        search: false,
                        pageSize: defaultPageSize,
                        filtering: true
                    }}
                    components={{
                        Body: props => {
                            return (
                                <MTableBody {...props} onFilterChanged={(columnId, value) => {
                                    const columnName = this.state.columnsHidden[columnId].field;
                                    let singleColumnFilterList = this.state.singleColumnFilterList;
                                    let indexIsExist = this.state.singleColumnFilterList.findIndex(filter => filter.column.key === columnName);
                                    const sqlColumn = sortingPhraseHelper.filter(phrase => phrase.key === columnName)
                                    const table = this.state.tableRef.current;

                                    if (this.state.columnsHidden[columnId].type === "dateType") {
                                        if (indexIsExist !== -1) {
                                            if (value.isEmpty) {
                                                singleColumnFilterList[indexIsExist].value = "IS NULL"
                                            } else if (value.isFull) {
                                                singleColumnFilterList[indexIsExist].value = "IS NOT NULL"
                                            } else {
                                                const start = moment(value.start).format('YYYY-MM-DD');
                                                const end = moment(value.end).format('YYYY-MM-DD');
                                                singleColumnFilterList[indexIsExist].value = `BETWEEN "${start}" AND "${end}"`;
                                            }
                                        } else {
                                            if (value.isEmpty) {
                                                singleColumnFilterList = [...singleColumnFilterList, {
                                                    column: sqlColumn[0],
                                                    value: "IS NULL",
                                                    type: 'dateType'
                                                }]
                                            } else if (value.isFull) {
                                                singleColumnFilterList = [...singleColumnFilterList, {
                                                    column: sqlColumn[0],
                                                    value: "IS NOT NULL",
                                                    type: 'dateType'
                                                }]
                                            } else {
                                                const start = moment(value.start).format('YYYY-MM-DD');
                                                const end = moment(value.end).format('YYYY-MM-DD');
                                                singleColumnFilterList = [...singleColumnFilterList, {
                                                    column: sqlColumn[0],
                                                    value: `BETWEEN "${start}" AND "${end}"`,
                                                    type: 'dateType'
                                                }]
                                            }
                                        }
                                    } else if (this.state.columnsHidden[columnId].type === "text") {
                                        if (indexIsExist !== -1) {
                                            singleColumnFilterList[indexIsExist].value = `LIKE '%${value}%'`;
                                            table.state.columns[columnId].tableData.initialValue = value;
                                        } else {
                                            singleColumnFilterList = [...singleColumnFilterList, {
                                                column: sqlColumn[0],
                                                value: `LIKE '%${value}%'`,
                                                type: 'text'
                                            }]
                                            table.state.columns[columnId].tableData.initialValue = value;
                                        }
                                    } else if (this.state.columnsHidden[columnId].type === "boolean") {
                                        if (indexIsExist !== -1) {
                                            if (value === "checked") {
                                                singleColumnFilterList.splice(indexIsExist, 1);
                                                table.onFilterChange(columnId, null);
                                            } else if (value === "unchecked") {
                                                singleColumnFilterList[indexIsExist].value = `=false OR ${sqlColumn[0].path} IS NULL`;
                                                table.onFilterChange(columnId, undefined);
                                            } else if (!value) {
                                                singleColumnFilterList[indexIsExist].value = '=true';
                                                table.onFilterChange(columnId, "checked");
                                            }
                                        } else {
                                            singleColumnFilterList = [...singleColumnFilterList, {
                                                column: sqlColumn[0],
                                                value: '=true',
                                                type: 'boolean'
                                            }]
                                            table.onFilterChange(columnId, "checked");
                                        }
                                    } else if (this.state.columnsHidden[columnId].type === "number") {
                                        const convertValue = value ? parseFloat(value.replace(/\s/g, '').replace(',', '.')) : 0;

                                        if (indexIsExist !== -1) {
                                            singleColumnFilterList[indexIsExist].value = `= ${convertValue}`;
                                            table.state.columns[columnId].tableData.initialValue = convertValue;
                                        } else {
                                            singleColumnFilterList = [...singleColumnFilterList, {
                                                column: sqlColumn[0],
                                                value: `= ${convertValue}`,
                                                type: 'number'
                                            }]
                                            table.state.columns[columnId].tableData.initialValue = convertValue;
                                        }
                                    } else if (this.state.columnsHidden[columnId].type === "select") {
                                        value = `LIKE "%${value}%"`

                                        if (indexIsExist !== -1) {
                                            singleColumnFilterList[indexIsExist].value = value;
                                            table.state.columns[columnId].tableData.initialValue = value;
                                        } else {
                                            singleColumnFilterList = [...singleColumnFilterList, {
                                                column: sqlColumn[0],
                                                value: value,
                                                type: 'select'
                                            }]
                                            table.state.columns[columnId].tableData.initialValue = value;
                                        }
                                    } else if (this.state.columnsHidden[columnId].type === "multiSelect") {
                                        if (this.state.columnsHidden[columnId].field === 'accepted') {
                                            let convertValue = '';
                                            value.forEach((item, index) => convertValue += index < value.length - 1 ? `LIKE "%${item}%" OR ${sqlColumn[0].path} ` : `LIKE "%${item}%"`)

                                            if (value.length > 0) {
                                                if (indexIsExist !== -1) {
                                                    singleColumnFilterList[indexIsExist].value = convertValue;
                                                    table.state.columns[columnId].tableData.initialValue = value;
                                                } else {
                                                    singleColumnFilterList = [...singleColumnFilterList, {
                                                        column: sqlColumn[0],
                                                        value: convertValue,
                                                        type: 'multiSelect'
                                                    }]
                                                    table.state.columns[columnId].tableData.initialValue = value;
                                                }
                                            } else {
                                                singleColumnFilterList = singleColumnFilterList.filter(filter => filter.column.key !== 'accepted');
                                                table.state.columns[columnId].tableData.initialValue = ["all"];
                                            }

                                        } else {
                                            if (indexIsExist !== -1) {
                                                let multiFiltersValues = '';
                                                value.forEach((filter, index) => {
                                                    multiFiltersValues += index === 0 ? `LIKE '%${value[index]}%'` : ` OR ${sqlColumn[0].path} LIKE '%${value[index]}%'`
                                                })
                                                singleColumnFilterList[indexIsExist].value = multiFiltersValues;
                                                table.onFilterChange(columnId, value);
                                            } else {
                                                singleColumnFilterList = [...singleColumnFilterList, {
                                                    column: sqlColumn[0],
                                                    value: `LIKE '%${value}%'`,
                                                    type: 'multiSelect'
                                                }]
                                                table.onFilterChange(columnId, value);
                                            }
                                        }
                                    }
                                    this.setState({singleColumnFilterList: singleColumnFilterList, filterPhrase: ''})
                                    this.goPage(0, this.state.limit, '', this.state.sortingColumnsList, singleColumnFilterList)
                                }}/>
                            )
                        },
                        Pagination: props => {
                            return (
                                <TablePagination
                                    {...props}
                                    rowsPerPage={this.state.limit}
                                    count={this.state.totalRow}
                                    page={this.state.page}
                                    onChangePage={(e, page) => {
                                        this.state.tableRef.current.onChangePage(e, page)
                                        this.setState({page: page})
                                        this.goPage(page, this.state.limit, this.state.filterPhrase, this.state.sortingColumnsList, this.state.singleColumnFilterList);
                                    }}
                                    onChangeRowsPerPage={event => {
                                        props.onChangeRowsPerPage(event);
                                        this.setState({limit: event.target.value})
                                        this.goPage(this.state.page, event.target.value, this.state.filterPhrase, this.state.sortingColumnsList, this.state.singleColumnFilterList);
                                    }}
                                />
                            )

                        },
                        FilterRow: props => {
                            return (
                                <MTableFilterRow className='filterRow' {...props} />
                            )
                        },
                        Row: props => (
                            <MTableBodyRow className={props.data.styles} {...props} />
                        ),
                        EditRow: props => (
                            <MTableEditRow
                                className='editingRow uppercase' {...props} />
                        ),
                        Toolbar: props => {
                            const table = this.state.tableRef.current;

                            return (
                                <div className='mainToolbar'>
                                    <MTableToolbar {...props} />

                                    {/* Usuwanie filtra z tabeli */}
                                    <Chip variant="outlined" style={{marginLeft: 24, marginTop: 5}}
                                          avatar={<HighlightOff/>}
                                          label="Clear filters" onClick={() => {

                                        this.goPage();
                                        this.props.history.push(`/trusted-profiles?page=${1}&limit=${20}`);

                                        table.state.columns.forEach((column, index) => {
                                            if (column.field === 'accepted') {
                                                table.state.columns[index].tableData.filterValue = 'all';
                                                column.tableData.initialValue = 'all';
                                            } else {
                                                table.onFilterChange(index, null);
                                                table.state.columns[index].tableData.filterValue = null;
                                                column.tableData.initialValue = '';
                                            }
                                            ;
                                        })
                                    }}/>
                                    {/* Eksportowanie danych z tabeli */}
                                    <Chip variant="outlined" style={{marginLeft: 24, marginTop: 10}}
                                          avatar={this.state.loaded ? <GetApp style={{padding: 5}}/> :
                                              <ProgressCircular size={20}/>}
                                          label="Export data to Excel"
                                          onClick={() => {
                                              const startTime = Date.now();
                                              this.setState({loaded: false, filteredData: []})

                                              const {
                                                  search,
                                                  sortingColumnsList,
                                                  singleColumnFilterList
                                              } = getAndConvertULRParams(this.props.location.search, sortingPhraseHelper)
                                              const sortPath = getSorthPath(sortingColumnsList);
                                              const filterPath = getFilterPath(singleColumnFilterList);

                                              axios.get(`/admin/export-trusted_profiles-to-excel.json`, {
                                                  params: {
                                                      phrase: search,
                                                      sortPath: sortPath,
                                                      filterPath: filterPath
                                                  }
                                              }, {headers: {'Authorization': `Bearer ${this.props.token}`}})
                                                  .then(async res => {
                                                      console.log('Response time: ' + ((Date.now() - startTime) / 1000) + ' s');
                                                      const trustedProfilesData = res.data.trusted_profiles;

                                                      let filteredTrustedProfilesData = trustedProfilesData.map(trustedProfile => {
                                                          return {
                                                              email: trustedProfile.email,
                                                              nazwa_użytkownika: trustedProfile.nickname,
                                                              imię: trustedProfile.first_name,
                                                              nazwisko: trustedProfile.surname,
                                                              numer_telefonu: trustedProfile.phone_number,
                                                              o_mnie: trustedProfile.about_me,
                                                              adres: trustedProfile.address,
                                                              aktywny: trustedProfile.active ? 'AKTYWNY' : 'NIEAKTYWNY',
                                                          }
                                                      });

                                                      json2excel({
                                                          data: filteredTrustedProfilesData,
                                                          name: 'profile_zaufane_' + formatDate(Date.now()),
                                                          formateDate: 'dd/mm/yyyy'
                                                      });

                                                      this.setState({loaded: true, filteredData: this.state.data})

                                                  })
                                                  .catch(err => {
                                                      catchResponse(err)
                                                  })
                                          }}/>
                                    {/*Generowanie tagu szukania*/}
                                    {this.state.filterPhrase && this.state.filterPhrase.trim().length > 0 &&
                                    <Chip style={{marginLeft: 24, marginTop: 10}} onDelete={() => {
                                        this.goPage(0, this.state.limit, '', this.state.sortingColumnsList, this.state.singleColumnFilterList)
                                        this.setState({filterPhrase: ''})
                                    }}
                                          label={`search: ${this.state.filterPhrase}`}
                                          color="primary"
                                    />}

                                    {/*Generowanie tagów filtrów kolumn*/}
                                    {this.state.singleColumnFilterList.map(filter => {
                                        let value;
                                        let key = filter.column.key;
                                        let icon = '';
                                        if (filter.type === 'dateType') value = filter.value.replace("BETWEEN", "").replace("AND", "-");
                                        else if (filter.type === 'text') value = filter.value.replace("LIKE", "").replace("'%", "").replace("%'", "");
                                        else if (filter.type === 'select') value = filter.value.replace("LIKE", "").replace("'%", "").replace("%'", "");
                                        else if (filter.type === 'multiSelect') value = filter.value.split("LIKE").join("").split("'%").join("").split("%'").join("").split(`OR ${filter.column.path}`).join(",");
                                        else if (filter.type === 'number') value = filter.value.replace("=", "");
                                        else if (filter.type === 'boolean') {
                                            if (filter.value.includes('true')) {
                                                value = "checked"
                                                icon = <Check/>
                                            } else {
                                                value = "unchecked"
                                                icon = <Close/>
                                            }
                                        }

                                        return (
                                            <Chip style={{marginLeft: 24, marginTop: 10}} onDelete={() => {
                                                const singleColumnFilterList = this.state.singleColumnFilterList.filter(item => item.column.key !== filter.column.key);
                                                this.goPage(0, this.state.limit, this.state.filterPhrase, this.state.sortingColumnsList, singleColumnFilterList)
                                                this.setState({singleColumnFilterList: singleColumnFilterList})
                                                const index = table.state.columns.findIndex(item => item.field === filter.column.key);
                                                if (index !== -1) {
                                                    if (table.state.columns[index].field === 'accepted') {
                                                        table.state.columns[index].tableData.initialValue = ["all"];
                                                    } else {
                                                        table.state.columns[index].tableData.initialValue = '';
                                                        table.onFilterChange(index, null);
                                                    }
                                                }
                                            }}
                                                  label={`${this.state.columns.find(item => item.field === key).title.toUpperCase()}:  ${value.toUpperCase()}`}
                                                  icon={icon}
                                                  variant="outlined"
                                            />
                                        )
                                    })}

                                    {/*Generowanie tagów sortowania*/}
                                    {this.state.sortingColumnsList.map(column => {
                                        let key = column.sortBy.key;
                                        let icon = '';
                                        if (column.order === "ASC") icon = <ArrowDownward/>
                                        if (column.order === "DESC") icon = <ArrowUpward/>

                                        return (
                                            <Chip style={{marginLeft: 24, marginTop: 10}} onDelete={() => {
                                                const sortingColumnsList = this.state.sortingColumnsList.filter(item => item.sortBy.key !== column.sortBy.key);
                                                this.goPage(0, this.state.limit, this.state.filterPhrase, sortingColumnsList, this.state.singleColumnFilterList)
                                                this.setState({sortingColumnsList: sortingColumnsList})
                                            }}
                                                  label={`${this.state.columns.find(item => item.field === key).title.toUpperCase()}`}
                                                  icon={icon}
                                            />
                                        )
                                    })}

                                    <div className={classes.SearchWrapper}>
                                        <SearchInput filterPhrase={this.state.filterPhrase}
                                                     onChange={this.handleSerach}/>
                                    </div>
                                </div>
                            )
                        }
                    }}
                    editable={this.state.editable}
                />
            </>
        );
    }

}

const mapStateToProps = state => {
    return {
        user_id: state.auth.user_id,
        ip_address: state.auth.ip_address,
        token: state.auth.token,
        role: state.auth.role,
    }
};

export default connect(mapStateToProps)(TrustedProfiles);