import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
    Button,
    Paper,
    FormControl,
    Checkbox,
    Input,
    FormControlLabel,
    FormGroup,
    OutlinedInput,
    InputLabel,
    MenuItem,
    ListItemText,
    Select,
} from "@material-ui/core";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { ArrowBack, Delete } from "@material-ui/icons";

// Shared
import axios from "../../../axios-sw";
import { authRedirect, catchResponse, thenResponse } from "../../../shared/utility";

// Components
import Spinner from "../../../components/UI/Spinner/Spinner";
import Dropzone from "../../../components/System/OrderDropzone/OrderDropzone";

// Styles
import classes from "./UkraineArticle.module.scss";

const UkraineArticle = (props) => {
    const [id, setId] = useState(props.match.params.id);
    const [loading, setLoading] = useState(true);
    const [videoOn, setVideoOn] = useState(false);
    const [article, setArticle] = useState(null);
    const [img, setImg] = useState([]);
    const [tags, setTags] = useState([]);
    const [chosenTags, setChosenTags] = useState([]);

    const token = useSelector(({ auth }) => auth.token);
    const userId = useSelector(({ auth }) => auth.user_id);
    const ipAddress = useSelector(({ auth }) => auth.ip_address);
    const dropzone = useRef();

    useEffect(() => {
        indexStoryArticleTags();
        if (id) indexArticle(id);
        else setLoading(false);
    }, [id]);

    const indexArticle = (id) => {
        axios
            .get(`admin/story-articles/${id}.json`, { headers: { Authorization: `Bearer ${token}` } })
            .then(async (res) => {
                await setArticle(res.data);
                await formik.setValues({
                    title_pl: res.data.title_pl,
                    title_ua: res.data.title_ua,
                    title_en: res.data.title_en,
                    slug_pl: res.data.slug_pl,
                    slug_ua: res.data.slug_ua,
                    slug_en: res.data.slug_en,
                    img: res.data.img_url,
                    short_content_pl: res.data.short_content_pl,
                    short_content_ua: res.data.short_content_ua,
                    short_content_en: res.data.short_content_en,
                    content_pl: res.data.content_pl,
                    content_ua: res.data.content_ua,
                    content_en: res.data.content_en,
                    published: res.data.published,
                    visits_counter: res.data.visits_counter,
                    video: res.data.video,
                });
                if (res.data.video !== null && res.data.video !== "") {
                    setVideoOn(true);
                }
                if (res.data.tags.length !== 0) {
                    setChosenTags(res.data.tags);
                }
                setLoading(false);
            })
            .catch((err) => {
                catchResponse(err);
            });
    };

    const indexStoryArticleTags = () => {
        axios
            .get(`admin/story-article-tags.json`, { headers: { Authorization: `Bearer ${token}` } })
            .then(async (res) => {
                await setTags(res.data.tags);
            })
            .catch((err) => {
                catchResponse(err);
            });
    };

    const addArticle = (data) => {
        axios
            .post("admin/story-articles", data, { headers: { Authorization: `Bearer ${token}` } })
            .then(async (res) => {
                await updateImgFiles(img, res.data.article_id, () => formik.setSubmitting(false));
                formik.setSubmitting(false);
                thenResponse(res);
                setId(res.data.article_id);
                props.history.push(`/ukraine-article/${res.data.article_id}`);
            })
            .catch((err) => {
                formik.setSubmitting(false);
                catchResponse(err);
            });
    };

    const updateArticle = (id, data) => {
        axios
            .put(`admin/story-articles/${id}`, data, { headers: { Authorization: `Bearer ${token}` } })
            .then((res) => {
                updateImgFiles(img, id, () => formik.setSubmitting(false));
                formik.setSubmitting(false);
                thenResponse(res);
            })
            .catch((err) => {
                formik.setSubmitting(false);
                catchResponse(err);
            });
    };

    const validationSchema = Yup.object({
        title_pl: Yup.string().required("Tytuł (PL) jest wymagany"),
        title_ua: Yup.string().required("Tytuł (UA) jest wymagany"),
        title_en: Yup.string().required("Tytuł (EN) jest wymagany"),
        slug_pl: Yup.string().required("Link (PL) jest wymagany!"),
        slug_ua: Yup.string().required("Link (UA) jest wymagany!"),
        slug_en: Yup.string().required("Link (EN) jest wymagany!"),
        short_content_pl: Yup.string().required("Krótka treść (PL) jest wymagany"),
        short_content_ua: Yup.string().required("Krótka treść (UA) jest wymagany"),
        short_content_en: Yup.string().required("Krótka treść (EN) jest wymagany"),
        video: Yup.string()
            .nullable()
            .when("videoOn", { is: true, then: Yup.string().required("Video ID jest wymagane") }),
        content_pl: Yup.string().when("videoOn", { is: false, then: Yup.string().required("Treść (PL) jest wymagany") }),
        content_ua: Yup.string().when("videoOn", { is: false, then: Yup.string().required("Treść (UA) jest wymagany") }),
        content_en: Yup.string().when("videoOn", { is: false, then: Yup.string().required("Treść (EN) jest wymagany") }),
    });

    const formik = useFormik({
        initialValues: {
            title_pl: "",
            title_ua: "",
            title_en: "",
            slug_pl: "",
            slug_ua: "",
            slug_en: "",
            img: "",
            short_content_pl: "",
            short_content_ua: "",
            short_content_en: "",
            content_pl: "",
            content_ua: "",
            content_en: "",
            video: "",
            published: false,
            visits_counter: 0,
        },
        onSubmit: (values) => {
            const data = {
                story_article: {
                    title_pl: values.title_pl,
                    title_ua: values.title_ua,
                    title_en: values.title_en,
                    slug_pl: values.slug_pl,
                    slug_ua: values.slug_ua,
                    slug_en: values.slug_en,
                    img_url: values.img,
                    short_content_pl: values.short_content_pl,
                    short_content_ua: values.short_content_ua,
                    short_content_en: values.short_content_en,
                    content_pl: values.content_pl,
                    content_ua: values.content_ua,
                    content_en: values.content_en,
                    video: values.video,
                    published: values.published,
                    visits_counter: values.visits_counter,
                },
                tags: chosenTags,
                user_id: userId,
                ip_address: ipAddress,
            };
            if (id) updateArticle(id, data);
            else addArticle(data);
        },
        validationSchema,
    });

    const updateImgFiles = (img_urls, articleId, callback) => {
        if (img_urls === null) {
            const formDataPreview = new FormData();
            formDataPreview.append("img_urls[]", null);
            formDataPreview.append("user_id", userId);
            formDataPreview.append("ip_address", ipAddress);

            axios.post(`/admin/story-articles/img-urls/${articleId}`, formDataPreview, { headers: { Authorization: `Bearer ${token}` } }).catch((err) => {
                callback();
                catchResponse(err);
            });
        } else if (img_urls.length > 0) {
            const formDataPreview = new FormData();
            for (let i = 0; i < img_urls.length; i++) {
                formDataPreview.append("img_urls[]", img_urls[i]);
            }
            formDataPreview.append("user_id", userId);
            formDataPreview.append("ip_address", ipAddress);
            axios.post(`/admin/story-articles/img-urls/${articleId}`, formDataPreview, { headers: { Authorization: `Bearer ${token}` } }).catch((err) => {
                callback();
                catchResponse(err);
            });
        }
    };

    return (
        <>
            {loading ? (
                <Spinner />
            ) : (
                <div className={classes.Article}>
                    {authRedirect(token)}
                    <ToastContainer />

                    <Paper className={classes.Paper}>
                        <Button className={classes.BackButton} variant="contained" onClick={() => props.history.push("/ukraine-articles")}>
                            <ArrowBack />
                        </Button>
                        {id && article ? <h1>Artykuł ID: {id}</h1> : <h1>Dodaj Artykuł</h1>}

                        <form className={classes.ArticleForm} onSubmit={formik.handleSubmit}>
                            {/* VIDEO */}
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={videoOn} onChange={() => setVideoOn(!videoOn)} />} label="Artykuł video" />
                            </FormGroup>

                            {/* VIDEO */}
                            {videoOn && (
                                <FormControl className={classes.FormControl}>
                                    <p className={classes.Label}>Video ID (z YouTube)</p>
                                    <Input id="video" error={formik.errors.video ? true : false} className={classes.Input} {...formik.getFieldProps("video")} />
                                    <p className={classes.InputFeedback}>{formik.errors.video}</p>
                                </FormControl>
                            )}

                            <FormControl className={classes.FormControl}>
                                <InputLabel id="tags">Tagi</InputLabel>
                                <Select
                                    labelId="tags"
                                    id="tags"
                                    multiple
                                    value={chosenTags}
                                    onChange={(e) => setChosenTags(typeof e.target.value === "string" ? e.target.value.split(",") : e.target.value)}
                                    input={<OutlinedInput label="Tag" />}
                                    renderValue={(selected) => selected.join(", ")}
                                >
                                    {tags.map((tag) => (
                                        <MenuItem key={tag.id} value={tag.name_pl}>
                                            <Checkbox checked={chosenTags.indexOf(tag.name_pl) > -1} />
                                            <ListItemText primary={tag.name_pl} />
                                        </MenuItem>
                                    ))}
                                </Select>
                                <p className={classes.InputFeedback}>{formik.errors.tags}</p>
                            </FormControl>

                            {/* IMAGE */}
                            <FormControl className={classes.FormControl}>
                                <div className={classes.DropzoneContainer}>
                                    <p className={classes.Label}>Główne Zjęcie</p>
                                    <div className={classes.Dropzone}>
                                        <Button
                                            className={classes.DropzoneDelete}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                dropzone.current.setInitialFiles();
                                                const articleData = article;
                                                articleData.img_url = [];
                                                setArticle(articleData);
                                                setImg(null);
                                                formik.setFieldValue("img_url", null);
                                            }}
                                        >
                                            <Delete />
                                        </Button>
                                        <Dropzone
                                            files={article && typeof article.img_url !== "undefined" ? article.img_url : []}
                                            disabled={false}
                                            ref={dropzone}
                                            onDrop={(files) => {
                                                formik.setFieldValue("img", files);
                                                setImg(files);
                                            }}
                                        />
                                    </div>
                                    <p className={classes.InputFeedback}>{formik.errors.img}</p>
                                </div>
                            </FormControl>

                            {/* TITLE PL */}
                            <FormControl className={classes.FormControl}>
                                <p className={classes.Label}>Tytuł (PL)</p>
                                <Input
                                    id="title_pl"
                                    error={formik.errors.title_pl ? true : false}
                                    className={classes.Input}
                                    {...formik.getFieldProps("title_pl")}
                                />
                                <p className={classes.InputFeedback}>{formik.errors.title_pl}</p>
                            </FormControl>

                            {/* TITLE UA */}
                            <FormControl className={classes.FormControl}>
                                <p className={classes.Label}>Tytuł (UA)</p>
                                <Input
                                    id="title_ua"
                                    error={formik.errors.title_ua ? true : false}
                                    className={classes.Input}
                                    {...formik.getFieldProps("title_ua")}
                                />
                                <p className={classes.InputFeedback}>{formik.errors.title_pl}</p>
                            </FormControl>

                            {/* TITLE EN */}
                            <FormControl className={classes.FormControl}>
                                <p className={classes.Label}>Tytuł (EN)</p>
                                <Input
                                    id="title_en"
                                    error={formik.errors.title_en ? true : false}
                                    className={classes.Input}
                                    {...formik.getFieldProps("title_en")}
                                />
                                <p className={classes.InputFeedback}>{formik.errors.title_en}</p>
                            </FormControl>

                            {/* SLUG PL */}
                            <FormControl className={classes.FormControl}>
                                <p className={classes.Label}>Link (PL)</p>
                                <Input
                                    id="slug_pl"
                                    error={formik.errors.slug_pl ? true : false}
                                    className={classes.Input}
                                    {...formik.getFieldProps("slug_pl")}
                                />
                                <p className={classes.InputFeedback}>{formik.errors.slug_pl}</p>
                            </FormControl>

                            {/* SLUG UA */}
                            <FormControl className={classes.FormControl}>
                                <p className={classes.Label}>Link (UA)</p>
                                <Input
                                    id="slug_ua"
                                    error={formik.errors.slug_ua ? true : false}
                                    className={classes.Input}
                                    {...formik.getFieldProps("slug_ua")}
                                />
                                <p className={classes.InputFeedback}>{formik.errors.slug_ua}</p>
                            </FormControl>

                            {/* SLUG EN */}
                            <FormControl className={classes.FormControl}>
                                <p className={classes.Label}>Link (EN)</p>
                                <Input
                                    id="slug_en"
                                    error={formik.errors.slug_en ? true : false}
                                    className={classes.Input}
                                    {...formik.getFieldProps("slug_en")}
                                />
                                <p className={classes.InputFeedback}>{formik.errors.slug_en}</p>
                            </FormControl>

                            {/* <FormControl className={classes.FormControl}>
                                <p className={classes.Label}>Ilość wejść</p>
                                <Input
                                    id="visits_counter"
                                    error={formik.errors.visits_counter ? true : false}
                                    className={classes.Input}
                                    {...formik.getFieldProps("visits_counter")}
                                />
                                <p className={classes.InputFeedback}>{formik.errors.visits_counter}</p>
                            </FormControl> */}

                            {/* SHORT CONTENT PL */}
                            <FormControl className={classes.FormControl}>
                                <p className={classes.Label}>Skrócony opis (PL)</p>
                                <CKEditor
                                    id="short_content_pl"
                                    editor={ClassicEditor}
                                    data={`${formik.values.short_content_pl}`}
                                    config={{
                                        toolbar: [
                                            "heading",
                                            "|",
                                            "bold",
                                            "italic",
                                            "blockQuote",
                                            "link",
                                            "numberedList",
                                            "bulletedList",
                                            "imageUpload",
                                            "insertTable",
                                            "tableColumn",
                                            "tableRow",
                                            "mergeTableCells",
                                            "|",
                                            "undo",
                                            "redo",
                                        ],
                                    }}
                                    onChange={(e, editor) => {
                                        const data = editor.getData();
                                        formik.setFieldValue("short_content_pl", data);
                                    }}
                                />
                                <p className={classes.InputFeedback}>{formik.errors.short_content_pl}</p>
                            </FormControl>

                            {/* SHORT CONTENT UA */}
                            <FormControl className={classes.FormControl}>
                                <p className={classes.Label}>Skrócony opis (UA)</p>
                                <CKEditor
                                    id="short_content_ua"
                                    editor={ClassicEditor}
                                    data={`${formik.values.short_content_ua}`}
                                    config={{
                                        toolbar: [
                                            "heading",
                                            "|",
                                            "bold",
                                            "italic",
                                            "blockQuote",
                                            "link",
                                            "numberedList",
                                            "bulletedList",
                                            "imageUpload",
                                            "insertTable",
                                            "tableColumn",
                                            "tableRow",
                                            "mergeTableCells",
                                            "|",
                                            "undo",
                                            "redo",
                                        ],
                                    }}
                                    onChange={(e, editor) => {
                                        const data = editor.getData();
                                        formik.setFieldValue("short_content_ua", data);
                                    }}
                                />
                                <p className={classes.InputFeedback}>{formik.errors.short_content_ua}</p>
                            </FormControl>

                            {/* SHORT CONTENT EN */}
                            <FormControl className={classes.FormControl}>
                                <p className={classes.Label}>Skrócony opis (EN)</p>
                                <CKEditor
                                    id="short_content_en"
                                    editor={ClassicEditor}
                                    data={`${formik.values.short_content_en}`}
                                    config={{
                                        toolbar: [
                                            "heading",
                                            "|",
                                            "bold",
                                            "italic",
                                            "blockQuote",
                                            "link",
                                            "numberedList",
                                            "bulletedList",
                                            "imageUpload",
                                            "insertTable",
                                            "tableColumn",
                                            "tableRow",
                                            "mergeTableCells",
                                            "|",
                                            "undo",
                                            "redo",
                                        ],
                                    }}
                                    onChange={(e, editor) => {
                                        const data = editor.getData();
                                        formik.setFieldValue("short_content_en", data);
                                    }}
                                />
                                <p className={classes.InputFeedback}>{formik.errors.short_content_en}</p>
                            </FormControl>

                            {/* CONTENT PL */}
                            <FormControl className={classes.FormControl}>
                                <p className={classes.Label}>Treść (PL)</p>
                                <CKEditor
                                    id="content_pl"
                                    editor={ClassicEditor}
                                    data={`${formik.values.content_pl}`}
                                    config={{
                                        toolbar: [
                                            "heading",
                                            "|",
                                            "bold",
                                            "italic",
                                            "blockQuote",
                                            "link",
                                            "numberedList",
                                            "bulletedList",
                                            "imageUpload",
                                            "insertTable",
                                            "tableColumn",
                                            "tableRow",
                                            "mergeTableCells",
                                            "|",
                                            "undo",
                                            "redo",
                                        ],
                                    }}
                                    onChange={(e, editor) => {
                                        const data = editor.getData();
                                        formik.setFieldValue("content_pl", data);
                                    }}
                                />
                                <p className={classes.InputFeedback}>{formik.errors.content_pl}</p>
                            </FormControl>

                            {/* CONTENT UA */}
                            <FormControl className={classes.FormControl}>
                                <p className={classes.Label}>Treść (UA)</p>
                                <CKEditor
                                    id="content_ua"
                                    editor={ClassicEditor}
                                    data={`${formik.values.content_ua}`}
                                    config={{
                                        toolbar: [
                                            "heading",
                                            "|",
                                            "bold",
                                            "italic",
                                            "blockQuote",
                                            "link",
                                            "numberedList",
                                            "bulletedList",
                                            "imageUpload",
                                            "insertTable",
                                            "tableColumn",
                                            "tableRow",
                                            "mergeTableCells",
                                            "|",
                                            "undo",
                                            "redo",
                                        ],
                                    }}
                                    onChange={(e, editor) => {
                                        const data = editor.getData();
                                        formik.setFieldValue("content_ua", data);
                                    }}
                                />
                                <p className={classes.InputFeedback}>{formik.errors.content_ua}</p>
                            </FormControl>

                            {/* CONTENT EN */}
                            <FormControl className={classes.FormControl}>
                                <p className={classes.Label}>Treść (EN)</p>
                                <CKEditor
                                    id="content_en"
                                    editor={ClassicEditor}
                                    data={`${formik.values.content_en}`}
                                    config={{
                                        toolbar: [
                                            "heading",
                                            "|",
                                            "bold",
                                            "italic",
                                            "blockQuote",
                                            "link",
                                            "numberedList",
                                            "bulletedList",
                                            "imageUpload",
                                            "insertTable",
                                            "tableColumn",
                                            "tableRow",
                                            "mergeTableCells",
                                            "|",
                                            "undo",
                                            "redo",
                                        ],
                                    }}
                                    onChange={(e, editor) => {
                                        const data = editor.getData();
                                        formik.setFieldValue("content_en", data);
                                    }}
                                />
                                <p className={classes.InputFeedback}>{formik.errors.content_en}</p>
                            </FormControl>

                            {/* PUBLISHED */}
                            <FormControl className={classes.FormControlCheckbox}>
                                <p className={classes.Label}>Czy artykuł ma zostać opublikowany (widoczny)?</p>
                                <Checkbox
                                    className={classes.Checkbox}
                                    id="published"
                                    checked={formik.values.published}
                                    {...formik.getFieldProps("published")}
                                />
                            </FormControl>

                            <Button onClick={formik.handleSubmit} variant="contained" style={{ backgroundColor: "#006400", color: "#fff" }}>
                                {id && article ? "Zapisz zmiany" : "Dodaj Artykuł"}
                            </Button>
                        </form>
                    </Paper>
                </div>
            )}
        </>
    );
};

export default UkraineArticle;
