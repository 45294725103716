import React, {Component} from "react";
import Box from '@material-ui/core/Box';
import { Grid, IconButton, CircularProgress} from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import axios from '../../../axios-sw';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';

class UserSupportGroup extends Component { 
  state = {
    loading: true,
    value: 0,
    userGroups: [],
    notUserGroups: []
  }

  getSupportGroups = async () => {
    this.setState({loading: true})
    await axios.get(`admin/get-user-support-groups/${this.props.user_id}`)
      .then(resp => {
         this.setState({userGroups: resp.data.userGroups,
           notUserGroups: resp.data.userNotGroups,
          loading: false})
      });
  }

  addToGroup = (supportGroupId) => {
    this.setState({loading: true});
    axios.post(`/admin/users/add-user-to-group`, {
      params: {
          supportGroupId: supportGroupId,
          userId: this.props.user_id
      }
  }, {headers: {'Authorization': `Bearer ${this.props.token}`}})
      .then(async resp => {
        this.setState({userGroups: resp.data.userGroups, notUserGroups: resp.data.userNotGroups, loading: false})
  });
  }

  removeFromGroup = (supportGroupId) => {
    this.setState({loading: true});
    axios.delete(`/admin/users/remove-user-from-group/${supportGroupId}/${this.props.user_id}`,
     {headers: {'Authorization': `Bearer ${this.props.token}`}})
      .then(async resp => {
        this.getSupportGroups();
  });
  }


  componentDidMount() {
    this.getSupportGroups();
  }

  render() {
    return (
      <Modal
        open={true}
        onClose={() => this.props.onClose()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box style={style}>
          {this.state.loading &&
          <div style={{
            justifyContent: "center", alignItems: "center", display: "flex",
            position: "absolute", width: "100%", height: "100%", backgroundColor: "grey", opacity: 0.5}}>
            <CircularProgress />
          </div>
  }
        <Grid container spacing={3}>
        <Grid item style={{paddingLeft: "25px", maxHeight: "50vh"}} xs={6}>
         <p>Grupy do których należy</p>
         <hr/>
         <div style={{overflowY: "scroll", maxHeight: "80%"}}>
         {
            this.state.userGroups.map((group, index) => (
              <Grid key={`notGroup${index}`} style={{marginBottom: "10px"}} container>
                <Grid xs={10}>
                  {group.name}
                </Grid>
                <Grid xs={2}>
                  <IconButton 
                  onClick={() => this.removeFromGroup(group.id)}
                  style={{padding: "0px"}} aria-label="delete">
                    <RemoveIcon fontSize="small" />
                  </IconButton>
                </Grid>
              </Grid>
            ))
         }
         </div>
        </Grid>
        <Grid item style={{paddingRight: "25px", maxHeight: "50vh"}} xs={6}>
          <p>Grupy do których nie należy</p>
          <hr/>
          <div style={{overflowY: "scroll", maxHeight: "80%"}}>
          {
            this.state.notUserGroups.map((group, index) => (
              <Grid key={`group${index}`} style={{marginBottom: "10px"}} container>
                <Grid xs={10}>
                  {group.name}
                </Grid>
                <Grid xs={2}>
                  <IconButton 
                    onClick={() => this.addToGroup(group.id)}
                  style={{padding: "0px"}} aria-label="delete">
                    <AddIcon fontSize="small" />
                  </IconButton>
                </Grid>
              </Grid>
            ))
          }
          </div>
        </Grid>
          </Grid>
        </Box>
      </Modal>
    );
  }
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: "#fff",
  minWidth: 500,
  boxShadow: 24,
  p: 4,
};

export default UserSupportGroup;