import React, {useEffect, useState} from "react";
import {fade, makeStyles} from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';

const useStyles = makeStyles((theme) => ({
    search: {
        position: 'relative',
        borderBottom: '0.5px solid #000',
        borderRadius: "0",
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
        '&::placeholder': {
            textTransform: 'none'
        }
    }
}));

const SearchInput = ({onChange, filterPhrase, ...props}) => {
    const classes = useStyles();
    const [searchValue, setSearchValue]= useState('');

    useEffect(() => {
        if(filterPhrase) {
            setSearchValue(filterPhrase)
        }
    }, [filterPhrase]);


    const keyPress = (e) => {
        if (e.keyCode == 13) {
            onChange(e.target.value)
        }
        if (e.keyCode === 8 && e.target.value === "") {
            onChange(e.target.value)
        }
    }

    const clearSearch = () => {
        setSearchValue('');
        onChange("")
    }

    return (
        <div className={classes.search}>
            <div className={classes.searchIcon}>
                <SearchIcon/>
            </div>
            <InputBase
                placeholder="Search and press Enter…"
                classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                }}
                value={searchValue}
                onChange={e => setSearchValue(e.target.value.split("'").join(""))}
                onKeyDown={keyPress}
                inputProps={{'aria-label': 'search'}}
            />
            <IconButton disabled={searchValue.trim() === "" ? true : false} onClick={clearSearch} aria-label="clear" size="small">
                <ClearIcon/>
            </IconButton>
        </div>
    )
}

export default SearchInput;