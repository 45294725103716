import React, {Component} from 'react';
import axios from "../../../axios-sw";

import Paper from '@material-ui/core/Paper';
import {tableIcons, titleTemplate} from "../../../shared/tableVariables";
import MaterialTable, {MTableBodyRow, MTableEditRow, MTableToolbar} from "material-table";
import Columns from "../../../shared/tables";
import {HotKeysSubtable} from "../../../components/System/HotKeys/HotKeys";

import {catchResponse, postFile, thenResponse} from '../../../shared/utility';

class PostComments extends Component {

    state = {
        columns: Columns.postCommentsColumns,
        data: []
    };

    componentDidMount() {
        this.indexPostComments();
    }


    indexPostComments = () => {
        const {token, postId} = this.props;
        axios.get(`/admin/show-post-comments/${postId}.json`, {headers: {'Authorization': `Bearer ${token}`}})
            .then(res => {
                this.setState({data: res.data});
            })
            .catch(err => {
                catchResponse(err)
            })
    };
    
    destroyPostComment = (id) => {
        const data = {
            /* Activity logging */
            user_id: this.props.user_id,
            ip_address: this.props.ip_address
        };
        axios.delete('/admin/comments/' + id, {
            data: data,
            headers: {'Authorization': `Bearer ${this.props.token}`}
        })
            .then(res => {
                this.indexPostComments();
                thenResponse(res)
            })
            .catch(err => {
                catchResponse(err)
            })
    };

    updatePostComment = (id, newData) => {
        const data = {
            comment: {
                content: newData.content,
            },
            /* Activity logging */
            user_id: this.props.user_id,
            ip_address: this.props.ip_address
        };
        const url = 'admin/comments/' + id + '.json';
        axios.put(url, data, {headers: {'Authorization': `Bearer ${this.props.token}`}})
            .then(res => {
                axios.get(url, {headers: {'Authorization': `Bearer ${this.props.token}`}})
                    .then(res => {
                        const fetchedPostComment = res.data;
                        const array = [...this.state.data];
                        const index = array.findIndex(x => x.id === fetchedPostComment.id);
                        if (index !== -1) {
                            array[index].content = fetchedPostComment.content;
                            array[index].created_at = fetchedPostComment.created_at;
                            array[index].author = fetchedPostComment.author;
                            array[index].post = fetchedPostComment.post;
                            this.setState({data: array});
                        }
                    })
                    .catch(err => {
                        catchResponse(err)
                    });
                thenResponse(res)
            })
            .catch(err => {
                catchResponse(err)
            })
    };

    render() {

        return (
            <Paper>
                <HotKeysSubtable/>
                <MaterialTable
                    title={titleTemplate('Komentarze')}
                    icons={tableIcons}
                    style={{width: '100%'}}
                    columns={this.state.columns}
                    data={this.state.data}
                    localization={{
                        editRow: {
                            cancelTooltip: 'Back'
                        }
                    }}
                    options={{
                        pageSize: 5,
                        exportButton: false,
                        filtering: false,
                        search: false,
                        paging: false
                    }}
                    components={{
                        EditRow: props => (
                            <MTableEditRow autoFocus className='subtableEditingRow uppercase ' {...props} />
                        ),
                        Row: props => (
                            <MTableBodyRow className={props.data.styles} {...props} />
                        ),
                        Toolbar: props => {
                            return (
                                <div className='subtableToolbar'>
                                    <MTableToolbar {...props} />
                                </div>
                            )
                        }
                    }}
                    editable={{
                        onRowUpdate: (newData, oldData) =>
                            new Promise(resolve => {
                                resolve();
                                this.updatePostComment(oldData.id, newData)
                            }),
                        onRowDelete: oldData =>
                            new Promise(resolve => {
                                resolve();
                                this.destroyPostComment(oldData.id);
                            }),
                    }}
                />
            </Paper>
        );
    }
}

export default PostComments;