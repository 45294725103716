import React, {useEffect, useRef, useState} from 'react';
import {useFormik} from "formik";
import * as Yup from 'yup';
import classes from "./Article.module.scss";
import axios from "../../../axios-sw";
import {authRedirect, catchResponse, thenResponse} from "../../../shared/utility";
import Spinner from "../../../components/UI/Spinner/Spinner";
import {toast, ToastContainer} from "react-toastify";
import {useSelector} from "react-redux";
import {Button, Paper} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import Checkbox from "@material-ui/core/Checkbox";
import Input from "@material-ui/core/Input";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {ArrowBack, Delete} from "@material-ui/icons";
import Dropzone from "../../../components/System/OrderDropzone/OrderDropzone";


const Article = props => {

    const [id, setId] = useState(props.match.params.id);
    const [loading, setLoading] = useState(true);
    const [article, setArticle] = useState(null);
    const [img, setImg] = useState([]);

    const token = useSelector(({auth}) => auth.token);
    const userId = useSelector(({auth}) => auth.user_id);
    const ipAddress = useSelector(({auth}) => auth.ip_address);
    const mainLangId = useSelector(({auth}) => auth.main_lang_id);
    const dropzone = useRef();

    useEffect(() => {
        if (id) indexArticle(id);
        else setLoading(false);
    }, [id])

    const indexArticle = id => {
        axios.get(`admin/articles/${id}.json`, {headers: {'Authorization': `Bearer ${token}`}})
            .then(async res => {
                await setArticle(res.data);
                await formik.setValues({
                    title: res.data.title,
                    img: res.data.img_url,
                    short_content: res.data.short_content,
                    content: res.data.content,
                    published: res.data.published,
                    publication_date: res.data.publication_date,
                    visits_counter: res.data.visits_counter
                })
                setLoading(false);
            })
            .catch(err => {
                catchResponse(err);
            })
    }

    const addArticle = data => {
        axios.post('admin/articles', data, {headers: {'Authorization': `Bearer ${token}`}})
            .then(async res => {
                await updateImgFiles(img, res.data.article_id, () => formik.setSubmitting(false));
                formik.setSubmitting(false);
                thenResponse(res);
                setId(res.data.article_id);
                props.history.push(`/article/${res.data.article_id}`);
            })
            .catch(err => {
                formik.setSubmitting(false);
                catchResponse(err)
            });
    }

    const updateArticle = (id, data) => {
        axios.put(`admin/articles/${id}`, data, {headers: {'Authorization': `Bearer ${token}`}})
            .then(res => {
                updateImgFiles(img, id, () => formik.setSubmitting(false));
                formik.setSubmitting(false);
                thenResponse(res);
            })
            .catch(err => {
                formik.setSubmitting(false);
                catchResponse(err)
            });
    }

    const validationSchema = Yup.object({
        title: Yup.string()
            .required('Tytuł jest wymagany'),
        short_content: Yup.string()
            .required('Skrócony Opis jest wymagany'),
        content: Yup.string()
            .required('Opis jest wymagany')
    })

    const formik = useFormik({
        initialValues: {
            title: '',
            img: '',
            short_content: '',
            content: '',
            published: false,
            publication_date: new Date(),
            visits_counter: 0
        },
        onSubmit: (values, actions) => {
            const data = {
                article: {
                    title: values.title,
                    img_url: values.img,
                    short_content: values.short_content,
                    content: values.content,
                    published: values.published,
                    publication_date: values.publication_date,
                    lang_id: mainLangId,
                    visits_counter: values.visits_counter
                },
                user_id: userId,
                ip_address: ipAddress
            }
            if(id) updateArticle(id, data);
            else addArticle(data) ;
        },
        validationSchema
    });

    const updateImgFiles = (img_urls, articleId, callback) => {
        if (img_urls === null) {
            const formDataPreview = new FormData();
            formDataPreview.append('img_urls[]', null);
            formDataPreview.append('user_id', userId);
            formDataPreview.append('ip_address', ipAddress);

            axios.post(`/admin/articles/img-urls/${articleId}`, formDataPreview, {headers: {'Authorization': `Bearer ${token}`}})
                .catch(err => {
                    callback();
                    catchResponse(err)
                });

        } else if (img_urls.length > 0) {
            const formDataPreview = new FormData();
            for (let i = 0; i < img_urls.length; i++) {
                formDataPreview.append('img_urls[]', img_urls[i]);
            }
            formDataPreview.append('user_id', userId);
            formDataPreview.append('ip_address', ipAddress);
            axios.post(`/admin/articles/img-urls/${articleId}`, formDataPreview, {headers: {'Authorization': `Bearer ${token}`}})
                .catch(err => {
                    callback();
                    catchResponse(err)
                });
        }
    };

    return (
        <>{loading ? <Spinner/> :
            <div className={classes.Article}>
                {authRedirect(token)}
                <ToastContainer/>
                <Paper className={classes.Paper}>
                    <Button
                        className={classes.BackButton}
                        variant="contained"
                        onClick={() => props.history.push('/articles')}>
                        <ArrowBack />
                    </Button>
                    {(id && article) ? <h1>Artykuł: {article.title}</h1> : <h1>Dodaj Artykuł</h1>}
                    <form className={classes.ArticleForm} onSubmit={formik.handleSubmit}>
                        <FormControl className={classes.FormControl}>
                            <p className={classes.Label}>Tytuł</p>
                            <Input
                                id="title"
                                error={formik.errors.title ? true : false}
                                className={classes.Input}
                                {...formik.getFieldProps('title')}
                            />
                            <p className={classes.InputFeedback}>{formik.errors.title}</p>
                        </FormControl>

                        <FormControl className={classes.FormControl}>
                            <p className={classes.Label}>Ilość Wejść</p>
                            <Input
                                id="visits_counter"
                                error={formik.errors.visits_counter ? true : false}
                                className={classes.Input}
                                {...formik.getFieldProps('visits_counter')}
                            />
                            <p className={classes.InputFeedback}>{formik.errors.visits_counter}</p>
                        </FormControl>

                        <FormControl className={classes.FormControl}>
                            <div className={classes.DropzoneContainer}>
                                <p className={classes.Label}>Główne Zjęcie</p>
                                <div className={classes.Dropzone}>
                                    <Button className={classes.DropzoneDelete} onClick={(e) => {
                                        e.preventDefault();
                                        dropzone.current.setInitialFiles();
                                        const articleData = article;
                                        articleData.img_url = [];
                                        setArticle(articleData);
                                        setImg(null)
                                        formik.setFieldValue('img_url', null);
                                    }}>
                                        <Delete />
                                    </Button>
                                    <Dropzone files={article && typeof article.img_url !== 'undefined' ? article.img_url : []}
                                              disabled={false}
                                              ref={dropzone}
                                              onDrop={files => {
                                                  formik.setFieldValue('img', files);
                                                  setImg(files)
                                              }}/>
                                </div>
                                <p className={classes.InputFeedback}>{formik.errors.img}</p>
                            </div>
                        </FormControl>

                        <FormControl className={classes.FormControl}>
                            <p className={classes.Label}>Skrócony Opis</p>
                            <CKEditor
                                id="short_content"
                                editor={ ClassicEditor }
                                data={`${formik.values.short_content}`}
                                config={{
                                    toolbar: ['heading', '|', 'bold', 'italic', 'blockQuote', 'link', 'numberedList', 'bulletedList', 'imageUpload', 'insertTable',
                                        'tableColumn', 'tableRow', 'mergeTableCells', '|', 'undo', 'redo']
                                }}
                                onChange={ ( e, editor ) => {
                                    const data = editor.getData();
                                    formik.setFieldValue('short_content', data)
                                } }
                            />
                            <p className={classes.InputFeedback}>{formik.errors.short_content}</p>
                        </FormControl>
                        <FormControl className={classes.FormControl}>
                            <p className={classes.Label}>Opis</p>
                            <CKEditor
                                id="content"
                                editor={ ClassicEditor }
                                data={`${formik.values.content}`}
                                config={{
                                    ckfinder: {
                                        // Upload the images to the server using the CKFinder QuickUpload command.
                                        uploadUrl: `${process.env.REACT_APP_BACKEND_URL}/admin/articles/upload-image`
                                      },
                                    toolbar: ['heading', '|', 'bold', 'italic', 'blockQuote', 'link', 'numberedList', 'bulletedList', 'imageUpload', 'insertTable',
                                        'tableColumn', 'tableRow', 'mergeTableCells', '|', 'undo', 'redo']
                                }}
                                onChange={ ( e, editor ) => {
                                    const data = editor.getData();
                                    formik.setFieldValue('content', data)
                                } }
                            />
                            <p className={classes.InputFeedback}>{formik.errors.content}</p>
                        </FormControl>
                        <FormControl className={classes.FormControl}>
                            <p className={classes.Label}>Czy artykuł ma zostać opublikowany (widoczny) ?</p>
                            <Checkbox
                                className={classes.Checkbox}
                                id="published"
                                checked={formik.values.published}
                                {...formik.getFieldProps('published')}
                            />
                        </FormControl>
                        <Button
                            onClick={formik.handleSubmit}
                            variant="contained"
                            style={{backgroundColor: '#006400', color:"#fff"}}
                        >
                            {(id && article) ? 'Zapisz zmiany' : 'Dodaj Artykuł'}
                        </Button>
                    </form>
                </Paper>
            </div>
        }
        </>
    )
}

export default Article;