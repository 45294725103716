import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import axios from "../../axios-sw";
import {authRedirect} from "../../shared/utility";
import Spinner from "../../components/UI/Spinner/Spinner";
import {Button} from "@material-ui/core";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import {toast, ToastContainer} from "react-toastify";

const WelcomeMessage = () => {
    //STATE
    const [welcomeMessage, setWelcomeMessage] = useState(null);
    const [loading, setLoading] = useState(true);
    const settingName = 'welcome_message';

    //SELECTORS
    const token = useSelector(({auth}) => auth.token);

    //FUNCTIONS
    useEffect(() => {
        axios.get(`/show-setting/${settingName}.json`, {headers: {'Authorization': `Bearer ${token}`}})
            .then(res => {
                setWelcomeMessage(res.data.value);
                setLoading(false);
            })
            .catch(err => {
                console.error(err)
            })
    }, []);

    const handleWelcomeMessageSave = () => {
        if(welcomeMessage && welcomeMessage.trim().length > 0 ) {
            setLoading(true);
            const data = {value: welcomeMessage};
            axios.post(`/update-setting/${settingName}.json`, data, {headers: {'Authorization': `Bearer ${token}`}})
                .then(res => {
                    setLoading(false);
                    toast.success('Zaktualizowano!');
                })
                .catch(err => {
                    console.error(err)
                })
        } else toast.error('Wiadomość nie może być pusta!');
    }

    return (
        <>
            {loading ? <Spinner/> :
                <div className="welcome-message">
                    {authRedirect(token)}
                    <ToastContainer/>
                        <TextareaAutosize
                            rows={10}
                            rowsMax={10}
                            value={welcomeMessage}
                            style={{fontSize: 14, width: '100%'}}
                            onChange={e => setWelcomeMessage(e.target.value)}
                        />
                        <Button
                            onClick={handleWelcomeMessageSave}
                            variant="outlined"
                            color="primary"
                            type="submit"
                        >
                            Zmień Treść
                        </Button>
                </div>
            }
        </>
    )
}

export default WelcomeMessage;