import React, {useEffect, useRef, useState} from 'react';
import {useFormik} from "formik";
import * as Yup from 'yup';
import classes from "./Partner.module.scss";
import axios from "../../../axios-sw";
import {authRedirect, catchResponse, thenResponse} from "../../../shared/utility";
import Spinner from "../../../components/UI/Spinner/Spinner";
import {ToastContainer} from "react-toastify";
import {useSelector} from "react-redux";
import {Button, Paper} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import Checkbox from "@material-ui/core/Checkbox";
import Input from "@material-ui/core/Input";
import {ArrowBack, Delete} from "@material-ui/icons";
import Dropzone from "../../../components/System/OrderDropzone/OrderDropzone";
import DatePicker from "react-datepicker";


const Partner = props => {

    const [id, setId] = useState(props.match.params.id);
    const [loading, setLoading] = useState(true);
    const [partner, setPartner] = useState(null);
    const [img, setImg] = useState([]);

    const token = useSelector(({auth}) => auth.token);
    const userId = useSelector(({auth}) => auth.user_id);
    const ipAddress = useSelector(({auth}) => auth.ip_address);
    const dropzone = useRef();

    useEffect(() => {
        if (id) indexPartner(id);
        else setLoading(false);
    }, [id])

    const indexPartner = id => {
        axios.get(`/admin/partners/${id}.json`, {headers: {'Authorization': `Bearer ${token}`}})
            .then(async res => {
                await setPartner(res.data);
                await formik.setValues({
                    title: res.data.title,
                    img: res.data.img_url,
                    url: res.data.url,
                    created_at: res.data.created_at,
                    published: res.data.published
                })
                setLoading(false);
            })
            .catch(err => {
                catchResponse(err);
            })
    }

    const addPartner = data => {
        axios.post('/admin/partners', data, {headers: {'Authorization': `Bearer ${token}`}})
            .then(async res => {
                await updateImgFiles(img, res.data.partner_id, () => formik.setSubmitting(false));
                formik.setSubmitting(false);
                thenResponse(res);
                setId(res.data.partner_id);
                props.history.push(`/partner/${res.data.partner_id}`);
            })
            .catch(err => {
                formik.setSubmitting(false);
                catchResponse(err)
            });
    }

    const updatePartner = (id, data) => {
        axios.put(`/admin/partners/${id}`, data, {headers: {'Authorization': `Bearer ${token}`}})
            .then(res => {
                updateImgFiles(img, id, () => formik.setSubmitting(false));
                formik.setSubmitting(false);
                thenResponse(res);
            })
            .catch(err => {
                formik.setSubmitting(false);
                catchResponse(err)
            });
    }

    const validationSchema = Yup.object({
        title: Yup.string()
            .required('Tytuł jest wymagany'),
        url: Yup.string()
            .required('Url jest wymagany'),
        created_at: Yup.date()
            .required('Data jest wymagana')
    })

    const formik = useFormik({
        initialValues: {
            title: '',
            img: '',
            url: '',
            created_at: Date.now(),
            published: false
        },
        onSubmit: (values, actions) => {
            const data = {
                partner: {
                    title: values.title,
                    img_url: values.img,
                    url: values.url,
                    created_at: values.created_at,
                    published: values.published
                },
                user_id: userId,
                ip_address: ipAddress
            }
            if (id) updatePartner(id, data);
            else addPartner(data);
        },
        validationSchema
    });

    const updateImgFiles = (img_urls, partnerId, callback) => {
        if (img_urls === null) {
            const formDataPreview = new FormData();
            formDataPreview.append('img_urls[]', null);
            formDataPreview.append('user_id', userId);
            formDataPreview.append('ip_address', ipAddress);

            axios.post(`/admin/partners/img-urls/${partnerId}`, formDataPreview, {headers: {'Authorization': `Bearer ${token}`}})
                .catch(err => {
                    callback();
                    catchResponse(err)
                });

        } else if (img_urls.length > 0) {
            const formDataPreview = new FormData();
            for (let i = 0; i < img_urls.length; i++) {
                formDataPreview.append('img_urls[]', img_urls[i]);
            }
            formDataPreview.append('user_id', userId);
            formDataPreview.append('ip_address', ipAddress);
            axios.post(`/admin/partners/img-urls/${partnerId}`, formDataPreview, {headers: {'Authorization': `Bearer ${token}`}})
                .catch(err => {
                    callback();
                    catchResponse(err)
                });
        }
    };

    return (
        <>{loading ? <Spinner/> :
            <div className={classes.Partner}>
                {authRedirect(token)}
                <ToastContainer/>
                <Paper className={classes.Paper}>
                    <Button
                        className={classes.BackButton}
                        variant="contained"
                        onClick={() => props.history.push('/partners')}>
                        <ArrowBack/>
                    </Button>
                    {(id && partner) ? <h1>Partner: {partner.title}</h1> : <h1>Dodaj Partnera</h1>}
                    <form className={classes.PartnerForm} onSubmit={formik.handleSubmit}>
                        <FormControl className={classes.FormControl}>
                            <p className={classes.Label}>Nazwa</p>
                            <Input
                                id="title"
                                error={formik.errors.title ? true : false}
                                className={classes.Input}
                                {...formik.getFieldProps('title')}
                            />
                            <p className={classes.InputFeedback}>{formik.errors.title}</p>
                        </FormControl>

                        <FormControl className={classes.FormControl}>
                            <div className={classes.DropzoneContainer}>
                                <p className={classes.Label}>Zjęcie</p>
                                <div className={classes.Dropzone}>
                                    <Button className={classes.DropzoneDelete} onClick={(e) => {
                                        e.preventDefault();
                                        dropzone.current.setInitialFiles();
                                        const partnerData = partner;
                                        partnerData.img_url = [];
                                        setPartner(partnerData);
                                        setImg(null)
                                        formik.setFieldValue('img_url', null);
                                    }}>
                                        <Delete/>
                                    </Button>
                                    <Dropzone
                                        files={partner && typeof partner.img_url !== 'undefined' ? partner.img_url : []}
                                        disabled={false}
                                        ref={dropzone}
                                        onDrop={files => {
                                            formik.setFieldValue('img', files);
                                            setImg(files)
                                        }}/>
                                </div>
                                <p className={classes.InputFeedback}>{formik.errors.img}</p>
                            </div>
                        </FormControl>
                        <FormControl className={classes.FormControl}>
                            <p className={classes.Label}>Data Utworzenia</p>
                            <DatePicker
                                selected={new Date(formik.values.created_at)}
                                onChange={date => formik.setFieldValue('created_at', date)}
                                dateFormat="yyyy-MM-dd"
                            />
                            <p className={classes.InputFeedback}>{formik.errors.created_at}</p>
                        </FormControl>
                        <FormControl className={classes.FormControl}>
                            <p className={classes.Label}>Link</p>
                            <Input
                                id="url"
                                error={formik.errors.title ? true : false}
                                className={classes.Input}
                                {...formik.getFieldProps('url')}
                            />
                            <p className={classes.InputFeedback}>{formik.errors.url}</p>
                        </FormControl>

                        <FormControl className={classes.FormControl}>
                            <p className={classes.Label}>Czy Opublikować ?</p>
                            <Checkbox
                                className={classes.Checkbox}
                                id="published"
                                checked={formik.values.published}
                                {...formik.getFieldProps('published')}
                            />
                        </FormControl>
                        <Button
                            onClick={formik.handleSubmit}
                            variant="contained"
                            style={{backgroundColor: '#006400', color: "#fff"}}
                        >
                            {(id && partner) ? 'Zapisz zmiany' : 'Dodaj Partnera'}
                        </Button>
                    </form>
                </Paper>
            </div>
        }
        </>
    )
}

export default Partner;