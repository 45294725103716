import * as actionTypes from "../actions/actionTypes";

//INITIAL STATE
const initialState = {
    currentSeasons: JSON.parse(window.localStorage.getItem(`user_seasons_${localStorage.getItem('user_id')}`)) || [{value: 4, label: "S21"}]
};

//ACTIONS
export const setSeasons = (seasons) => ({type: actionTypes.SET_SEASON, seasons});

//REDUCER
const systemInfoReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case actionTypes.SET_SEASON:
            return  {
                ...state,
                currentSeasons: action.seasons,
            };
        default:
            return state;
    }
}

export default systemInfoReducer;